const e = "undefined" != typeof window ? window : void 0,
  t = "undefined" != typeof globalThis ? globalThis : e,
  i = Array.prototype,
  n = i.forEach,
  s = i.indexOf,
  r = null == t ? void 0 : t.navigator,
  o = null == t ? void 0 : t.document,
  a = null == t ? void 0 : t.location,
  l = null == t ? void 0 : t.fetch,
  c = null != t && t.XMLHttpRequest && "withCredentials" in new t.XMLHttpRequest() ? t.XMLHttpRequest : void 0,
  u = null == t ? void 0 : t.AbortController,
  d = null == r ? void 0 : r.userAgent,
  h = null != e ? e : {};
const _ = {
    DEBUG: !1,
    LIB_VERSION: "1.176.0"
  },
  p = Array.isArray,
  g = Object.prototype,
  f = g.hasOwnProperty,
  v = g.toString,
  m = p || function (e) {
    return "[object Array]" === v.call(e);
  },
  y = function (e) {
    return "function" == typeof e;
  },
  b = function (e) {
    return e === Object(e) && !m(e);
  },
  w = function (e) {
    if (b(e)) {
      for (const t in e) if (f.call(e, t)) return !1;
      return !0;
    }
    return !1;
  },
  S = function (e) {
    return void 0 === e;
  },
  E = function (e) {
    return "[object String]" == v.call(e);
  },
  k = function (e) {
    return E(e) && 0 === e.trim().length;
  },
  x = function (e) {
    return null === e;
  },
  I = function (e) {
    return S(e) || x(e);
  },
  P = function (e) {
    return "[object Number]" == v.call(e);
  },
  F = function (e) {
    return "[object Boolean]" === v.call(e);
  },
  R = e => e instanceof FormData,
  T = "[PostHog.js]",
  $ = {
    _log: function (t) {
      if (e && (_.DEBUG || h.POSTHOG_DEBUG) && !S(e.console) && e.console) {
        const r = "__rrweb_original__" in e.console[t] ? e.console[t].__rrweb_original__ : e.console[t];
        for (var i = arguments.length, n = new Array(i > 1 ? i - 1 : 0), s = 1; s < i; s++) n[s - 1] = arguments[s];
        r(T, ...n);
      }
    },
    info: function () {
      for (var e = arguments.length, t = new Array(e), i = 0; i < e; i++) t[i] = arguments[i];
      $._log("log", ...t);
    },
    warn: function () {
      for (var e = arguments.length, t = new Array(e), i = 0; i < e; i++) t[i] = arguments[i];
      $._log("warn", ...t);
    },
    error: function () {
      for (var e = arguments.length, t = new Array(e), i = 0; i < e; i++) t[i] = arguments[i];
      $._log("error", ...t);
    },
    critical: function () {
      for (var e = arguments.length, t = new Array(e), i = 0; i < e; i++) t[i] = arguments[i];
      console.error(T, ...t);
    },
    uninitializedWarning: e => {
      $.error(`You must initialize PostHog before calling ${e}`);
    }
  },
  C = (e, t, i) => {
    if (e.config.disable_external_dependency_loading) return $.warn(`${t} was requested but loading of external scripts is disabled.`), i("Loading of external scripts is disabled");
    const n = () => {
      if (!o) return i("document not found");
      const e = o.createElement("script");
      e.type = "text/javascript", e.crossOrigin = "anonymous", e.src = t, e.onload = e => i(void 0, e), e.onerror = e => i(e);
      const n = o.querySelectorAll("body > script");
      var s;
      n.length > 0 ? null === (s = n[0].parentNode) || void 0 === s || s.insertBefore(e, n[0]) : o.body.appendChild(e);
    };
    null != o && o.body ? n() : null == o || o.addEventListener("DOMContentLoaded", n);
  };
h.__PosthogExtensions__ = h.__PosthogExtensions__ || {}, h.__PosthogExtensions__.loadExternalDependency = (e, t, i) => {
  let n = `/static/${t}.js?v=${e.version}`;
  if ("toolbar" === t) {
    const e = 3e5;
    n = `${n}?&=${Math.floor(Date.now() / e) * e}`;
  }
  const s = e.requestRouter.endpointFor("assets", n);
  C(e, s, i);
}, h.__PosthogExtensions__.loadSiteApp = (e, t, i) => {
  const n = e.requestRouter.endpointFor("api", t);
  C(e, n, i);
};
const M = {},
  A = function (e) {
    return e.replace(/^[\s\uFEFF\xA0]+|[\s\uFEFF\xA0]+$/g, "");
  };
function L(e, t, i) {
  if (m(e)) if (n && e.forEach === n) e.forEach(t, i);else if ("length" in e && e.length === +e.length) for (let n = 0, s = e.length; n < s; n++) if (n in e && t.call(i, e[n], n) === M) return;
}
function O(e, t, i) {
  if (!I(e)) {
    if (m(e)) return L(e, t, i);
    if (R(e)) {
      for (const n of e.entries()) if (t.call(i, n[1], n[0]) === M) return;
    } else for (const n in e) if (f.call(e, n) && t.call(i, e[n], n) === M) return;
  }
}
const D = function (e) {
  for (var t = arguments.length, i = new Array(t > 1 ? t - 1 : 0), n = 1; n < t; n++) i[n - 1] = arguments[n];
  return L(i, function (t) {
    for (const i in t) void 0 !== t[i] && (e[i] = t[i]);
  }), e;
};
function N(e, t) {
  return -1 !== e.indexOf(t);
}
function q(e) {
  const t = Object.keys(e);
  let i = t.length;
  const n = new Array(i);
  for (; i--;) n[i] = [t[i], e[t[i]]];
  return n;
}
const B = function (e) {
    try {
      return e();
    } catch {
      return;
    }
  },
  H = function (e) {
    return function () {
      try {
        for (var t = arguments.length, i = new Array(t), n = 0; n < t; n++) i[n] = arguments[n];
        return e.apply(this, i);
      } catch (e) {
        $.critical("Implementation error. Please turn on debug mode and open a ticket on https://app.posthog.com/home#panel=support%3Asupport%3A."), $.critical(e);
      }
    };
  },
  U = function (e) {
    const t = {};
    return O(e, function (e, i) {
      E(e) && e.length > 0 && (t[i] = e);
    }), t;
  },
  W = function (e) {
    return e.replace(/^\$/, "");
  };
function z(e, t) {
  return function (e, t) {
    const i = new Set();
    return function e(n, s) {
      if (n !== Object(n)) return t ? t(n, s) : n;
      if (i.has(n)) return;
      let r;
      return i.add(n), m(n) ? (r = [], L(n, t => {
        r.push(e(t));
      })) : (r = {}, O(n, (t, n) => {
        i.has(t) || (r[n] = e(t, n));
      })), r;
    }(e);
  }(e, e => E(e) && !x(t) ? e.slice(0, t) : e);
}
const V = function (e) {
    let t,
      i,
      n,
      s = "",
      r = 0;
    for (t = i = 0, r = (e = (e + "").replace(/\r\n/g, "\n").replace(/\r/g, "\n")).length, n = 0; n < r; n++) {
      const r = e.charCodeAt(n);
      let o = null;
      r < 128 ? i++ : o = r > 127 && r < 2048 ? String.fromCharCode(r >> 6 | 192, 63 & r | 128) : String.fromCharCode(r >> 12 | 224, r >> 6 & 63 | 128, 63 & r | 128), x(o) || (i > t && (s += e.substring(t, i)), s += o, t = i = n + 1);
    }
    return i > t && (s += e.substring(t, e.length)), s;
  },
  G = function () {
    function t(e) {
      return e && (e.preventDefault = t.preventDefault, e.stopPropagation = t.stopPropagation), e;
    }
    return t.preventDefault = function () {
      this.returnValue = !1;
    }, t.stopPropagation = function () {
      this.cancelBubble = !0;
    }, function (i, n, s, r, o) {
      if (i) {
        if (i.addEventListener && !r) i.addEventListener(n, s, !!o);else {
          const r = "on" + n,
            o = i[r];
          i[r] = function (i, n, s) {
            return function (r) {
              if (!(r = r || t(null == e ? void 0 : e.event))) return;
              let o,
                a = !0;
              y(s) && (o = s(r));
              const l = n.call(i, r);
              return !1 !== o && !1 !== l || (a = !1), a;
            };
          }(i, s, o);
        }
      } else $.error("No valid element provided to register_event");
    };
  }();
function j(e) {
  const t = null == e ? void 0 : e.hostname;
  return !!E(t) && "herokuapp.com" !== t.split(".").slice(-2).join(".");
}
function Q(e, t) {
  for (let i = 0; i < e.length; i++) if (t(e[i])) return e[i];
}
let J;
!function (e) {
  e.GZipJS = "gzip-js", e.Base64 = "base64";
}(J || (J = {}));
const Y = ["fatal", "error", "warning", "log", "info", "debug"],
  X = "$people_distinct_id",
  K = "__alias",
  Z = "__timers",
  ee = "$autocapture_disabled_server_side",
  te = "$heatmaps_enabled_server_side",
  ie = "$exception_capture_enabled_server_side",
  ne = "$exception_capture_endpoint_suffix",
  se = "$web_vitals_enabled_server_side",
  re = "$web_vitals_allowed_metrics",
  oe = "$session_recording_enabled_server_side",
  ae = "$console_log_recording_enabled_server_side",
  le = "$session_recording_network_payload_capture",
  ce = "$session_recording_canvas_recording",
  ue = "$replay_sample_rate",
  de = "$replay_minimum_duration",
  he = "$sesid",
  _e = "$session_is_sampled",
  pe = "$session_recording_url_trigger_activated_session",
  ge = "$session_recording_url_trigger_status",
  fe = "$enabled_feature_flags",
  ve = "$early_access_features",
  me = "$stored_person_properties",
  ye = "$stored_group_properties",
  be = "$surveys",
  we = "$surveys_activated",
  Se = "$flag_call_reported",
  Ee = "$user_state",
  ke = "$client_session_props",
  xe = "$capture_rate_limit",
  Ie = "$initial_campaign_params",
  Pe = "$initial_referrer_info",
  Fe = "$initial_person_info",
  Re = "$epp",
  Te = "__POSTHOG_TOOLBAR__",
  $e = [X, K, "__cmpns", Z, oe, te, he, fe, Ee, ve, ye, me, be, Se, ke, xe, Ie, Pe, Re],
  Ce = "$active_feature_flags",
  Me = "$override_feature_flags",
  Ae = "$feature_flag_payloads",
  Le = e => {
    const t = {};
    for (const [i, n] of q(e || {})) n && (t[i] = n);
    return t;
  };
class Oe {
  constructor(e) {
    this.instance = e, this._override_warning = !1, this.featureFlagEventHandlers = [], this.reloadFeatureFlagsQueued = !1, this.reloadFeatureFlagsInAction = !1;
  }
  getFlags() {
    return Object.keys(this.getFlagVariants());
  }
  getFlagVariants() {
    const e = this.instance.get_property(fe),
      t = this.instance.get_property(Me);
    if (!t) return e || {};
    const i = D({}, e),
      n = Object.keys(t);
    for (let e = 0; e < n.length; e++) i[n[e]] = t[n[e]];
    return this._override_warning || ($.warn(" Overriding feature flags!", {
      enabledFlags: e,
      overriddenFlags: t,
      finalFlags: i
    }), this._override_warning = !0), i;
  }
  getFlagPayloads() {
    return this.instance.get_property(Ae) || {};
  }
  reloadFeatureFlags() {
    this.reloadFeatureFlagsQueued || (this.reloadFeatureFlagsQueued = !0, this._startReloadTimer());
  }
  setAnonymousDistinctId(e) {
    this.$anon_distinct_id = e;
  }
  setReloadingPaused(e) {
    this.reloadFeatureFlagsInAction = e;
  }
  resetRequestQueue() {
    this.reloadFeatureFlagsQueued = !1;
  }
  _startReloadTimer() {
    this.reloadFeatureFlagsQueued && !this.reloadFeatureFlagsInAction && setTimeout(() => {
      !this.reloadFeatureFlagsInAction && this.reloadFeatureFlagsQueued && (this.reloadFeatureFlagsQueued = !1, this._reloadFeatureFlagsRequest());
    }, 5);
  }
  _reloadFeatureFlagsRequest() {
    if (this.instance.config.advanced_disable_feature_flags) return;
    this.setReloadingPaused(!0);
    const e = this.instance.config.token,
      t = this.instance.get_property(me),
      i = this.instance.get_property(ye),
      n = {
        token: e,
        distinct_id: this.instance.get_distinct_id(),
        groups: this.instance.getGroups(),
        $anon_distinct_id: this.$anon_distinct_id,
        person_properties: t,
        group_properties: i,
        disable_flags: this.instance.config.advanced_disable_feature_flags || void 0
      };
    this.instance._send_request({
      method: "POST",
      url: this.instance.requestRouter.endpointFor("api", "/decide/?v=3"),
      data: n,
      compression: this.instance.config.disable_compression ? void 0 : J.Base64,
      timeout: this.instance.config.feature_flag_request_timeout_ms,
      callback: e => {
        var t;
        this.setReloadingPaused(!1);
        let i = !0;
        200 === e.statusCode && (this.$anon_distinct_id = void 0, i = !1), this.receivedFeatureFlags(null !== (t = e.json) && void 0 !== t ? t : {}, i), this._startReloadTimer();
      }
    });
  }
  getFeatureFlag(e) {
    let t = arguments.length > 1 && void 0 !== arguments[1] ? arguments[1] : {};
    if (!(this.instance.decideEndpointWasHit || this.getFlags() && this.getFlags().length > 0)) return void $.warn('getFeatureFlag for key "' + e + "\" failed. Feature flags didn't load in time.");
    const i = this.getFlagVariants()[e],
      n = `${i}`,
      s = this.instance.get_property(Se) || {};
    var r;
    !t.send_event && "send_event" in t || e in s && s[e].includes(n) || (m(s[e]) ? s[e].push(n) : s[e] = [n], null === (r = this.instance.persistence) || void 0 === r || r.register({
      [Se]: s
    }), this.instance.capture("$feature_flag_called", {
      $feature_flag: e,
      $feature_flag_response: i
    }));
    return i;
  }
  getFeatureFlagPayload(e) {
    return this.getFlagPayloads()[e];
  }
  isFeatureEnabled(e) {
    let t = arguments.length > 1 && void 0 !== arguments[1] ? arguments[1] : {};
    if (this.instance.decideEndpointWasHit || this.getFlags() && this.getFlags().length > 0) return !!this.getFeatureFlag(e, t);
    $.warn('isFeatureEnabled for key "' + e + "\" failed. Feature flags didn't load in time.");
  }
  addFeatureFlagsHandler(e) {
    this.featureFlagEventHandlers.push(e);
  }
  removeFeatureFlagsHandler(e) {
    this.featureFlagEventHandlers = this.featureFlagEventHandlers.filter(t => t !== e);
  }
  receivedFeatureFlags(e, t) {
    if (!this.instance.persistence) return;
    this.instance.decideEndpointWasHit = !0;
    const i = this.getFlagVariants(),
      n = this.getFlagPayloads();
    !function (e, t) {
      let i = arguments.length > 2 && void 0 !== arguments[2] ? arguments[2] : {},
        n = arguments.length > 3 && void 0 !== arguments[3] ? arguments[3] : {};
      const s = e.featureFlags,
        r = e.featureFlagPayloads;
      if (!s) return;
      if (m(s)) {
        const e = {};
        if (s) for (let t = 0; t < s.length; t++) e[s[t]] = !0;
        return void (t && t.register({
          [Ce]: s,
          [fe]: e
        }));
      }
      let o = s,
        a = r;
      e.errorsWhileComputingFlags && (o = {
        ...i,
        ...o
      }, a = {
        ...n,
        ...a
      }), t && t.register({
        [Ce]: Object.keys(Le(o)),
        [fe]: o || {},
        [Ae]: a || {}
      });
    }(e, this.instance.persistence, i, n), this._fireFeatureFlagsCallbacks(t);
  }
  override(e) {
    let t = arguments.length > 1 && void 0 !== arguments[1] && arguments[1];
    if (!this.instance.__loaded || !this.instance.persistence) return $.uninitializedWarning("posthog.feature_flags.override");
    if (this._override_warning = t, !1 === e) this.instance.persistence.unregister(Me);else if (m(e)) {
      const t = {};
      for (let i = 0; i < e.length; i++) t[e[i]] = !0;
      this.instance.persistence.register({
        [Me]: t
      });
    } else this.instance.persistence.register({
      [Me]: e
    });
  }
  onFeatureFlags(e) {
    if (this.addFeatureFlagsHandler(e), this.instance.decideEndpointWasHit) {
      const {
        flags: t,
        flagVariants: i
      } = this._prepareFeatureFlagsForCallbacks();
      e(t, i);
    }
    return () => this.removeFeatureFlagsHandler(e);
  }
  updateEarlyAccessFeatureEnrollment(e, t) {
    var i;
    const n = {
      [`$feature_enrollment/${e}`]: t
    };
    this.instance.capture("$feature_enrollment_update", {
      $feature_flag: e,
      $feature_enrollment: t,
      $set: n
    }), this.setPersonPropertiesForFlags(n, !1);
    const s = {
      ...this.getFlagVariants(),
      [e]: t
    };
    null === (i = this.instance.persistence) || void 0 === i || i.register({
      [Ce]: Object.keys(Le(s)),
      [fe]: s
    }), this._fireFeatureFlagsCallbacks();
  }
  getEarlyAccessFeatures(e) {
    let t = arguments.length > 1 && void 0 !== arguments[1] && arguments[1];
    const i = this.instance.get_property(ve);
    if (i && !t) return e(i);
    this.instance._send_request({
      transport: "XHR",
      url: this.instance.requestRouter.endpointFor("api", `/api/early_access_features/?token=${this.instance.config.token}`),
      method: "GET",
      callback: t => {
        var i;
        if (!t.json) return;
        const n = t.json.earlyAccessFeatures;
        return null === (i = this.instance.persistence) || void 0 === i || i.register({
          [ve]: n
        }), e(n);
      }
    });
  }
  _prepareFeatureFlagsForCallbacks() {
    const e = this.getFlags(),
      t = this.getFlagVariants();
    return {
      flags: e.filter(e => t[e]),
      flagVariants: Object.keys(t).filter(e => t[e]).reduce((e, i) => (e[i] = t[i], e), {})
    };
  }
  _fireFeatureFlagsCallbacks(e) {
    const {
      flags: t,
      flagVariants: i
    } = this._prepareFeatureFlagsForCallbacks();
    this.featureFlagEventHandlers.forEach(n => n(t, i, {
      errorsLoading: e
    }));
  }
  setPersonPropertiesForFlags(e) {
    let t = !(arguments.length > 1 && void 0 !== arguments[1]) || arguments[1];
    const i = this.instance.get_property(me) || {};
    this.instance.register({
      [me]: {
        ...i,
        ...e
      }
    }), t && this.instance.reloadFeatureFlags();
  }
  resetPersonPropertiesForFlags() {
    this.instance.unregister(me);
  }
  setGroupPropertiesForFlags(e) {
    let t = !(arguments.length > 1 && void 0 !== arguments[1]) || arguments[1];
    const i = this.instance.get_property(ye) || {};
    0 !== Object.keys(i).length && Object.keys(i).forEach(t => {
      i[t] = {
        ...i[t],
        ...e[t]
      }, delete e[t];
    }), this.instance.register({
      [ye]: {
        ...i,
        ...e
      }
    }), t && this.instance.reloadFeatureFlags();
  }
  resetGroupPropertiesForFlags(e) {
    if (e) {
      const t = this.instance.get_property(ye) || {};
      this.instance.register({
        [ye]: {
          ...t,
          [e]: {}
        }
      });
    } else this.instance.unregister(ye);
  }
}
/**
 * uuidv7: An experimental implementation of the proposed UUID Version 7
 *
 * @license Apache-2.0
 * @copyright 2021-2023 LiosK
 * @packageDocumentation
 *
 * from https://github.com/LiosK/uuidv7/blob/e501462ea3d23241de13192ceae726956f9b3b7d/src/index.ts
 */
Math.trunc || (Math.trunc = function (e) {
  return e < 0 ? Math.ceil(e) : Math.floor(e);
}), Number.isInteger || (Number.isInteger = function (e) {
  return P(e) && isFinite(e) && Math.floor(e) === e;
});
const De = "0123456789abcdef";
class Ne {
  constructor(e) {
    if (this.bytes = e, 16 !== e.length) throw new TypeError("not 128-bit length");
  }
  static fromFieldsV7(e, t, i, n) {
    if (!Number.isInteger(e) || !Number.isInteger(t) || !Number.isInteger(i) || !Number.isInteger(n) || e < 0 || t < 0 || i < 0 || n < 0 || e > 0xffffffffffff || t > 4095 || i > 1073741823 || n > 4294967295) throw new RangeError("invalid field value");
    const s = new Uint8Array(16);
    return s[0] = e / 2 ** 40, s[1] = e / 2 ** 32, s[2] = e / 2 ** 24, s[3] = e / 65536, s[4] = e / 256, s[5] = e, s[6] = 112 | t >>> 8, s[7] = t, s[8] = 128 | i >>> 24, s[9] = i >>> 16, s[10] = i >>> 8, s[11] = i, s[12] = n >>> 24, s[13] = n >>> 16, s[14] = n >>> 8, s[15] = n, new Ne(s);
  }
  toString() {
    let e = "";
    for (let t = 0; t < this.bytes.length; t++) e = e + De.charAt(this.bytes[t] >>> 4) + De.charAt(15 & this.bytes[t]), 3 !== t && 5 !== t && 7 !== t && 9 !== t || (e += "-");
    if (36 !== e.length) throw new Error("Invalid UUIDv7 was generated");
    return e;
  }
  clone() {
    return new Ne(this.bytes.slice(0));
  }
  equals(e) {
    return 0 === this.compareTo(e);
  }
  compareTo(e) {
    for (let t = 0; t < 16; t++) {
      const i = this.bytes[t] - e.bytes[t];
      if (0 !== i) return Math.sign(i);
    }
    return 0;
  }
}
class qe {
  timestamp = 0;
  counter = 0;
  random = new Ue();
  generate() {
    const e = this.generateOrAbort();
    if (S(e)) {
      this.timestamp = 0;
      const e = this.generateOrAbort();
      if (S(e)) throw new Error("Could not generate UUID after timestamp reset");
      return e;
    }
    return e;
  }
  generateOrAbort() {
    const e = Date.now();
    if (e > this.timestamp) this.timestamp = e, this.resetCounter();else {
      if (!(e + 1e4 > this.timestamp)) return;
      this.counter++, this.counter > 4398046511103 && (this.timestamp++, this.resetCounter());
    }
    return Ne.fromFieldsV7(this.timestamp, Math.trunc(this.counter / 2 ** 30), this.counter & 2 ** 30 - 1, this.random.nextUint32());
  }
  resetCounter() {
    this.counter = 1024 * this.random.nextUint32() + (1023 & this.random.nextUint32());
  }
}
let Be,
  He = e => {
    if ("undefined" != typeof UUIDV7_DENY_WEAK_RNG && UUIDV7_DENY_WEAK_RNG) throw new Error("no cryptographically strong RNG available");
    for (let t = 0; t < e.length; t++) e[t] = 65536 * Math.trunc(65536 * Math.random()) + Math.trunc(65536 * Math.random());
    return e;
  };
e && !S(e.crypto) && crypto.getRandomValues && (He = e => crypto.getRandomValues(e));
class Ue {
  buffer = new Uint32Array(8);
  cursor = 1 / 0;
  nextUint32() {
    return this.cursor >= this.buffer.length && (He(this.buffer), this.cursor = 0), this.buffer[this.cursor++];
  }
}
const We = () => ze().toString(),
  ze = () => (Be || (Be = new qe())).generate(),
  Ve = "Thu, 01 Jan 1970 00:00:00 GMT";
let Ge = "";
const je = /[a-z0-9][a-z0-9-]+\.[a-z]{2,}$/i;
function Qe(e, t) {
  if (t) {
    let t = function (e) {
      let t = arguments.length > 1 && void 0 !== arguments[1] ? arguments[1] : o;
      if (Ge) return Ge;
      if (!t) return "";
      if (["localhost", "127.0.0.1"].includes(e)) return "";
      const i = e.split(".");
      let n = Math.min(i.length, 8);
      const s = "dmn_chk_" + We(),
        r = new RegExp("(^|;)\\s*" + s + "=1");
      for (; !Ge && n--;) {
        const e = i.slice(n).join("."),
          o = s + "=1;domain=." + e;
        t.cookie = o, r.test(t.cookie) && (t.cookie = o + ";expires=" + Ve, Ge = e);
      }
      return Ge;
    }(e);
    if (!t) {
      const i = (e => {
        const t = e.match(je);
        return t ? t[0] : "";
      })(e);
      i !== t && $.info("Warning: cookie subdomain discovery mismatch", i, t), t = i;
    }
    return t ? "; domain=." + t : "";
  }
  return "";
}
const Je = {
  is_supported: () => !!o,
  error: function (e) {
    $.error("cookieStore error: " + e);
  },
  get: function (e) {
    if (o) {
      try {
        const t = e + "=",
          i = o.cookie.split(";").filter(e => e.length);
        for (let e = 0; e < i.length; e++) {
          let n = i[e];
          for (; " " == n.charAt(0);) n = n.substring(1, n.length);
          if (0 === n.indexOf(t)) return decodeURIComponent(n.substring(t.length, n.length));
        }
      } catch {}
      return null;
    }
  },
  parse: function (e) {
    let t;
    try {
      t = JSON.parse(Je.get(e)) || {};
    } catch {}
    return t;
  },
  set: function (e, t, i, n, s) {
    if (o) try {
      let r = "",
        a = "";
      const l = Qe(o.location.hostname, n);
      if (i) {
        const e = new Date();
        e.setTime(e.getTime() + 24 * i * 60 * 60 * 1e3), r = "; expires=" + e.toUTCString();
      }
      s && (a = "; secure");
      const c = e + "=" + encodeURIComponent(JSON.stringify(t)) + r + "; SameSite=Lax; path=/" + l + a;
      return c.length > 3686.4 && $.warn("cookieStore warning: large cookie, len=" + c.length), o.cookie = c, c;
    } catch {
      return;
    }
  },
  remove: function (e, t) {
    try {
      Je.set(e, "", -1, t);
    } catch {
      return;
    }
  }
};
let Ye = null;
const Xe = {
    is_supported: function () {
      if (!x(Ye)) return Ye;
      let t = !0;
      if (S(e)) t = !1;else try {
        const e = "__mplssupport__",
          i = "xyz";
        Xe.set(e, i), '"xyz"' !== Xe.get(e) && (t = !1), Xe.remove(e);
      } catch {
        t = !1;
      }
      return t || $.error("localStorage unsupported; falling back to cookie store"), Ye = t, t;
    },
    error: function (e) {
      $.error("localStorage error: " + e);
    },
    get: function (t) {
      try {
        return null == e ? void 0 : e.localStorage.getItem(t);
      } catch (e) {
        Xe.error(e);
      }
      return null;
    },
    parse: function (e) {
      try {
        return JSON.parse(Xe.get(e)) || {};
      } catch {}
      return null;
    },
    set: function (t, i) {
      try {
        null == e || e.localStorage.setItem(t, JSON.stringify(i));
      } catch (e) {
        Xe.error(e);
      }
    },
    remove: function (t) {
      try {
        null == e || e.localStorage.removeItem(t);
      } catch (e) {
        Xe.error(e);
      }
    }
  },
  Ke = ["distinct_id", he, _e, Re],
  Ze = {
    ...Xe,
    parse: function (e) {
      try {
        let t = {};
        try {
          t = Je.parse(e) || {};
        } catch {}
        const i = D(t, JSON.parse(Xe.get(e) || "{}"));
        return Xe.set(e, i), i;
      } catch {}
      return null;
    },
    set: function (e, t, i, n, s, r) {
      try {
        Xe.set(e, t, void 0, void 0, r);
        const o = {};
        Ke.forEach(e => {
          t[e] && (o[e] = t[e]);
        }), Object.keys(o).length && Je.set(e, o, i, n, s, r);
      } catch (e) {
        Xe.error(e);
      }
    },
    remove: function (t, i) {
      try {
        null == e || e.localStorage.removeItem(t), Je.remove(t, i);
      } catch (e) {
        Xe.error(e);
      }
    }
  },
  et = {},
  tt = {
    is_supported: function () {
      return !0;
    },
    error: function (e) {
      $.error("memoryStorage error: " + e);
    },
    get: function (e) {
      return et[e] || null;
    },
    parse: function (e) {
      return et[e] || null;
    },
    set: function (e, t) {
      et[e] = t;
    },
    remove: function (e) {
      delete et[e];
    }
  };
let it = null;
const nt = {
    is_supported: function () {
      if (!x(it)) return it;
      if (it = !0, S(e)) it = !1;else try {
        const e = "__support__",
          t = "xyz";
        nt.set(e, t), '"xyz"' !== nt.get(e) && (it = !1), nt.remove(e);
      } catch {
        it = !1;
      }
      return it;
    },
    error: function (e) {
      $.error("sessionStorage error: ", e);
    },
    get: function (t) {
      try {
        return null == e ? void 0 : e.sessionStorage.getItem(t);
      } catch (e) {
        nt.error(e);
      }
      return null;
    },
    parse: function (e) {
      try {
        return JSON.parse(nt.get(e)) || null;
      } catch {}
      return null;
    },
    set: function (t, i) {
      try {
        null == e || e.sessionStorage.setItem(t, JSON.stringify(i));
      } catch (e) {
        nt.error(e);
      }
    },
    remove: function (t) {
      try {
        null == e || e.sessionStorage.removeItem(t);
      } catch (e) {
        nt.error(e);
      }
    }
  },
  st = ["localhost", "127.0.0.1"],
  rt = e => {
    const t = null == o ? void 0 : o.createElement("a");
    return S(t) ? null : (t.href = e, t);
  },
  ot = function (e, t) {
    return !!function (e) {
      try {
        new RegExp(e);
      } catch {
        return !1;
      }
      return !0;
    }(t) && new RegExp(t).test(e);
  },
  at = function (e) {
    let t,
      i,
      n = arguments.length > 1 && void 0 !== arguments[1] ? arguments[1] : "&";
    const s = [];
    return O(e, function (e, n) {
      S(e) || S(n) || "undefined" === n || (t = encodeURIComponent((e => e instanceof File)(e) ? e.name : e.toString()), i = encodeURIComponent(n), s[s.length] = i + "=" + t);
    }), s.join(n);
  },
  lt = function (e, t) {
    const i = ((e.split("#")[0] || "").split("?")[1] || "").split("&");
    let n;
    for (let e = 0; e < i.length; e++) {
      const s = i[e].split("=");
      if (s[0] === t) {
        n = s;
        break;
      }
    }
    if (!m(n) || n.length < 2) return "";
    {
      let e = n[1];
      try {
        e = decodeURIComponent(e);
      } catch {
        $.error("Skipping decoding for malformed query param: " + e);
      }
      return e.replace(/\+/g, " ");
    }
  },
  ct = function (e, t) {
    const i = e.match(new RegExp(t + "=([^&]*)"));
    return i ? i[1] : null;
  },
  ut = "Mobile",
  dt = "iOS",
  ht = "Android",
  _t = "Tablet",
  pt = ht + " " + _t,
  gt = "iPad",
  ft = "Apple",
  vt = ft + " Watch",
  mt = "Safari",
  yt = "BlackBerry",
  bt = "Samsung",
  wt = bt + "Browser",
  St = bt + " Internet",
  Et = "Chrome",
  kt = Et + " OS",
  xt = Et + " " + dt,
  It = "Internet Explorer",
  Pt = It + " " + ut,
  Ft = "Opera",
  Rt = Ft + " Mini",
  Tt = "Edge",
  $t = "Microsoft " + Tt,
  Ct = "Firefox",
  Mt = Ct + " " + dt,
  At = "Nintendo",
  Lt = "PlayStation",
  Ot = "Xbox",
  Dt = ht + " " + ut,
  Nt = ut + " " + mt,
  qt = "Windows",
  Bt = qt + " Phone",
  Ht = "Nokia",
  Ut = "Ouya",
  Wt = "Generic",
  zt = Wt + " " + ut.toLowerCase(),
  Vt = Wt + " " + _t.toLowerCase(),
  Gt = "Konqueror",
  jt = "(\\d+(\\.\\d+)?)",
  Qt = new RegExp("Version/" + jt),
  Jt = new RegExp(Ot, "i"),
  Yt = new RegExp(Lt + " \\w+", "i"),
  Xt = new RegExp(At + " \\w+", "i"),
  Kt = new RegExp(yt + "|PlayBook|BB10", "i"),
  Zt = {
    "NT3.51": "NT 3.11",
    "NT4.0": "NT 4.0",
    "5.0": "2000",
    5.1: "XP",
    5.2: "XP",
    "6.0": "Vista",
    6.1: "7",
    6.2: "8",
    6.3: "8.1",
    6.4: "10",
    "10.0": "10"
  };
const ei = (e, t) => t && N(t, ft) || function (e) {
    return N(e, mt) && !N(e, Et) && !N(e, ht);
  }(e),
  ti = function (e, t) {
    return t = t || "", N(e, " OPR/") && N(e, "Mini") ? Rt : N(e, " OPR/") ? Ft : Kt.test(e) ? yt : N(e, "IE" + ut) || N(e, "WPDesktop") ? Pt : N(e, wt) ? St : N(e, Tt) || N(e, "Edg/") ? $t : N(e, "FBIOS") ? "Facebook " + ut : N(e, "UCWEB") || N(e, "UCBrowser") ? "UC Browser" : N(e, "CriOS") ? xt : N(e, "CrMo") ? Et : N(e, ht) && N(e, mt) ? Dt : N(e, Et) ? Et : N(e, "FxiOS") ? Mt : N(e.toLowerCase(), Gt.toLowerCase()) ? Gt : ei(e, t) ? N(e, ut) ? Nt : mt : N(e, Ct) ? Ct : N(e, "MSIE") || N(e, "Trident/") ? It : N(e, "Gecko") ? Ct : "";
  },
  ii = {
    [Pt]: [new RegExp("rv:" + jt)],
    [$t]: [new RegExp(Tt + "?\\/" + jt)],
    [Et]: [new RegExp("(" + Et + "|CrMo)\\/" + jt)],
    [xt]: [new RegExp("CriOS\\/" + jt)],
    "UC Browser": [new RegExp("(UCBrowser|UCWEB)\\/" + jt)],
    [mt]: [Qt],
    [Nt]: [Qt],
    [Ft]: [new RegExp("(Opera|OPR)\\/" + jt)],
    [Ct]: [new RegExp(Ct + "\\/" + jt)],
    [Mt]: [new RegExp("FxiOS\\/" + jt)],
    [Gt]: [new RegExp("Konqueror[:/]?" + jt, "i")],
    [yt]: [new RegExp(yt + " " + jt), Qt],
    [Dt]: [new RegExp("android\\s" + jt, "i")],
    [St]: [new RegExp(wt + "\\/" + jt)],
    [It]: [new RegExp("(rv:|MSIE )" + jt)],
    Mozilla: [new RegExp("rv:" + jt)]
  },
  ni = [[new RegExp(Ot + "; " + Ot + " (.*?)[);]", "i"), e => [Ot, e && e[1] || ""]], [new RegExp(At, "i"), [At, ""]], [new RegExp(Lt, "i"), [Lt, ""]], [Kt, [yt, ""]], [new RegExp(qt, "i"), (e, t) => {
    if (/Phone/.test(t) || /WPDesktop/.test(t)) return [Bt, ""];
    if (new RegExp(ut).test(t) && !/IEMobile\b/.test(t)) return [qt + " " + ut, ""];
    const i = /Windows NT ([0-9.]+)/i.exec(t);
    if (i && i[1]) {
      const e = i[1];
      let n = Zt[e] || "";
      return /arm/i.test(t) && (n = "RT"), [qt, n];
    }
    return [qt, ""];
  }], [/((iPhone|iPad|iPod).*?OS (\d+)_(\d+)_?(\d+)?|iPhone)/, e => {
    if (e && e[3]) {
      const t = [e[3], e[4], e[5] || "0"];
      return [dt, t.join(".")];
    }
    return [dt, ""];
  }], [/(watch.*\/(\d+\.\d+\.\d+)|watch os,(\d+\.\d+),)/i, e => {
    let t = "";
    return e && e.length >= 3 && (t = S(e[2]) ? e[3] : e[2]), ["watchOS", t];
  }], [new RegExp("(" + ht + " (\\d+)\\.(\\d+)\\.?(\\d+)?|" + ht + ")", "i"), e => {
    if (e && e[2]) {
      const t = [e[2], e[3], e[4] || "0"];
      return [ht, t.join(".")];
    }
    return [ht, ""];
  }], [/Mac OS X (\d+)[_.](\d+)[_.]?(\d+)?/i, e => {
    const t = ["Mac OS X", ""];
    if (e && e[1]) {
      const i = [e[1], e[2], e[3] || "0"];
      t[1] = i.join(".");
    }
    return t;
  }], [/Mac/i, ["Mac OS X", ""]], [/CrOS/, [kt, ""]], [/Linux|debian/i, ["Linux", ""]]],
  si = function (e) {
    return Xt.test(e) ? At : Yt.test(e) ? Lt : Jt.test(e) ? Ot : new RegExp(Ut, "i").test(e) ? Ut : new RegExp("(" + Bt + "|WPDesktop)", "i").test(e) ? Bt : /iPad/.test(e) ? gt : /iPod/.test(e) ? "iPod Touch" : /iPhone/.test(e) ? "iPhone" : /(watch)(?: ?os[,/]|\d,\d\/)[\d.]+/i.test(e) ? vt : Kt.test(e) ? yt : /(kobo)\s(ereader|touch)/i.test(e) ? "Kobo" : new RegExp(Ht, "i").test(e) ? Ht : /(kf[a-z]{2}wi|aeo[c-r]{2})( bui|\))/i.test(e) || /(kf[a-z]+)( bui|\)).+silk\//i.test(e) ? "Kindle Fire" : /(Android|ZTE)/i.test(e) ? !new RegExp(ut).test(e) || /(9138B|TB782B|Nexus [97]|pixel c|HUAWEISHT|BTV|noble nook|smart ultra 6)/i.test(e) ? /pixel[\daxl ]{1,6}/i.test(e) && !/pixel c/i.test(e) || /(huaweimed-al00|tah-|APA|SM-G92|i980|zte|U304AA)/i.test(e) || /lmy47v/i.test(e) && !/QTAQZ3/i.test(e) ? ht : pt : ht : new RegExp("(pda|" + ut + ")", "i").test(e) ? zt : new RegExp(_t, "i").test(e) && !new RegExp(_t + " pc", "i").test(e) ? Vt : "";
  },
  ri = "https?://(.*)",
  oi = ["utm_source", "utm_medium", "utm_campaign", "utm_content", "utm_term", "gclid", "gad_source", "gclsrc", "dclid", "gbraid", "wbraid", "fbclid", "msclkid", "twclid", "li_fat_id", "mc_cid", "igshid", "ttclid", "rdt_cid"],
  ai = {
    campaignParams: function (e) {
      return o ? this._campaignParamsFromUrl(o.URL, e) : {};
    },
    _campaignParamsFromUrl: function (e, t) {
      const i = oi.concat(t || []),
        n = {};
      return O(i, function (t) {
        const i = lt(e, t);
        n[t] = i || null;
      }), n;
    },
    _searchEngine: function (e) {
      return e ? 0 === e.search(ri + "google.([^/?]*)") ? "google" : 0 === e.search(ri + "bing.com") ? "bing" : 0 === e.search(ri + "yahoo.com") ? "yahoo" : 0 === e.search(ri + "duckduckgo.com") ? "duckduckgo" : null : null;
    },
    _searchInfoFromReferrer: function (e) {
      const t = ai._searchEngine(e),
        i = "yahoo" != t ? "q" : "p",
        n = {};
      if (!x(t)) {
        n.$search_engine = t;
        const e = o ? lt(o.referrer, i) : "";
        e.length && (n.ph_keyword = e);
      }
      return n;
    },
    searchInfo: function () {
      const e = null == o ? void 0 : o.referrer;
      return e ? this._searchInfoFromReferrer(e) : {};
    },
    browser: ti,
    browserVersion: function (e, t) {
      const i = ti(e, t),
        n = ii[i];
      if (S(n)) return null;
      for (let t = 0; t < n.length; t++) {
        const i = n[t],
          s = e.match(i);
        if (s) return parseFloat(s[s.length - 2]);
      }
      return null;
    },
    browserLanguage: function () {
      return navigator.language || navigator.userLanguage;
    },
    os: function (e) {
      for (let t = 0; t < ni.length; t++) {
        const [i, n] = ni[t],
          s = i.exec(e),
          r = s && (y(n) ? n(s, e) : n);
        if (r) return r;
      }
      return ["", ""];
    },
    device: si,
    deviceType: function (e) {
      const t = si(e);
      return t === gt || t === pt || "Kobo" === t || "Kindle Fire" === t || t === Vt ? _t : t === At || t === Ot || t === Lt || t === Ut ? "Console" : t === vt ? "Wearable" : t ? ut : "Desktop";
    },
    referrer: function () {
      return (null == o ? void 0 : o.referrer) || "$direct";
    },
    referringDomain: function () {
      var e;
      return null != o && o.referrer && (null === (e = rt(o.referrer)) || void 0 === e ? void 0 : e.host) || "$direct";
    },
    referrerInfo: function () {
      return {
        $referrer: this.referrer(),
        $referring_domain: this.referringDomain()
      };
    },
    initialPersonInfo: function () {
      return {
        r: this.referrer(),
        u: null == a ? void 0 : a.href
      };
    },
    initialPersonPropsFromInfo: function (e) {
      var t;
      const {
          r: i,
          u: n
        } = e,
        s = {
          $initial_referrer: i,
          $initial_referring_domain: null == i ? void 0 : "$direct" == i ? "$direct" : null === (t = rt(i)) || void 0 === t ? void 0 : t.host
        };
      if (n) {
        s.$initial_current_url = n;
        const e = rt(n);
        s.$initial_host = null == e ? void 0 : e.host, s.$initial_pathname = null == e ? void 0 : e.pathname;
        O(this._campaignParamsFromUrl(n), function (e, t) {
          s["$initial_" + W(t)] = e;
        });
      }
      if (i) {
        O(this._searchInfoFromReferrer(i), function (e, t) {
          s["$initial_" + W(t)] = e;
        });
      }
      return s;
    },
    properties: function () {
      if (!d) return {};
      const [t, i] = ai.os(d);
      return D(U({
        $os: t,
        $os_version: i,
        $browser: ai.browser(d, navigator.vendor),
        $device: ai.device(d),
        $device_type: ai.deviceType(d)
      }), {
        $current_url: null == a ? void 0 : a.href,
        $host: null == a ? void 0 : a.host,
        $pathname: null == a ? void 0 : a.pathname,
        $raw_user_agent: d.length > 1e3 ? d.substring(0, 997) + "..." : d,
        $browser_version: ai.browserVersion(d, navigator.vendor),
        $browser_language: ai.browserLanguage(),
        $screen_height: null == e ? void 0 : e.screen.height,
        $screen_width: null == e ? void 0 : e.screen.width,
        $viewport_height: null == e ? void 0 : e.innerHeight,
        $viewport_width: null == e ? void 0 : e.innerWidth,
        $lib: "web",
        $lib_version: _.LIB_VERSION,
        $insert_id: Math.random().toString(36).substring(2, 10) + Math.random().toString(36).substring(2, 10),
        $time: Date.now() / 1e3
      });
    },
    people_properties: function () {
      if (!d) return {};
      const [e, t] = ai.os(d);
      return D(U({
        $os: e,
        $os_version: t,
        $browser: ai.browser(d, navigator.vendor)
      }), {
        $browser_version: ai.browserVersion(d, navigator.vendor)
      });
    }
  },
  li = ["cookie", "localstorage", "localstorage+cookie", "sessionstorage", "memory"];
class ci {
  constructor(e) {
    this.config = e, this.props = {}, this.campaign_params_saved = !1, this.name = (e => {
      let t = "";
      return e.token && (t = e.token.replace(/\+/g, "PL").replace(/\//g, "SL").replace(/=/g, "EQ")), e.persistence_name ? "ph_" + e.persistence_name : "ph_" + t + "_posthog";
    })(e), this.storage = this.buildStorage(e), this.load(), e.debug && $.info("Persistence loaded", e.persistence, {
      ...this.props
    }), this.update_config(e, e), this.save();
  }
  buildStorage(e) {
    let t;
    -1 === li.indexOf(e.persistence.toLowerCase()) && ($.critical("Unknown persistence type " + e.persistence + "; falling back to localStorage+cookie"), e.persistence = "localStorage+cookie");
    const i = e.persistence.toLowerCase();
    return t = "localstorage" === i && Xe.is_supported() ? Xe : "localstorage+cookie" === i && Ze.is_supported() ? Ze : "sessionstorage" === i && nt.is_supported() ? nt : "memory" === i ? tt : "cookie" === i ? Je : Ze.is_supported() ? Ze : Je, t;
  }
  properties() {
    const e = {};
    return O(this.props, function (t, i) {
      if (i === fe && b(t)) {
        const i = Object.keys(t);
        for (let n = 0; n < i.length; n++) e[`$feature/${i[n]}`] = t[i[n]];
      } else (function (e, t) {
        let i = !1;
        return x(e) ? i : s && e.indexOf === s ? -1 != e.indexOf(t) : (O(e, function (e) {
          if (i || (i = e === t)) return M;
        }), i);
      })($e, i) || (e[i] = t);
    }), e;
  }
  load() {
    if (this.disabled) return;
    const e = this.storage.parse(this.name);
    e && (this.props = D({}, e));
  }
  save() {
    this.disabled || this.storage.set(this.name, this.props, this.expire_days, this.cross_subdomain, this.secure, this.config.debug);
  }
  remove() {
    this.storage.remove(this.name, !1), this.storage.remove(this.name, !0);
  }
  clear() {
    this.remove(), this.props = {};
  }
  register_once(e, t, i) {
    if (b(e)) {
      S(t) && (t = "None"), this.expire_days = S(i) ? this.default_expiry : i;
      let n = !1;
      if (O(e, (e, i) => {
        this.props.hasOwnProperty(i) && this.props[i] !== t || (this.props[i] = e, n = !0);
      }), n) return this.save(), !0;
    }
    return !1;
  }
  register(e, t) {
    if (b(e)) {
      this.expire_days = S(t) ? this.default_expiry : t;
      let i = !1;
      if (O(e, (t, n) => {
        e.hasOwnProperty(n) && this.props[n] !== t && (this.props[n] = t, i = !0);
      }), i) return this.save(), !0;
    }
    return !1;
  }
  unregister(e) {
    e in this.props && (delete this.props[e], this.save());
  }
  update_campaign_params() {
    this.campaign_params_saved || (this.register(ai.campaignParams(this.config.custom_campaign_params)), this.campaign_params_saved = !0);
  }
  update_search_keyword() {
    this.register(ai.searchInfo());
  }
  update_referrer_info() {
    this.register_once(ai.referrerInfo(), void 0);
  }
  set_initial_person_info() {
    this.props[Ie] || this.props[Pe] || this.register_once({
      [Fe]: ai.initialPersonInfo()
    }, void 0);
  }
  get_referrer_info() {
    return U({
      $referrer: this.props.$referrer,
      $referring_domain: this.props.$referring_domain
    });
  }
  get_initial_props() {
    const e = {};
    O([Pe, Ie], t => {
      const i = this.props[t];
      i && O(i, function (t, i) {
        e["$initial_" + W(i)] = t;
      });
    });
    const t = this.props[Fe];
    if (t) {
      const i = ai.initialPersonPropsFromInfo(t);
      D(e, i);
    }
    return e;
  }
  safe_merge(e) {
    return O(this.props, function (t, i) {
      i in e || (e[i] = t);
    }), e;
  }
  update_config(e, t) {
    if (this.default_expiry = this.expire_days = e.cookie_expiration, this.set_disabled(e.disable_persistence), this.set_cross_subdomain(e.cross_subdomain_cookie), this.set_secure(e.secure_cookie), e.persistence !== t.persistence) {
      const t = this.buildStorage(e),
        i = this.props;
      this.clear(), this.storage = t, this.props = i, this.save();
    }
  }
  set_disabled(e) {
    this.disabled = e, this.disabled ? this.remove() : this.save();
  }
  set_cross_subdomain(e) {
    e !== this.cross_subdomain && (this.cross_subdomain = e, this.remove(), this.save());
  }
  get_cross_subdomain() {
    return !!this.cross_subdomain;
  }
  set_secure(e) {
    e !== this.secure && (this.secure = e, this.remove(), this.save());
  }
  set_event_timer(e, t) {
    const i = this.props[Z] || {};
    i[e] = t, this.props[Z] = i, this.save();
  }
  remove_event_timer(e) {
    const t = (this.props[Z] || {})[e];
    return S(t) || (delete this.props[Z][e], this.save()), t;
  }
  get_property(e) {
    return this.props[e];
  }
  set_property(e, t) {
    this.props[e] = t, this.save();
  }
}
function ui(e) {
  var t;
  return (null === (t = JSON.stringify(e, function () {
    const e = [];
    return function (t, i) {
      if (b(i)) {
        for (; e.length > 0 && e.at(-1) !== this;) e.pop();
        return e.includes(i) ? "[Circular]" : (e.push(i), i);
      }
      return i;
    };
  }())) || void 0 === t ? void 0 : t.length) || 0;
}
function di(e) {
  let t = arguments.length > 1 && void 0 !== arguments[1] ? arguments[1] : 6606028.8;
  if (e.size >= t && e.data.length > 1) {
    const t = Math.floor(e.data.length / 2),
      i = e.data.slice(0, t),
      n = e.data.slice(t);
    return [di({
      size: ui(i),
      data: i,
      sessionId: e.sessionId,
      windowId: e.windowId
    }), di({
      size: ui(n),
      data: n,
      sessionId: e.sessionId,
      windowId: e.windowId
    })].flatMap(e => e);
  }
  return [e];
}
var hi = (e => (e[e.DomContentLoaded = 0] = "DomContentLoaded", e[e.Load = 1] = "Load", e[e.FullSnapshot = 2] = "FullSnapshot", e[e.IncrementalSnapshot = 3] = "IncrementalSnapshot", e[e.Meta = 4] = "Meta", e[e.Custom = 5] = "Custom", e[e.Plugin = 6] = "Plugin", e))(hi || {}),
  _i = (e => (e[e.Mutation = 0] = "Mutation", e[e.MouseMove = 1] = "MouseMove", e[e.MouseInteraction = 2] = "MouseInteraction", e[e.Scroll = 3] = "Scroll", e[e.ViewportResize = 4] = "ViewportResize", e[e.Input = 5] = "Input", e[e.TouchMove = 6] = "TouchMove", e[e.MediaInteraction = 7] = "MediaInteraction", e[e.StyleSheetRule = 8] = "StyleSheetRule", e[e.CanvasMutation = 9] = "CanvasMutation", e[e.Font = 10] = "Font", e[e.Log = 11] = "Log", e[e.Drag = 12] = "Drag", e[e.StyleDeclaration = 13] = "StyleDeclaration", e[e.Selection = 14] = "Selection", e[e.AdoptedStyleSheet = 15] = "AdoptedStyleSheet", e[e.CustomElement = 16] = "CustomElement", e))(_i || {});
function pi(e) {
  return e ? A(e).split(/\s+/) : [];
}
function gi(t) {
  const i = null == e ? void 0 : e.location.href;
  return !!(i && t && t.some(e => i.match(e)));
}
function fi(e) {
  let t = "";
  switch (typeof e.className) {
    case "string":
      t = e.className;
      break;
    case "object":
      t = (e.className && "baseVal" in e.className ? e.className.baseVal : null) || e.getAttribute("class") || "";
      break;
    default:
      t = "";
  }
  return pi(t);
}
function vi(e) {
  return I(e) ? null : A(e).split(/(\s+)/).filter(e => Li(e)).join("").replace(/[\r\n]/g, " ").replace(/[ ]+/g, " ").substring(0, 255);
}
function mi(e) {
  let t = "";
  return Pi(e) && !Fi(e) && e.childNodes && e.childNodes.length && O(e.childNodes, function (e) {
    var i;
    Si(e) && e.textContent && (t += null !== (i = vi(e.textContent)) && void 0 !== i ? i : "");
  }), A(t);
}
function yi(e) {
  return S(e.target) ? e.srcElement || null : null !== (t = e.target) && void 0 !== t && t.shadowRoot ? e.composedPath()[0] || null : e.target || null;
  var t;
}
function bi(e) {
  return !!e && 1 === e.nodeType;
}
function wi(e, t) {
  return !!e && !!e.tagName && e.tagName.toLowerCase() === t.toLowerCase();
}
function Si(e) {
  return !!e && 3 === e.nodeType;
}
function Ei(e) {
  return !!e && 11 === e.nodeType;
}
const ki = ["a", "button", "form", "input", "select", "textarea", "label"];
function xi(e) {
  const t = e.parentNode;
  return !(!t || !bi(t)) && t;
}
function Ii(t, i) {
  let n = arguments.length > 2 && void 0 !== arguments[2] ? arguments[2] : void 0,
    s = arguments.length > 3 ? arguments[3] : void 0,
    r = arguments.length > 4 ? arguments[4] : void 0;
  if (!e || !t || wi(t, "html") || !bi(t)) return !1;
  if (null != n && n.url_allowlist && !gi(n.url_allowlist)) return !1;
  if (null != n && n.url_ignorelist && gi(n.url_ignorelist)) return !1;
  if (null != n && n.dom_event_allowlist) {
    const e = n.dom_event_allowlist;
    if (e && !e.some(e => i.type === e)) return !1;
  }
  let o = !1;
  const a = [t];
  let l = !0,
    c = t;
  for (; c.parentNode && !wi(c, "body");) if (Ei(c.parentNode)) a.push(c.parentNode.host), c = c.parentNode.host;else {
    if (l = xi(c), !l) break;
    if (s || ki.indexOf(l.tagName.toLowerCase()) > -1) o = !0;else {
      const t = e.getComputedStyle(l);
      t && "pointer" === t.getPropertyValue("cursor") && (o = !0);
    }
    a.push(l), c = l;
  }
  if (!function (e, t) {
    const i = null == t ? void 0 : t.element_allowlist;
    if (S(i)) return !0;
    for (const t of e) if (i.some(e => t.tagName.toLowerCase() === e)) return !0;
    return !1;
  }(a, n)) return !1;
  if (!function (e, t) {
    const i = null == t ? void 0 : t.css_selector_allowlist;
    if (S(i)) return !0;
    for (const t of e) if (i.some(e => t.matches(e))) return !0;
    return !1;
  }(a, n)) return !1;
  const u = e.getComputedStyle(t);
  if (u && "pointer" === u.getPropertyValue("cursor") && "click" === i.type) return !0;
  const d = t.tagName.toLowerCase();
  switch (d) {
    case "html":
      return !1;
    case "form":
      return (r || ["submit"]).indexOf(i.type) >= 0;
    case "input":
    case "select":
    case "textarea":
      return (r || ["change", "click"]).indexOf(i.type) >= 0;
    default:
      return o ? (r || ["click"]).indexOf(i.type) >= 0 : (r || ["click"]).indexOf(i.type) >= 0 && (ki.indexOf(d) > -1 || "true" === t.getAttribute("contenteditable"));
  }
}
function Pi(e) {
  for (let t = e; t.parentNode && !wi(t, "body"); t = t.parentNode) {
    const e = fi(t);
    if (N(e, "ph-sensitive") || N(e, "ph-no-capture")) return !1;
  }
  if (N(fi(e), "ph-include")) return !0;
  const t = e.type || "";
  if (E(t)) switch (t.toLowerCase()) {
    case "hidden":
    case "password":
      return !1;
  }
  const i = e.name || e.id || "";
  if (E(i)) {
    if (/^cc|cardnum|ccnum|creditcard|csc|cvc|cvv|exp|pass|pwd|routing|seccode|securitycode|securitynum|socialsec|socsec|ssn/i.test(i.replace(/[^a-zA-Z0-9]/g, ""))) return !1;
  }
  return !0;
}
function Fi(e) {
  return !!(wi(e, "input") && !["button", "checkbox", "submit", "reset"].includes(e.type) || wi(e, "select") || wi(e, "textarea") || "true" === e.getAttribute("contenteditable"));
}
const Ri = "(4[0-9]{12}(?:[0-9]{3})?)|(5[1-5][0-9]{14})|(6(?:011|5[0-9]{2})[0-9]{12})|(3[47][0-9]{13})|(3(?:0[0-5]|[68][0-9])[0-9]{11})|((?:2131|1800|35[0-9]{3})[0-9]{11})",
  Ti = new RegExp(`^(?:${Ri})$`),
  $i = new RegExp(Ri),
  Ci = "\\d{3}-?\\d{2}-?\\d{4}",
  Mi = new RegExp(`^(${Ci})$`),
  Ai = new RegExp(`(${Ci})`);
function Li(e) {
  let t = !(arguments.length > 1 && void 0 !== arguments[1]) || arguments[1];
  if (I(e)) return !1;
  if (E(e)) {
    e = A(e);
    if ((t ? Ti : $i).test((e || "").replace(/[- ]/g, ""))) return !1;
    if ((t ? Mi : Ai).test(e)) return !1;
  }
  return !0;
}
function Oi(e) {
  let t = mi(e);
  return t = `${t} ${Di(e)}`.trim(), Li(t) ? t : "";
}
function Di(e) {
  let t = "";
  return e && e.childNodes && e.childNodes.length && O(e.childNodes, function (e) {
    var i;
    if (e && "span" === (null === (i = e.tagName) || void 0 === i ? void 0 : i.toLowerCase())) try {
      const i = mi(e);
      t = `${t} ${i}`.trim(), e.childNodes && e.childNodes.length && (t = `${t} ${Di(e)}`.trim());
    } catch (e) {
      $.error(e);
    }
  }), t;
}
function Ni(e) {
  return function (e) {
    const t = e.map(e => {
      var t, i;
      let n = "";
      if (e.tag_name && (n += e.tag_name), e.attr_class) {
        e.attr_class.sort();
        for (const t of e.attr_class) n += `.${t.replace(/"/g, "")}`;
      }
      const s = {
          ...(e.text ? {
            text: e.text
          } : {}),
          "nth-child": null !== (t = e.nth_child) && void 0 !== t ? t : 0,
          "nth-of-type": null !== (i = e.nth_of_type) && void 0 !== i ? i : 0,
          ...(e.href ? {
            href: e.href
          } : {}),
          ...(e.attr_id ? {
            attr_id: e.attr_id
          } : {}),
          ...e.attributes
        },
        r = {};
      return q(s).sort((e, t) => {
        let [i] = e,
          [n] = t;
        return i.localeCompare(n);
      }).forEach(e => {
        let [t, i] = e;
        return r[qi(t.toString())] = qi(i.toString());
      }), n += ":", n += q(s).map(e => {
        let [t, i] = e;
        return `${t}="${i}"`;
      }).join(""), n;
    });
    return t.join(";");
  }(function (e) {
    return e.map(e => {
      var t, i;
      const n = {
        text: null === (t = e.$el_text) || void 0 === t ? void 0 : t.slice(0, 400),
        tag_name: e.tag_name,
        href: null === (i = e.attr__href) || void 0 === i ? void 0 : i.slice(0, 2048),
        attr_class: Bi(e),
        attr_id: e.attr__id,
        nth_child: e.nth_child,
        nth_of_type: e.nth_of_type,
        attributes: {}
      };
      return q(e).filter(e => {
        let [t] = e;
        return 0 === t.indexOf("attr__");
      }).forEach(e => {
        let [t, i] = e;
        return n.attributes[t] = i;
      }), n;
    });
  }(e));
}
function qi(e) {
  return e.replace(/"|\\"/g, '\\"');
}
function Bi(e) {
  const t = e.attr__class;
  return t ? m(t) ? t : pi(t) : void 0;
}
const Hi = "[SessionRecording]",
  Ui = "redacted",
  Wi = {
    initiatorTypes: ["audio", "beacon", "body", "css", "early-hint", "embed", "fetch", "frame", "iframe", "icon", "image", "img", "input", "link", "navigation", "object", "ping", "script", "track", "video", "xmlhttprequest"],
    maskRequestFn: e => e,
    recordHeaders: !1,
    recordBody: !1,
    recordInitialRequests: !1,
    recordPerformance: !1,
    performanceEntryTypeToObserve: ["first-input", "navigation", "paint", "resource"],
    payloadSizeLimitBytes: 1e6,
    payloadHostDenyList: [".lr-ingest.io", ".ingest.sentry.io"]
  },
  zi = ["authorization", "x-forwarded-for", "authorization", "cookie", "set-cookie", "x-api-key", "x-real-ip", "remote-addr", "forwarded", "proxy-authorization", "x-csrf-token", "x-csrftoken", "x-xsrf-token"],
  Vi = ["password", "secret", "passwd", "api_key", "apikey", "auth", "credentials", "mysql_pwd", "privatekey", "private_key", "token"],
  Gi = ["/s/", "/e/", "/i/"];
function ji(e, t, i, n) {
  if (I(e)) return e;
  let s = (null == t ? void 0 : t["content-length"]) || function (e) {
    return new Blob([e]).size;
  }(e);
  return E(s) && (s = parseInt(s)), s > i ? Hi + ` ${n} body too large to record (${s} bytes)` : e;
}
function Qi(e, t) {
  if (I(e)) return e;
  let i = e;
  return Li(i, !1) || (i = Hi + " " + t + " body " + Ui), O(Vi, e => {
    var n, s;
    null !== (n = i) && void 0 !== n && n.length && -1 !== (null === (s = i) || void 0 === s ? void 0 : s.indexOf(e)) && (i = Hi + " " + t + " body " + Ui + " as might contain: " + e);
  }), i;
}
const Ji = (e, t) => {
  const i = {
      payloadSizeLimitBytes: Wi.payloadSizeLimitBytes,
      performanceEntryTypeToObserve: [...Wi.performanceEntryTypeToObserve],
      payloadHostDenyList: [...(t.payloadHostDenyList || []), ...Wi.payloadHostDenyList]
    },
    n = !1 !== e.session_recording.recordHeaders && t.recordHeaders,
    s = !1 !== e.session_recording.recordBody && t.recordBody,
    r = !1 !== e.capture_performance && t.recordPerformance,
    o = (e => {
      var t;
      const i = Math.min(1e6, null !== (t = e.payloadSizeLimitBytes) && void 0 !== t ? t : 1e6);
      return e => (null != e && e.requestBody && (e.requestBody = ji(e.requestBody, e.requestHeaders, i, "Request")), null != e && e.responseBody && (e.responseBody = ji(e.responseBody, e.responseHeaders, i, "Response")), e);
    })(i),
    a = e => o((e => {
      const t = rt(e.name);
      if (!(t && t.pathname && Gi.some(e => 0 === t.pathname.indexOf(e)))) return e;
    })((e => {
      const t = e.requestHeaders;
      return I(t) || O(Object.keys(null != t ? t : {}), e => {
        zi.includes(e.toLowerCase()) && (t[e] = Ui);
      }), e;
    })(e))),
    l = y(e.session_recording.maskNetworkRequestFn);
  return l && y(e.session_recording.maskCapturedNetworkRequestFn) && $.warn("Both `maskNetworkRequestFn` and `maskCapturedNetworkRequestFn` are defined. `maskNetworkRequestFn` will be ignored."), l && (e.session_recording.maskCapturedNetworkRequestFn = t => {
    const i = e.session_recording.maskNetworkRequestFn({
      url: t.name
    });
    return {
      ...t,
      name: null == i ? void 0 : i.url
    };
  }), i.maskRequestFn = y(e.session_recording.maskCapturedNetworkRequestFn) ? t => {
    var i, n, s;
    const r = a(t);
    return r && null !== (i = null === (n = (s = e.session_recording).maskCapturedNetworkRequestFn) || void 0 === n ? void 0 : n.call(s, r)) && void 0 !== i ? i : void 0;
  } : e => function (e) {
    if (!S(e)) return e.requestBody = Qi(e.requestBody, "Request"), e.responseBody = Qi(e.responseBody, "Response"), e;
  }(a(e)), {
    ...Wi,
    ...i,
    recordHeaders: n,
    recordBody: s,
    recordPerformance: r,
    recordInitialRequests: r
  };
};
class Yi {
  bucketSize = 100;
  refillRate = 10;
  mutationBuckets = {};
  loggedTracker = {};
  constructor(e) {
    var t, i;
    let n = arguments.length > 1 && void 0 !== arguments[1] ? arguments[1] : {};
    this.rrweb = e, this.options = n, this.refillRate = null !== (t = this.options.refillRate) && void 0 !== t ? t : this.refillRate, this.bucketSize = null !== (i = this.options.bucketSize) && void 0 !== i ? i : this.bucketSize, setInterval(() => {
      this.refillBuckets();
    }, 1e3);
  }
  refillBuckets = () => {
    Object.keys(this.mutationBuckets).forEach(e => {
      this.mutationBuckets[e] = this.mutationBuckets[e] + this.refillRate, this.mutationBuckets[e] >= this.bucketSize && delete this.mutationBuckets[e];
    });
  };
  getNodeOrRelevantParent = e => {
    const t = this.rrweb.mirror.getNode(e);
    if ("svg" !== (null == t ? void 0 : t.nodeName) && t instanceof Element) {
      const e = t.closest("svg");
      if (e) return [this.rrweb.mirror.getId(e), e];
    }
    return [e, t];
  };
  numberOfChanges = e => {
    var t, i, n, s, r, o, a, l;
    return (null !== (t = null === (i = e.removes) || void 0 === i ? void 0 : i.length) && void 0 !== t ? t : 0) + (null !== (n = null === (s = e.attributes) || void 0 === s ? void 0 : s.length) && void 0 !== n ? n : 0) + (null !== (r = null === (o = e.texts) || void 0 === o ? void 0 : o.length) && void 0 !== r ? r : 0) + (null !== (a = null === (l = e.adds) || void 0 === l ? void 0 : l.length) && void 0 !== a ? a : 0);
  };
  throttleMutations = e => {
    if (3 !== e.type || 0 !== e.data.source) return e;
    const t = e.data,
      i = this.numberOfChanges(t);
    t.attributes && (t.attributes = t.attributes.filter(e => {
      var t;
      const [i, n] = this.getNodeOrRelevantParent(e.id);
      if (0 === this.mutationBuckets[i]) return !1;
      var s, r;
      (this.mutationBuckets[i] = null !== (t = this.mutationBuckets[i]) && void 0 !== t ? t : this.bucketSize, this.mutationBuckets[i] = Math.max(this.mutationBuckets[i] - 1, 0), 0 === this.mutationBuckets[i]) && (this.loggedTracker[i] || (this.loggedTracker[i] = !0, null === (s = (r = this.options).onBlockedNode) || void 0 === s || s.call(r, i, n)));
      return e;
    }));
    const n = this.numberOfChanges(t);
    return 0 !== n || i === n ? e : void 0;
  };
}
var Xi = Uint8Array,
  Ki = Uint16Array,
  Zi = Uint32Array,
  en = new Xi([0, 0, 0, 0, 0, 0, 0, 0, 1, 1, 1, 1, 2, 2, 2, 2, 3, 3, 3, 3, 4, 4, 4, 4, 5, 5, 5, 5, 0, 0, 0, 0]),
  tn = new Xi([0, 0, 0, 0, 1, 1, 2, 2, 3, 3, 4, 4, 5, 5, 6, 6, 7, 7, 8, 8, 9, 9, 10, 10, 11, 11, 12, 12, 13, 13, 0, 0]),
  nn = new Xi([16, 17, 18, 0, 8, 7, 9, 6, 10, 5, 11, 4, 12, 3, 13, 2, 14, 1, 15]),
  sn = function (e, t) {
    for (var i = new Ki(31), n = 0; n < 31; ++n) i[n] = t += 1 << e[n - 1];
    var s = new Zi(i[30]);
    for (n = 1; n < 30; ++n) for (var r = i[n]; r < i[n + 1]; ++r) s[r] = r - i[n] << 5 | n;
    return [i, s];
  },
  rn = sn(en, 2),
  on = rn[0],
  an = rn[1];
on[28] = 258, an[258] = 28;
for (var ln = sn(tn, 0)[1], cn = new Ki(32768), un = 0; un < 32768; ++un) {
  var dn = (43690 & un) >>> 1 | (21845 & un) << 1;
  dn = (61680 & (dn = (52428 & dn) >>> 2 | (13107 & dn) << 2)) >>> 4 | (3855 & dn) << 4, cn[un] = ((65280 & dn) >>> 8 | (255 & dn) << 8) >>> 1;
}
var hn = function (e, t, i) {
    for (var n = e.length, s = 0, r = new Ki(t); s < n; ++s) ++r[e[s] - 1];
    var o,
      a = new Ki(t);
    for (s = 0; s < t; ++s) a[s] = a[s - 1] + r[s - 1] << 1;
    if (i) {
      o = new Ki(1 << t);
      var l = 15 - t;
      for (s = 0; s < n; ++s) if (e[s]) for (var c = s << 4 | e[s], u = t - e[s], d = a[e[s] - 1]++ << u, h = d | (1 << u) - 1; d <= h; ++d) o[cn[d] >>> l] = c;
    } else for (o = new Ki(n), s = 0; s < n; ++s) o[s] = cn[a[e[s] - 1]++] >>> 15 - e[s];
    return o;
  },
  _n = new Xi(288);
for (un = 0; un < 144; ++un) _n[un] = 8;
for (un = 144; un < 256; ++un) _n[un] = 9;
for (un = 256; un < 280; ++un) _n[un] = 7;
for (un = 280; un < 288; ++un) _n[un] = 8;
var pn = new Xi(32);
for (un = 0; un < 32; ++un) pn[un] = 5;
var gn = hn(_n, 9, 0),
  fn = hn(pn, 5, 0),
  vn = function (e) {
    return (e / 8 >> 0) + (7 & e && 1);
  },
  mn = function (e, t, i) {
    (null == i || i > e.length) && (i = e.length);
    var n = new (e instanceof Ki ? Ki : e instanceof Zi ? Zi : Xi)(i - t);
    return n.set(e.subarray(t, i)), n;
  },
  yn = function (e, t, i) {
    i <<= 7 & t;
    var n = t / 8 >> 0;
    e[n] |= i, e[n + 1] |= i >>> 8;
  },
  bn = function (e, t, i) {
    i <<= 7 & t;
    var n = t / 8 >> 0;
    e[n] |= i, e[n + 1] |= i >>> 8, e[n + 2] |= i >>> 16;
  },
  wn = function (e, t) {
    for (var i = [], n = 0; n < e.length; ++n) e[n] && i.push({
      s: n,
      f: e[n]
    });
    var s = i.length,
      r = i.slice();
    if (!s) return [new Xi(0), 0];
    if (1 == s) {
      var o = new Xi(i[0].s + 1);
      return o[i[0].s] = 1, [o, 1];
    }
    i.sort(function (e, t) {
      return e.f - t.f;
    }), i.push({
      s: -1,
      f: 25001
    });
    var a = i[0],
      l = i[1],
      c = 0,
      u = 1,
      d = 2;
    for (i[0] = {
      s: -1,
      f: a.f + l.f,
      l: a,
      r: l
    }; u != s - 1;) a = i[i[c].f < i[d].f ? c++ : d++], l = i[c != u && i[c].f < i[d].f ? c++ : d++], i[u++] = {
      s: -1,
      f: a.f + l.f,
      l: a,
      r: l
    };
    var h = r[0].s;
    for (n = 1; n < s; ++n) r[n].s > h && (h = r[n].s);
    var _ = new Ki(h + 1),
      p = Sn(i[u - 1], _, 0);
    if (p > t) {
      n = 0;
      var g = 0,
        f = p - t,
        v = 1 << f;
      for (r.sort(function (e, t) {
        return _[t.s] - _[e.s] || e.f - t.f;
      }); n < s; ++n) {
        var m = r[n].s;
        if (!(_[m] > t)) break;
        g += v - (1 << p - _[m]), _[m] = t;
      }
      for (g >>>= f; g > 0;) {
        var y = r[n].s;
        _[y] < t ? g -= 1 << t - _[y]++ - 1 : ++n;
      }
      for (; n >= 0 && g; --n) {
        var b = r[n].s;
        _[b] == t && (--_[b], ++g);
      }
      p = t;
    }
    return [new Xi(_), p];
  },
  Sn = function (e, t, i) {
    return -1 == e.s ? Math.max(Sn(e.l, t, i + 1), Sn(e.r, t, i + 1)) : t[e.s] = i;
  },
  En = function (e) {
    for (var t = e.length; t && !e[--t];);
    for (var i = new Ki(++t), n = 0, s = e[0], r = 1, o = function (e) {
        i[n++] = e;
      }, a = 1; a <= t; ++a) if (e[a] == s && a != t) ++r;else {
      if (!s && r > 2) {
        for (; r > 138; r -= 138) o(32754);
        r > 2 && (o(r > 10 ? r - 11 << 5 | 28690 : r - 3 << 5 | 12305), r = 0);
      } else if (r > 3) {
        for (o(s), --r; r > 6; r -= 6) o(8304);
        r > 2 && (o(r - 3 << 5 | 8208), r = 0);
      }
      for (; r--;) o(s);
      r = 1, s = e[a];
    }
    return [i.subarray(0, n), t];
  },
  kn = function (e, t) {
    for (var i = 0, n = 0; n < t.length; ++n) i += e[n] * t[n];
    return i;
  },
  xn = function (e, t, i) {
    var n = i.length,
      s = vn(t + 2);
    e[s] = 255 & n, e[s + 1] = n >>> 8, e[s + 2] = 255 ^ e[s], e[s + 3] = 255 ^ e[s + 1];
    for (var r = 0; r < n; ++r) e[s + r + 4] = i[r];
    return 8 * (s + 4 + n);
  },
  In = function (e, t, i, n, s, r, o, a, l, c, u) {
    yn(t, u++, i), ++s[256];
    for (var d = wn(s, 15), h = d[0], _ = d[1], p = wn(r, 15), g = p[0], f = p[1], v = En(h), m = v[0], y = v[1], b = En(g), w = b[0], S = b[1], E = new Ki(19), k = 0; k < m.length; ++k) E[31 & m[k]]++;
    for (k = 0; k < w.length; ++k) E[31 & w[k]]++;
    for (var x = wn(E, 7), I = x[0], P = x[1], F = 19; F > 4 && !I[nn[F - 1]]; --F);
    var R,
      T,
      $,
      C,
      M = c + 5 << 3,
      A = kn(s, _n) + kn(r, pn) + o,
      L = kn(s, h) + kn(r, g) + o + 14 + 3 * F + kn(E, I) + (2 * E[16] + 3 * E[17] + 7 * E[18]);
    if (M <= A && M <= L) return xn(t, u, e.subarray(l, l + c));
    if (yn(t, u, 1 + (L < A)), u += 2, L < A) {
      R = hn(h, _, 0), T = h, $ = hn(g, f, 0), C = g;
      var O = hn(I, P, 0);
      yn(t, u, y - 257), yn(t, u + 5, S - 1), yn(t, u + 10, F - 4), u += 14;
      for (k = 0; k < F; ++k) yn(t, u + 3 * k, I[nn[k]]);
      u += 3 * F;
      for (var D = [m, w], N = 0; N < 2; ++N) {
        var q = D[N];
        for (k = 0; k < q.length; ++k) {
          var B = 31 & q[k];
          yn(t, u, O[B]), u += I[B], B > 15 && (yn(t, u, q[k] >>> 5 & 127), u += q[k] >>> 12);
        }
      }
    } else R = gn, T = _n, $ = fn, C = pn;
    for (k = 0; k < a; ++k) if (n[k] > 255) {
      B = n[k] >>> 18 & 31;
      bn(t, u, R[B + 257]), u += T[B + 257], B > 7 && (yn(t, u, n[k] >>> 23 & 31), u += en[B]);
      var H = 31 & n[k];
      bn(t, u, $[H]), u += C[H], H > 3 && (bn(t, u, n[k] >>> 5 & 8191), u += tn[H]);
    } else bn(t, u, R[n[k]]), u += T[n[k]];
    return bn(t, u, R[256]), u + T[256];
  },
  Pn = new Zi([65540, 131080, 131088, 131104, 262176, 1048704, 1048832, 2114560, 2117632]),
  Fn = function () {
    for (var e = new Zi(256), t = 0; t < 256; ++t) {
      for (var i = t, n = 9; --n;) i = (1 & i && 3988292384) ^ i >>> 1;
      e[t] = i;
    }
    return e;
  }(),
  Rn = function () {
    var e = 4294967295;
    return {
      p: function (t) {
        for (var i = e, n = 0; n < t.length; ++n) i = Fn[255 & i ^ t[n]] ^ i >>> 8;
        e = i;
      },
      d: function () {
        return 4294967295 ^ e;
      }
    };
  },
  Tn = function (e, t, i, n, s) {
    return function (e, t, i, n, s, r) {
      var o = e.length,
        a = new Xi(n + o + 5 * (1 + Math.floor(o / 7e3)) + s),
        l = a.subarray(n, a.length - s),
        c = 0;
      if (!t || o < 8) for (var u = 0; u <= o; u += 65535) {
        var d = u + 65535;
        d < o ? c = xn(l, c, e.subarray(u, d)) : (l[u] = r, c = xn(l, c, e.subarray(u, o)));
      } else {
        for (var h = Pn[t - 1], _ = h >>> 13, p = 8191 & h, g = (1 << i) - 1, f = new Ki(32768), v = new Ki(g + 1), m = Math.ceil(i / 3), y = 2 * m, b = function (t) {
            return (e[t] ^ e[t + 1] << m ^ e[t + 2] << y) & g;
          }, w = new Zi(25e3), S = new Ki(288), E = new Ki(32), k = 0, x = 0, I = (u = 0, 0), P = 0, F = 0; u < o; ++u) {
          var R = b(u),
            T = 32767 & u,
            $ = v[R];
          if (f[T] = $, v[R] = T, P <= u) {
            var C = o - u;
            if ((k > 7e3 || I > 24576) && C > 423) {
              c = In(e, l, 0, w, S, E, x, I, F, u - F, c), I = k = x = 0, F = u;
              for (var M = 0; M < 286; ++M) S[M] = 0;
              for (M = 0; M < 30; ++M) E[M] = 0;
            }
            var A = 2,
              L = 0,
              O = p,
              D = T - $ & 32767;
            if (C > 2 && R == b(u - D)) for (var N = Math.min(_, C) - 1, q = Math.min(32767, u), B = Math.min(258, C); D <= q && --O && T != $;) {
              if (e[u + A] == e[u + A - D]) {
                for (var H = 0; H < B && e[u + H] == e[u + H - D]; ++H);
                if (H > A) {
                  if (A = H, L = D, H > N) break;
                  var U = Math.min(D, H - 2),
                    W = 0;
                  for (M = 0; M < U; ++M) {
                    var z = u - D + M + 32768 & 32767,
                      V = z - f[z] + 32768 & 32767;
                    V > W && (W = V, $ = z);
                  }
                }
              }
              D += (T = $) - ($ = f[T]) + 32768 & 32767;
            }
            if (L) {
              w[I++] = 268435456 | an[A] << 18 | ln[L];
              var G = 31 & an[A],
                j = 31 & ln[L];
              x += en[G] + tn[j], ++S[257 + G], ++E[j], P = u + A, ++k;
            } else w[I++] = e[u], ++S[e[u]];
          }
        }
        c = In(e, l, r, w, S, E, x, I, F, u - F, c);
      }
      return mn(a, 0, n + vn(c) + s);
    }(e, null == t.level ? 6 : t.level, null == t.mem ? Math.ceil(1.5 * Math.max(8, Math.min(13, Math.log(e.length)))) : 12 + t.mem, i, n, !s);
  },
  $n = function (e, t, i) {
    for (; i; ++t) e[t] = i, i >>>= 8;
  },
  Cn = function (e, t) {
    var i = t.filename;
    if (e[0] = 31, e[1] = 139, e[2] = 8, e[8] = t.level < 2 ? 4 : 9 == t.level ? 2 : 0, e[9] = 3, 0 != t.mtime && $n(e, 4, Math.floor(new Date(t.mtime || Date.now()) / 1e3)), i) {
      e[3] = 8;
      for (var n = 0; n <= i.length; ++n) e[n + 10] = i.charCodeAt(n);
    }
  },
  Mn = function (e) {
    return 10 + (e.filename && e.filename.length + 1 || 0);
  };
function An(e, t) {
  void 0 === t && (t = {});
  var i = Rn(),
    n = e.length;
  i.p(e);
  var s = Tn(e, t, Mn(t), 8),
    r = s.length;
  return Cn(s, t), $n(s, r - 8, i.d()), $n(s, r - 4, n), s;
}
function Ln(e, t) {
  var i = e.length;
  if ("undefined" != typeof TextEncoder) return new TextEncoder().encode(e);
  for (var n = new Xi(e.length + (e.length >>> 1)), s = 0, r = function (e) {
      n[s++] = e;
    }, o = 0; o < i; ++o) {
    if (s + 5 > n.length) {
      var a = new Xi(s + 8 + (i - o << 1));
      a.set(n), n = a;
    }
    var l = e.charCodeAt(o);
    l < 128 || t ? r(l) : l < 2048 ? (r(192 | l >>> 6), r(128 | 63 & l)) : l > 55295 && l < 57344 ? (r(240 | (l = 65536 + (1047552 & l) | 1023 & e.charCodeAt(++o)) >>> 18), r(128 | l >>> 12 & 63), r(128 | l >>> 6 & 63), r(128 | 63 & l)) : (r(224 | l >>> 12), r(128 | l >>> 6 & 63), r(128 | 63 & l));
  }
  return mn(n, 0, s);
}
function On(e, t, i, n) {
  return P(e) ? e > i ? (n && $.warn(n + " cannot be  greater than max: " + i + ". Using max value instead."), i) : e < t ? (n && $.warn(n + " cannot be less than min: " + t + ". Using min value instead."), t) : e : (n && $.warn(n + " must be a number. Defaulting to max value:" + i), i);
}
const Dn = 3e5,
  Nn = [_i.MouseMove, _i.MouseInteraction, _i.Scroll, _i.ViewportResize, _i.Input, _i.TouchMove, _i.MediaInteraction, _i.Drag],
  qn = ["trigger_activated", "trigger_pending", "trigger_disabled"],
  Bn = e => ({
    rrwebMethod: e,
    enqueuedAt: Date.now(),
    attempt: 1
  }),
  Hn = "[SessionRecording]";
function Un(e) {
  return function (e, t) {
    for (var i = "", n = 0; n < e.length;) {
      var s = e[n++];
      s < 128 || t ? i += String.fromCharCode(s) : s < 224 ? i += String.fromCharCode((31 & s) << 6 | 63 & e[n++]) : s < 240 ? i += String.fromCharCode((15 & s) << 12 | (63 & e[n++]) << 6 | 63 & e[n++]) : (s = ((15 & s) << 18 | (63 & e[n++]) << 12 | (63 & e[n++]) << 6 | 63 & e[n++]) - 65536, i += String.fromCharCode(55296 | s >> 10, 56320 | 1023 & s));
    }
    return i;
  }(An(Ln(JSON.stringify(e))), !0);
}
function Wn(e) {
  return e.type === hi.Custom && "sessionIdle" === e.data.tag;
}
class zn {
  queuedRRWebEvents = [];
  isIdle = !1;
  _linkedFlagSeen = !1;
  _lastActivityTimestamp = Date.now();
  _linkedFlag = null;
  _removePageViewCaptureHook = void 0;
  _onSessionIdListener = void 0;
  _persistDecideOnSessionListener = void 0;
  _samplingSessionListener = void 0;
  _urlTriggers = [];
  _forceAllowLocalhostNetworkCapture = !1;
  get sessionIdleThresholdMilliseconds() {
    return this.instance.config.session_recording.session_idle_threshold_ms || 3e5;
  }
  get rrwebRecord() {
    var e, t;
    return null == h || null === (e = h.__PosthogExtensions__) || void 0 === e || null === (t = e.rrweb) || void 0 === t ? void 0 : t.record;
  }
  get started() {
    return this._captureStarted;
  }
  get sessionManager() {
    if (!this.instance.sessionManager) throw new Error(Hn + " must be started with a valid sessionManager.");
    return this.instance.sessionManager;
  }
  get fullSnapshotIntervalMillis() {
    var e, t;
    return "trigger_pending" === this.urlTriggerStatus ? 6e4 : null !== (e = null === (t = this.instance.config.session_recording) || void 0 === t ? void 0 : t.full_snapshot_interval_millis) && void 0 !== e ? e : Dn;
  }
  get isSampled() {
    const e = this.instance.get_property(_e);
    return F(e) ? e : null;
  }
  get sessionDuration() {
    var e, t;
    const i = null === (e = this.buffer) || void 0 === e ? void 0 : e.data[(null === (t = this.buffer) || void 0 === t ? void 0 : t.data.length) - 1],
      {
        sessionStartTimestamp: n
      } = this.sessionManager.checkAndGetSessionAndWindowId(!0);
    return i ? i.timestamp - n : null;
  }
  get isRecordingEnabled() {
    const t = !!this.instance.get_property(oe),
      i = !this.instance.config.disable_session_recording;
    return e && t && i;
  }
  get isConsoleLogCaptureEnabled() {
    const e = !!this.instance.get_property(ae),
      t = this.instance.config.enable_recording_console_log;
    return null != t ? t : e;
  }
  get canvasRecording() {
    var e, t, i, n, s, r;
    const o = this.instance.config.session_recording.captureCanvas,
      a = this.instance.get_property(ce),
      l = null !== (e = null !== (t = null == o ? void 0 : o.recordCanvas) && void 0 !== t ? t : null == a ? void 0 : a.enabled) && void 0 !== e && e,
      c = null !== (i = null !== (n = null == o ? void 0 : o.canvasFps) && void 0 !== n ? n : null == a ? void 0 : a.fps) && void 0 !== i ? i : 0,
      u = null !== (s = null !== (r = null == o ? void 0 : o.canvasQuality) && void 0 !== r ? r : null == a ? void 0 : a.quality) && void 0 !== s ? s : 0;
    return {
      enabled: l,
      fps: On(c, 0, 12, "canvas recording fps"),
      quality: On(u, 0, 1, "canvas recording quality")
    };
  }
  get networkPayloadCapture() {
    var e, t;
    const i = this.instance.get_property(le),
      n = {
        recordHeaders: null === (e = this.instance.config.session_recording) || void 0 === e ? void 0 : e.recordHeaders,
        recordBody: null === (t = this.instance.config.session_recording) || void 0 === t ? void 0 : t.recordBody
      },
      s = (null == n ? void 0 : n.recordHeaders) || (null == i ? void 0 : i.recordHeaders),
      r = (null == n ? void 0 : n.recordBody) || (null == i ? void 0 : i.recordBody),
      o = b(this.instance.config.capture_performance) ? this.instance.config.capture_performance.network_timing : this.instance.config.capture_performance,
      a = !!(F(o) ? o : null == i ? void 0 : i.capturePerformance);
    return s || r || a ? {
      recordHeaders: s,
      recordBody: r,
      recordPerformance: a
    } : void 0;
  }
  get sampleRate() {
    const e = this.instance.get_property(ue);
    return P(e) ? e : null;
  }
  get minimumDuration() {
    const e = this.instance.get_property(de);
    return P(e) ? e : null;
  }
  get status() {
    return this.receivedDecide ? this.isRecordingEnabled ? I(this._linkedFlag) || this._linkedFlagSeen ? "trigger_pending" === this.urlTriggerStatus ? "buffering" : F(this.isSampled) ? this.isSampled ? "sampled" : "disabled" : "active" : "buffering" : "disabled" : "buffering";
  }
  get urlTriggerStatus() {
    var e, t;
    if (this.receivedDecide && 0 === this._urlTriggers.length) return "trigger_disabled";
    const i = null === (e = this.instance) || void 0 === e ? void 0 : e.get_property(ge);
    var n, s, r, o;
    return (null === (t = this.instance) || void 0 === t ? void 0 : t.get_property(pe)) !== this.sessionId ? (null === (n = this.instance) || void 0 === n || null === (s = n.persistence) || void 0 === s || s.unregister(pe), null === (r = this.instance) || void 0 === r || null === (o = r.persistence) || void 0 === o || o.unregister(ge), "trigger_pending") : qn.includes(i) ? i : "trigger_pending";
  }
  set urlTriggerStatus(e) {
    var t, i;
    null === (t = this.instance) || void 0 === t || null === (i = t.persistence) || void 0 === i || i.register({
      [pe]: this.sessionId,
      [ge]: e
    });
  }
  constructor(e) {
    if (this.instance = e, this._captureStarted = !1, this._endpoint = "/s/", this.stopRrweb = void 0, this.receivedDecide = !1, !this.instance.sessionManager) throw $.error(Hn + " started without valid sessionManager"), new Error(Hn + " started without valid sessionManager. This is a bug.");
    const {
      sessionId: t,
      windowId: i
    } = this.sessionManager.checkAndGetSessionAndWindowId();
    this.sessionId = t, this.windowId = i, this.buffer = this.clearBuffer(), this.sessionIdleThresholdMilliseconds >= this.sessionManager.sessionTimeoutMs && $.warn(Hn + ` session_idle_threshold_ms (${this.sessionIdleThresholdMilliseconds}) is greater than the session timeout (${this.sessionManager.sessionTimeoutMs}). Session will never be detected as idle`);
  }
  _onBeforeUnload = () => {
    this._flushBuffer();
  };
  _onOffline = () => {
    this._tryAddCustomEvent("browser offline", {});
  };
  _onOnline = () => {
    this._tryAddCustomEvent("browser online", {});
  };
  _onVisibilityChange = () => {
    if (null != o && o.visibilityState) {
      const e = "window " + o.visibilityState;
      this._tryAddCustomEvent(e, {});
    }
  };
  startIfEnabledOrStop(t) {
    this.isRecordingEnabled ? (this._startCapture(t), null == e || e.addEventListener("beforeunload", this._onBeforeUnload), null == e || e.addEventListener("offline", this._onOffline), null == e || e.addEventListener("online", this._onOnline), null == e || e.addEventListener("visibilitychange", this._onVisibilityChange), this._setupSampling(), I(this._removePageViewCaptureHook) && (this._removePageViewCaptureHook = this.instance._addCaptureHook(t => {
      try {
        if ("$pageview" === t) {
          const t = e ? this._maskUrl(e.location.href) : "";
          if (!t) return;
          this._tryAddCustomEvent("$pageview", {
            href: t
          });
        }
      } catch (e) {
        $.error("Could not add $pageview to rrweb session", e);
      }
    })), this._onSessionIdListener || (this._onSessionIdListener = this.sessionManager.onSessionId((e, t, i) => {
      var n, s, r, o;
      i && (this._tryAddCustomEvent("$session_id_change", {
        sessionId: e,
        windowId: t,
        changeReason: i
      }), null === (n = this.instance) || void 0 === n || null === (s = n.persistence) || void 0 === s || s.unregister(pe), null === (r = this.instance) || void 0 === r || null === (o = r.persistence) || void 0 === o || o.unregister(ge));
    }))) : this.stopRecording();
  }
  stopRecording() {
    var t, i, n;
    this._captureStarted && this.stopRrweb && (this.stopRrweb(), this.stopRrweb = void 0, this._captureStarted = !1, null == e || e.removeEventListener("beforeunload", this._onBeforeUnload), null == e || e.removeEventListener("offline", this._onOffline), null == e || e.removeEventListener("online", this._onOnline), null == e || e.removeEventListener("visibilitychange", this._onVisibilityChange), this.clearBuffer(), clearInterval(this._fullSnapshotTimer), null === (t = this._removePageViewCaptureHook) || void 0 === t || t.call(this), this._removePageViewCaptureHook = void 0, null === (i = this._onSessionIdListener) || void 0 === i || i.call(this), this._onSessionIdListener = void 0, null === (n = this._samplingSessionListener) || void 0 === n || n.call(this), this._samplingSessionListener = void 0, $.info(Hn + " stopped"));
  }
  makeSamplingDecision(e) {
    var t;
    const i = this.sessionId !== e,
      n = this.sampleRate;
    var s;
    if (!P(n)) return void (null === (s = this.instance.persistence) || void 0 === s || s.register({
      [_e]: null
    }));
    const r = this.isSampled;
    let o;
    const a = i || !F(r);
    if (a) {
      o = Math.random() < n;
    } else o = r;
    a && (o ? this._reportStarted("sampling") : $.warn(Hn + ` Sample rate (${n}) has determined that this sessionId (${e}) will not be sent to the server.`), this._tryAddCustomEvent("samplingDecisionMade", {
      sampleRate: n,
      isSampled: o
    })), null === (t = this.instance.persistence) || void 0 === t || t.register({
      [_e]: o
    });
  }
  afterDecideResponse(e) {
    var t, i, n, s;
    (this._persistDecideResponse(e), this._linkedFlag = (null === (t = e.sessionRecording) || void 0 === t ? void 0 : t.linkedFlag) || null, null !== (i = e.sessionRecording) && void 0 !== i && i.endpoint) && (this._endpoint = null === (s = e.sessionRecording) || void 0 === s ? void 0 : s.endpoint);
    if (this._setupSampling(), !I(this._linkedFlag) && !this._linkedFlagSeen) {
      const e = E(this._linkedFlag) ? this._linkedFlag : this._linkedFlag.flag,
        t = E(this._linkedFlag) ? null : this._linkedFlag.variant;
      this.instance.onFeatureFlags((i, n) => {
        const s = b(n) && e in n,
          r = t ? n[e] === t : s;
        if (r) {
          const i = {
              linkedFlag: e,
              linkedVariant: t
            },
            n = "linked flag matched";
          $.info(Hn + " " + n, i), this._tryAddCustomEvent(n, i), this._reportStarted("linked_flag_match");
        }
        this._linkedFlagSeen = r;
      });
    }
    null !== (n = e.sessionRecording) && void 0 !== n && n.urlTriggers && (this._urlTriggers = e.sessionRecording.urlTriggers), this.receivedDecide = !0, this.startIfEnabledOrStop();
  }
  _setupSampling() {
    P(this.sampleRate) && I(this._samplingSessionListener) && (this._samplingSessionListener = this.sessionManager.onSessionId(e => {
      this.makeSamplingDecision(e);
    }));
  }
  _persistDecideResponse(e) {
    if (this.instance.persistence) {
      var t;
      const i = this.instance.persistence,
        n = () => {
          var t, n, s, r, o, a, l;
          const c = null === (t = e.sessionRecording) || void 0 === t ? void 0 : t.sampleRate,
            u = I(c) ? null : parseFloat(c),
            d = null === (n = e.sessionRecording) || void 0 === n ? void 0 : n.minimumDurationMilliseconds;
          i.register({
            [oe]: !!e.sessionRecording,
            [ae]: null === (s = e.sessionRecording) || void 0 === s ? void 0 : s.consoleLogRecordingEnabled,
            [le]: {
              capturePerformance: e.capturePerformance,
              ...(null === (r = e.sessionRecording) || void 0 === r ? void 0 : r.networkPayloadCapture)
            },
            [ce]: {
              enabled: null === (o = e.sessionRecording) || void 0 === o ? void 0 : o.recordCanvas,
              fps: null === (a = e.sessionRecording) || void 0 === a ? void 0 : a.canvasFps,
              quality: null === (l = e.sessionRecording) || void 0 === l ? void 0 : l.canvasQuality
            },
            [ue]: u,
            [de]: S(d) ? null : d
          });
        };
      n(), null === (t = this._persistDecideOnSessionListener) || void 0 === t || t.call(this), this._persistDecideOnSessionListener = this.sessionManager.onSessionId(n);
    }
  }
  log(e) {
    var t;
    let i = arguments.length > 1 && void 0 !== arguments[1] ? arguments[1] : "log";
    null === (t = this.instance.sessionRecording) || void 0 === t || t.onRRwebEmit({
      type: 6,
      data: {
        plugin: "rrweb/console@1",
        payload: {
          level: i,
          trace: [],
          payload: [JSON.stringify(e)]
        }
      },
      timestamp: Date.now()
    });
  }
  _startCapture(e) {
    if (!S(Object.assign) && !S(Array.from) && !(this._captureStarted || this.instance.config.disable_session_recording || this.instance.consent.isOptedOut())) {
      var t, i;
      if (this._captureStarted = !0, this.sessionManager.checkAndGetSessionAndWindowId(), this.rrwebRecord) this._onScriptLoaded();else null === (t = h.__PosthogExtensions__) || void 0 === t || null === (i = t.loadExternalDependency) || void 0 === i || i.call(t, this.instance, "recorder", e => {
        if (e) return $.error(Hn + " could not load recorder", e);
        this._onScriptLoaded();
      });
      $.info(Hn + " starting"), "active" === this.status && this._reportStarted(e || "recording_initialized");
    }
  }
  isInteractiveEvent(e) {
    var t;
    return 3 === e.type && -1 !== Nn.indexOf(null === (t = e.data) || void 0 === t ? void 0 : t.source);
  }
  _updateWindowAndSessionIds(e) {
    const t = this.isInteractiveEvent(e);
    if (!t && !this.isIdle) {
      e.timestamp - this._lastActivityTimestamp > this.sessionIdleThresholdMilliseconds && (this.isIdle = !0, clearInterval(this._fullSnapshotTimer), this._tryAddCustomEvent("sessionIdle", {
        eventTimestamp: e.timestamp,
        lastActivityTimestamp: this._lastActivityTimestamp,
        threshold: this.sessionIdleThresholdMilliseconds,
        bufferLength: this.buffer.data.length,
        bufferSize: this.buffer.size
      }), this._flushBuffer());
    }
    let i = !1;
    if (t && (this._lastActivityTimestamp = e.timestamp, this.isIdle && (this.isIdle = !1, this._tryAddCustomEvent("sessionNoLongerIdle", {
      reason: "user activity",
      type: e.type
    }), i = !0)), this.isIdle) return;
    const {
        windowId: n,
        sessionId: s
      } = this.sessionManager.checkAndGetSessionAndWindowId(!t, e.timestamp),
      r = this.sessionId !== s,
      o = this.windowId !== n;
    this.windowId = n, this.sessionId = s, r || o ? (this.stopRecording(), this.startIfEnabledOrStop("session_id_changed")) : i && this._scheduleFullSnapshot();
  }
  _tryRRWebMethod(e) {
    try {
      return e.rrwebMethod(), !0;
    } catch (t) {
      return this.queuedRRWebEvents.length < 10 ? this.queuedRRWebEvents.push({
        enqueuedAt: e.enqueuedAt || Date.now(),
        attempt: e.attempt++,
        rrwebMethod: e.rrwebMethod
      }) : $.warn(Hn + " could not emit queued rrweb event.", t, e), !1;
    }
  }
  _tryAddCustomEvent(e, t) {
    return this._tryRRWebMethod(Bn(() => this.rrwebRecord.addCustomEvent(e, t)));
  }
  _tryTakeFullSnapshot() {
    return this._tryRRWebMethod(Bn(() => this.rrwebRecord.takeFullSnapshot()));
  }
  _onScriptLoaded() {
    var e;
    const t = {
        blockClass: "ph-no-capture",
        blockSelector: void 0,
        ignoreClass: "ph-ignore-input",
        maskTextClass: "ph-mask",
        maskTextSelector: void 0,
        maskTextFn: void 0,
        maskAllInputs: !0,
        maskInputOptions: {
          password: !0
        },
        maskInputFn: void 0,
        slimDOMOptions: {},
        collectFonts: !1,
        inlineStylesheet: !0,
        recordCrossOriginIframes: !1
      },
      i = this.instance.config.session_recording;
    for (const [e, n] of Object.entries(i || {})) e in t && ("maskInputOptions" === e ? t.maskInputOptions = {
      password: !0,
      ...n
    } : t[e] = n);
    if (this.canvasRecording && this.canvasRecording.enabled && (t.recordCanvas = !0, t.sampling = {
      canvas: this.canvasRecording.fps
    }, t.dataURLOptions = {
      type: "image/webp",
      quality: this.canvasRecording.quality
    }), !this.rrwebRecord) return void $.error(Hn + "onScriptLoaded was called but rrwebRecord is not available. This indicates something has gone wrong.");
    this.mutationRateLimiter = null !== (e = this.mutationRateLimiter) && void 0 !== e ? e : new Yi(this.rrwebRecord, {
      onBlockedNode: (e, t) => {
        const i = `Too many mutations on node '${e}'. Rate limiting. This could be due to SVG animations or something similar`;
        $.info(i, {
          node: t
        }), this.log(Hn + " " + i, "warn");
      }
    });
    const n = this._gatherRRWebPlugins();
    this.stopRrweb = this.rrwebRecord({
      emit: e => {
        this.onRRwebEmit(e);
      },
      plugins: n,
      ...t
    }), this._lastActivityTimestamp = Date.now(), this.isIdle = !1, this._tryAddCustomEvent("$session_options", {
      sessionRecordingOptions: t,
      activePlugins: n.map(e => null == e ? void 0 : e.name)
    }), this._tryAddCustomEvent("$posthog_config", {
      config: this.instance.config
    });
  }
  _scheduleFullSnapshot() {
    if (this._fullSnapshotTimer && clearInterval(this._fullSnapshotTimer), this.isIdle) return;
    const e = this.fullSnapshotIntervalMillis;
    e && (this._fullSnapshotTimer = setInterval(() => {
      this._tryTakeFullSnapshot();
    }, e));
  }
  _gatherRRWebPlugins() {
    var e, t, i, n;
    const s = [],
      r = null === (e = h.__PosthogExtensions__) || void 0 === e || null === (t = e.rrwebPlugins) || void 0 === t ? void 0 : t.getRecordConsolePlugin;
    r && this.isConsoleLogCaptureEnabled && s.push(r());
    const o = null === (i = h.__PosthogExtensions__) || void 0 === i || null === (n = i.rrwebPlugins) || void 0 === n ? void 0 : n.getRecordNetworkPlugin;
    if (this.networkPayloadCapture && y(o)) {
      !st.includes(location.hostname) || this._forceAllowLocalhostNetworkCapture ? s.push(o(Ji(this.instance.config, this.networkPayloadCapture))) : $.info(Hn + " NetworkCapture not started because we are on localhost.");
    }
    return s;
  }
  onRRwebEmit(e) {
    var t;
    if (this._processQueuedEvents(), !e || !b(e)) return;
    if (e.type === hi.Meta) {
      const t = this._maskUrl(e.data.href);
      if (this._lastHref = t, !t) return;
      e.data.href = t;
    } else this._pageViewFallBack();
    this._checkUrlTrigger(), e.type === hi.FullSnapshot && this._scheduleFullSnapshot(), e.type === hi.FullSnapshot && "trigger_pending" === this.urlTriggerStatus && this.clearBuffer();
    const i = this.mutationRateLimiter ? this.mutationRateLimiter.throttleMutations(e) : e;
    if (!i) return;
    const n = function (e) {
      const t = e;
      if (t && b(t) && 6 === t.type && b(t.data) && "rrweb/console@1" === t.data.plugin) {
        t.data.payload.payload.length > 10 && (t.data.payload.payload = t.data.payload.payload.slice(0, 10), t.data.payload.payload.push("...[truncated]"));
        const i = [];
        for (let e = 0; e < t.data.payload.payload.length; e++) t.data.payload.payload[e] && t.data.payload.payload[e].length > 2e3 ? i.push(t.data.payload.payload[e].slice(0, 2e3) + "...[truncated]") : i.push(t.data.payload.payload[e]);
        return t.data.payload.payload = i, e;
      }
      return e;
    }(i);
    if (this._updateWindowAndSessionIds(n), this.isIdle && !Wn(n)) return;
    if (Wn(n)) {
      const e = n.data.payload;
      if (e) {
        const t = e.lastActivityTimestamp,
          i = e.threshold;
        n.timestamp = t + i;
      }
    }
    const s = null === (t = this.instance.config.session_recording.compress_events) || void 0 === t || t ? function (e) {
        if (ui(e) < 1024) return e;
        try {
          if (e.type === hi.FullSnapshot) return {
            ...e,
            data: Un(e.data),
            cv: "2024-10"
          };
          if (e.type === hi.IncrementalSnapshot && e.data.source === _i.Mutation) return {
            ...e,
            cv: "2024-10",
            data: {
              ...e.data,
              texts: Un(e.data.texts),
              attributes: Un(e.data.attributes),
              removes: Un(e.data.removes),
              adds: Un(e.data.adds)
            }
          };
          if (e.type === hi.IncrementalSnapshot && e.data.source === _i.StyleSheetRule) return {
            ...e,
            cv: "2024-10",
            data: {
              ...e.data,
              adds: Un(e.data.adds),
              removes: Un(e.data.removes)
            }
          };
        } catch (e) {
          $.error(Hn + " could not compress event - will use uncompressed event", e);
        }
        return e;
      }(n) : n,
      r = {
        $snapshot_bytes: ui(s),
        $snapshot_data: s,
        $session_id: this.sessionId,
        $window_id: this.windowId
      };
    "disabled" !== this.status ? this._captureSnapshotBuffered(r) : this.clearBuffer();
  }
  _pageViewFallBack() {
    if (this.instance.config.capture_pageview || !e) return;
    const t = this._maskUrl(e.location.href);
    this._lastHref !== t && (this._tryAddCustomEvent("$url_changed", {
      href: t
    }), this._lastHref = t);
  }
  _processQueuedEvents() {
    if (this.queuedRRWebEvents.length) {
      const e = [...this.queuedRRWebEvents];
      this.queuedRRWebEvents = [], e.forEach(e => {
        Date.now() - e.enqueuedAt <= 2e3 && this._tryRRWebMethod(e);
      });
    }
  }
  _maskUrl(e) {
    const t = this.instance.config.session_recording;
    if (t.maskNetworkRequestFn) {
      var i;
      let n = {
        url: e
      };
      return n = t.maskNetworkRequestFn(n), null === (i = n) || void 0 === i ? void 0 : i.url;
    }
    return e;
  }
  clearBuffer() {
    return this.buffer = {
      size: 0,
      data: [],
      sessionId: this.sessionId,
      windowId: this.windowId
    }, this.buffer;
  }
  _flushBuffer() {
    this.flushBufferTimer && (clearTimeout(this.flushBufferTimer), this.flushBufferTimer = void 0);
    const e = this.minimumDuration,
      t = this.sessionDuration,
      i = P(t) && t >= 0,
      n = P(e) && i && t < e;
    if ("buffering" === this.status || n) return this.flushBufferTimer = setTimeout(() => {
      this._flushBuffer();
    }, 2e3), this.buffer;
    if (this.buffer.data.length > 0) {
      di(this.buffer).forEach(e => {
        this._captureSnapshot({
          $snapshot_bytes: e.size,
          $snapshot_data: e.data,
          $session_id: e.sessionId,
          $window_id: e.windowId
        });
      });
    }
    return this.clearBuffer();
  }
  _captureSnapshotBuffered(e) {
    var t;
    const i = 2 + ((null === (t = this.buffer) || void 0 === t ? void 0 : t.data.length) || 0);
    !this.isIdle && (this.buffer.size + e.$snapshot_bytes + i > 943718.4 || this.buffer.sessionId !== this.sessionId) && (this.buffer = this._flushBuffer()), this.buffer.size += e.$snapshot_bytes, this.buffer.data.push(e.$snapshot_data), this.flushBufferTimer || this.isIdle || (this.flushBufferTimer = setTimeout(() => {
      this._flushBuffer();
    }, 2e3));
  }
  _captureSnapshot(e) {
    this.instance.capture("$snapshot", e, {
      _url: this.instance.requestRouter.endpointFor("api", this._endpoint),
      _noTruncate: !0,
      _batchKey: "recordings",
      skip_client_rate_limiting: !0
    });
  }
  _checkUrlTrigger() {
    if (void 0 === e || !e.location.href) return;
    const t = e.location.href;
    this._urlTriggers.some(e => "regex" === e.matching && new RegExp(e.url).test(t)) && this._activateUrlTrigger();
  }
  _activateUrlTrigger() {
    "trigger_pending" === this.urlTriggerStatus && (this.urlTriggerStatus = "trigger_activated", this._tryAddCustomEvent("url trigger activated", {}), this._flushBuffer(), $.info(Hn + " recording triggered by URL pattern match"));
  }
  overrideLinkedFlag() {
    this._linkedFlagSeen = !0, this._reportStarted("linked_flag_override");
  }
  overrideSampling() {
    var e;
    null === (e = this.instance.persistence) || void 0 === e || e.register({
      [_e]: !0
    }), this._reportStarted("sampling_override");
  }
  _reportStarted(e) {
    (arguments.length > 1 && void 0 !== arguments[1] ? arguments[1] : () => !0)() && this.instance.register_for_session({
      $session_recording_start_reason: e
    });
  }
}
class Vn {
  constructor(e) {
    this.instance = e, this.instance.decideEndpointWasHit = this.instance._hasBootstrappedFeatureFlags();
  }
  call() {
    const e = {
      token: this.instance.config.token,
      distinct_id: this.instance.get_distinct_id(),
      groups: this.instance.getGroups(),
      person_properties: this.instance.get_property(me),
      group_properties: this.instance.get_property(ye),
      disable_flags: this.instance.config.advanced_disable_feature_flags || this.instance.config.advanced_disable_feature_flags_on_first_load || void 0
    };
    this.instance._send_request({
      method: "POST",
      url: this.instance.requestRouter.endpointFor("api", "/decide/?v=3"),
      data: e,
      compression: this.instance.config.disable_compression ? void 0 : J.Base64,
      timeout: this.instance.config.feature_flag_request_timeout_ms,
      callback: e => this.parseDecideResponse(e.json)
    });
  }
  parseDecideResponse(e) {
    this.instance.featureFlags.setReloadingPaused(!1), this.instance.featureFlags._startReloadTimer();
    const t = !e;
    if (this.instance.config.advanced_disable_feature_flags_on_first_load || this.instance.config.advanced_disable_feature_flags || this.instance.featureFlags.receivedFeatureFlags(null != e ? e : {}, t), t) $.error("Failed to fetch feature flags from PostHog.");else {
      if (!o || !o.body) return $.info("document not ready yet, trying again in 500 milliseconds..."), void setTimeout(() => {
        this.parseDecideResponse(e);
      }, 500);
      if (this.instance._afterDecideResponse(e), e.siteApps) if (this.instance.config.opt_in_site_apps) for (const {
        id: t,
        url: r
      } of e.siteApps) {
        var i, n, s;
        h[`__$$ph_site_app_${t}`] = this.instance, null === (i = h.__PosthogExtensions__) || void 0 === i || null === (n = (s = i).loadSiteApp) || void 0 === n || n.call(s, this.instance, r, e => {
          if (e) return $.error(`Error while initializing PostHog app with config id ${t}`, e);
        });
      } else e.siteApps.length > 0 && $.error('PostHog site apps are disabled. Enable the "opt_in_site_apps" config to proceed.');
    }
  }
}
const Gn = null != e && e.location ? ct(e.location.hash, "__posthog") || ct(location.hash, "state") : null,
  jn = "_postHogToolbarParams";
var Qn;
!function (e) {
  e[e.UNINITIALIZED = 0] = "UNINITIALIZED", e[e.LOADING = 1] = "LOADING", e[e.LOADED = 2] = "LOADED";
}(Qn || (Qn = {}));
class Jn {
  constructor(e) {
    this.instance = e;
  }
  setToolbarState(e) {
    h.ph_toolbar_state = e;
  }
  getToolbarState() {
    var e;
    return null !== (e = h.ph_toolbar_state) && void 0 !== e ? e : Qn.UNINITIALIZED;
  }
  maybeLoadToolbar() {
    var t, i;
    let n = arguments.length > 0 && void 0 !== arguments[0] ? arguments[0] : void 0,
      s = arguments.length > 1 && void 0 !== arguments[1] ? arguments[1] : void 0,
      r = arguments.length > 2 && void 0 !== arguments[2] ? arguments[2] : void 0;
    if (!e || !o) return !1;
    n = null !== (t = n) && void 0 !== t ? t : e.location, r = null !== (i = r) && void 0 !== i ? i : e.history;
    try {
      if (!s) {
        try {
          e.localStorage.setItem("test", "test"), e.localStorage.removeItem("test");
        } catch {
          return !1;
        }
        s = null == e ? void 0 : e.localStorage;
      }
      const t = Gn || ct(n.hash, "__posthog") || ct(n.hash, "state");
      let i;
      const o = t ? B(() => JSON.parse(atob(decodeURIComponent(t)))) || B(() => JSON.parse(decodeURIComponent(t))) : null;
      return o && "ph_authorize" === o.action ? (i = o, i.source = "url", i && Object.keys(i).length > 0 && (o.desiredHash ? n.hash = o.desiredHash : r ? r.replaceState(r.state, "", n.pathname + n.search) : n.hash = "")) : (i = JSON.parse(s.getItem(jn) || "{}"), i.source = "localstorage", delete i.userIntent), !(!i.token || this.instance.config.token !== i.token) && (this.loadToolbar(i), !0);
    } catch {
      return !1;
    }
  }
  _callLoadToolbar(e) {
    (h.ph_load_toolbar || h.ph_load_editor)(e, this.instance);
  }
  loadToolbar(t) {
    const i = !(null == o || !o.getElementById(Te));
    if (!e || i) return !1;
    const n = "custom" === this.instance.requestRouter.region && this.instance.config.advanced_disable_toolbar_metrics,
      s = {
        token: this.instance.config.token,
        ...t,
        apiURL: this.instance.requestRouter.endpointFor("ui"),
        ...(n ? {
          instrument: !1
        } : {})
      };
    if (e.localStorage.setItem(jn, JSON.stringify({
      ...s,
      source: void 0
    })), this.getToolbarState() === Qn.LOADED) this._callLoadToolbar(s);else if (this.getToolbarState() === Qn.UNINITIALIZED) {
      var r, a;
      this.setToolbarState(Qn.LOADING), null === (r = h.__PosthogExtensions__) || void 0 === r || null === (a = r.loadExternalDependency) || void 0 === a || a.call(r, this.instance, "toolbar", e => {
        if (e) return $.error("Failed to load toolbar", e), void this.setToolbarState(Qn.UNINITIALIZED);
        this.setToolbarState(Qn.LOADED), this._callLoadToolbar(s);
      }), G(e, "turbolinks:load", () => {
        this.setToolbarState(Qn.UNINITIALIZED), this.loadToolbar(s);
      });
    }
    return !0;
  }
  _loadEditor(e) {
    return this.loadToolbar(e);
  }
  maybeLoadEditor() {
    let e = arguments.length > 0 && void 0 !== arguments[0] ? arguments[0] : void 0,
      t = arguments.length > 1 && void 0 !== arguments[1] ? arguments[1] : void 0,
      i = arguments.length > 2 && void 0 !== arguments[2] ? arguments[2] : void 0;
    return this.maybeLoadToolbar(e, t, i);
  }
}
class Yn {
  isPaused = !0;
  queue = [];
  flushTimeoutMs = 3e3;
  constructor(e) {
    this.sendRequest = e;
  }
  enqueue(e) {
    this.queue.push(e), this.flushTimeout || this.setFlushTimeout();
  }
  unload() {
    this.clearFlushTimeout();
    const e = this.queue.length > 0 ? this.formatQueue() : {},
      t = Object.values(e),
      i = [...t.filter(e => 0 === e.url.indexOf("/e")), ...t.filter(e => 0 !== e.url.indexOf("/e"))];
    i.map(e => {
      this.sendRequest({
        ...e,
        transport: "sendBeacon"
      });
    });
  }
  enable() {
    this.isPaused = !1, this.setFlushTimeout();
  }
  setFlushTimeout() {
    this.isPaused || (this.flushTimeout = setTimeout(() => {
      if (this.clearFlushTimeout(), this.queue.length > 0) {
        const e = this.formatQueue();
        for (const t in e) {
          const i = e[t],
            n = new Date().getTime();
          i.data && m(i.data) && O(i.data, e => {
            e.offset = Math.abs(e.timestamp - n), delete e.timestamp;
          }), this.sendRequest(i);
        }
      }
    }, this.flushTimeoutMs));
  }
  clearFlushTimeout() {
    clearTimeout(this.flushTimeout), this.flushTimeout = void 0;
  }
  formatQueue() {
    const e = {};
    return O(this.queue, t => {
      var i;
      const n = t,
        s = (n ? n.batchKey : null) || n.url;
      S(e[s]) && (e[s] = {
        ...n,
        data: []
      }), null === (i = e[s].data) || void 0 === i || i.push(n.data);
    }), this.queue = [], e;
  }
}
const Xn = !!c || !!l,
  Kn = "text/plain",
  Zn = (e, t) => {
    const [i, n] = e.split("?"),
      s = {
        ...t
      };
    null == n || n.split("&").forEach(e => {
      const [t] = e.split("=");
      delete s[t];
    });
    let r = at(s);
    return r = r ? (n ? n + "&" : "") + r : n, `${i}?${r}`;
  },
  es = e => {
    let {
      data: t,
      compression: i
    } = e;
    if (!t) return;
    if (i === J.GZipJS) {
      const e = An(Ln(JSON.stringify(t)), {
          mtime: 0
        }),
        i = new Blob([e], {
          type: Kn
        });
      return {
        contentType: Kn,
        body: i,
        estimatedSize: i.size
      };
    }
    if (i === J.Base64) {
      const e = function (e) {
          const t = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/=";
          let i,
            n,
            s,
            r,
            o,
            a,
            l,
            c,
            u = 0,
            d = 0,
            h = "";
          const _ = [];
          if (!e) return e;
          e = V(e);
          do {
            i = e.charCodeAt(u++), n = e.charCodeAt(u++), s = e.charCodeAt(u++), c = i << 16 | n << 8 | s, r = c >> 18 & 63, o = c >> 12 & 63, a = c >> 6 & 63, l = 63 & c, _[d++] = t.charAt(r) + t.charAt(o) + t.charAt(a) + t.charAt(l);
          } while (u < e.length);
          switch (h = _.join(""), e.length % 3) {
            case 1:
              h = h.slice(0, -2) + "==";
              break;
            case 2:
              h = h.slice(0, -1) + "=";
          }
          return h;
        }(JSON.stringify(t)),
        i = (e => "data=" + encodeURIComponent("string" == typeof e ? e : JSON.stringify(e)))(e);
      return {
        contentType: "application/x-www-form-urlencoded",
        body: i,
        estimatedSize: new Blob([i]).size
      };
    }
    const n = JSON.stringify(t);
    return {
      contentType: "application/json",
      body: n,
      estimatedSize: new Blob([n]).size
    };
  },
  ts = e => {
    var t;
    const i = new c();
    i.open(e.method || "GET", e.url, !0);
    const {
      contentType: n,
      body: s
    } = null !== (t = es(e)) && void 0 !== t ? t : {};
    O(e.headers, function (e, t) {
      i.setRequestHeader(t, e);
    }), n && i.setRequestHeader("Content-Type", n), e.timeout && (i.timeout = e.timeout), i.withCredentials = !0, i.onreadystatechange = () => {
      if (4 === i.readyState) {
        var t;
        const n = {
          statusCode: i.status,
          text: i.responseText
        };
        if (200 === i.status) try {
          n.json = JSON.parse(i.responseText);
        } catch {}
        null === (t = e.callback) || void 0 === t || t.call(e, n);
      }
    }, i.send(s);
  },
  is = e => {
    var t, i;
    const {
        contentType: n,
        body: s,
        estimatedSize: r
      } = null !== (t = es(e)) && void 0 !== t ? t : {},
      o = new Headers();
    O(e.headers, function (e, t) {
      o.append(t, e);
    }), n && o.append("Content-Type", n);
    const a = e.url;
    let c = null;
    if (u) {
      const t = new u();
      c = {
        signal: t.signal,
        timeout: setTimeout(() => t.abort(), e.timeout)
      };
    }
    l(a, {
      method: (null == e ? void 0 : e.method) || "GET",
      headers: o,
      keepalive: "POST" === e.method && (r || 0) < 65536,
      body: s,
      signal: null === (i = c) || void 0 === i ? void 0 : i.signal
    }).then(t => t.text().then(i => {
      var n;
      const s = {
        statusCode: t.status,
        text: i
      };
      if (200 === t.status) try {
        s.json = JSON.parse(i);
      } catch (e) {
        $.error(e);
      }
      null === (n = e.callback) || void 0 === n || n.call(e, s);
    })).catch(t => {
      var i;
      $.error(t), null === (i = e.callback) || void 0 === i || i.call(e, {
        statusCode: 0,
        text: t
      });
    }).finally(() => c ? clearTimeout(c.timeout) : null);
  },
  ns = e => {
    const t = Zn(e.url, {
      beacon: "1"
    });
    try {
      var i;
      const {
          contentType: n,
          body: s
        } = null !== (i = es(e)) && void 0 !== i ? i : {},
        o = "string" == typeof s ? new Blob([s], {
          type: n
        }) : s;
      r.sendBeacon(t, o);
    } catch {}
  },
  ss = [];
c && ss.push({
  transport: "XHR",
  method: ts
}), l && ss.push({
  transport: "fetch",
  method: is
}), null != r && r.sendBeacon && ss.push({
  transport: "sendBeacon",
  method: ns
});
class rs {
  isPolling = !1;
  pollIntervalMs = 3e3;
  queue = [];
  constructor(t) {
    this.instance = t, this.queue = [], this.areWeOnline = !0, !S(e) && "onLine" in e.navigator && (this.areWeOnline = e.navigator.onLine, e.addEventListener("online", () => {
      this.areWeOnline = !0, this.flush();
    }), e.addEventListener("offline", () => {
      this.areWeOnline = !1;
    }));
  }
  retriableRequest(e) {
    let {
      retriesPerformedSoFar: t,
      ...i
    } = e;
    P(t) && t > 0 && (i.url = Zn(i.url, {
      retry_count: t
    })), this.instance._send_request({
      ...i,
      callback: e => {
        var n;
        200 !== e.statusCode && (e.statusCode < 400 || e.statusCode >= 500) && (null != t ? t : 0) < 10 ? this.enqueue({
          retriesPerformedSoFar: t,
          ...i
        }) : null === (n = i.callback) || void 0 === n || n.call(i, e);
      }
    });
  }
  enqueue(e) {
    const t = e.retriesPerformedSoFar || 0;
    e.retriesPerformedSoFar = t + 1;
    const i = function (e) {
        const t = 3e3 * 2 ** e,
          i = t / 2,
          n = Math.min(18e5, t),
          s = (Math.random() - .5) * (n - i);
        return Math.ceil(n + s);
      }(t),
      n = Date.now() + i;
    this.queue.push({
      retryAt: n,
      requestOptions: e
    });
    let s = `Enqueued failed request for retry in ${i}`;
    navigator.onLine || (s += " (Browser is offline)"), $.warn(s), this.isPolling || (this.isPolling = !0, this.poll());
  }
  poll() {
    this.poller && clearTimeout(this.poller), this.poller = setTimeout(() => {
      this.areWeOnline && this.queue.length > 0 && this.flush(), this.poll();
    }, this.pollIntervalMs);
  }
  flush() {
    const e = Date.now(),
      t = [],
      i = this.queue.filter(i => i.retryAt < e || (t.push(i), !1));
    if (this.queue = t, i.length > 0) for (const {
      requestOptions: e
    } of i) this.retriableRequest(e);
  }
  unload() {
    this.poller && (clearTimeout(this.poller), this.poller = void 0);
    for (const {
      requestOptions: e
    } of this.queue) try {
      this.instance._send_request({
        ...e,
        transport: "sendBeacon"
      });
    } catch (e) {
      $.error(e);
    }
    this.queue = [];
  }
}
class os {
  _sessionIdChangedHandlers = [];
  constructor(e, t, i, n) {
    var s;
    this.config = e, this.persistence = t, this._windowId = void 0, this._sessionId = void 0, this._sessionStartTimestamp = null, this._sessionActivityTimestamp = null, this._sessionIdGenerator = i || We, this._windowIdGenerator = n || We;
    const r = e.persistence_name || e.token,
      o = e.session_idle_timeout_seconds || 1800;
    if (this._sessionTimeoutMs = 1e3 * On(o, 60, 1800, "session_idle_timeout_seconds"), this._window_id_storage_key = "ph_" + r + "_window_id", this._primary_window_exists_storage_key = "ph_" + r + "_primary_window_exists", this._canUseSessionStorage()) {
      const e = nt.parse(this._window_id_storage_key),
        t = nt.parse(this._primary_window_exists_storage_key);
      e && !t ? this._windowId = e : nt.remove(this._window_id_storage_key), nt.set(this._primary_window_exists_storage_key, !0);
    }
    if (null !== (s = this.config.bootstrap) && void 0 !== s && s.sessionID) try {
      const e = (e => {
        const t = e.replace(/-/g, "");
        if (32 !== t.length) throw new Error("Not a valid UUID");
        if ("7" !== t[12]) throw new Error("Not a UUIDv7");
        return parseInt(t.substring(0, 12), 16);
      })(this.config.bootstrap.sessionID);
      this._setSessionId(this.config.bootstrap.sessionID, new Date().getTime(), e);
    } catch (e) {
      $.error("Invalid sessionID in bootstrap", e);
    }
    this._listenToReloadWindow();
  }
  get sessionTimeoutMs() {
    return this._sessionTimeoutMs;
  }
  onSessionId(e) {
    return S(this._sessionIdChangedHandlers) && (this._sessionIdChangedHandlers = []), this._sessionIdChangedHandlers.push(e), this._sessionId && e(this._sessionId, this._windowId), () => {
      this._sessionIdChangedHandlers = this._sessionIdChangedHandlers.filter(t => t !== e);
    };
  }
  _canUseSessionStorage() {
    return "memory" !== this.config.persistence && !this.persistence.disabled && nt.is_supported();
  }
  _setWindowId(e) {
    e !== this._windowId && (this._windowId = e, this._canUseSessionStorage() && nt.set(this._window_id_storage_key, e));
  }
  _getWindowId() {
    return this._windowId ? this._windowId : this._canUseSessionStorage() ? nt.parse(this._window_id_storage_key) : null;
  }
  _setSessionId(e, t, i) {
    e === this._sessionId && t === this._sessionActivityTimestamp && i === this._sessionStartTimestamp || (this._sessionStartTimestamp = i, this._sessionActivityTimestamp = t, this._sessionId = e, this.persistence.register({
      [he]: [t, e, i]
    }));
  }
  _getSessionId() {
    if (this._sessionId && this._sessionActivityTimestamp && this._sessionStartTimestamp) return [this._sessionActivityTimestamp, this._sessionId, this._sessionStartTimestamp];
    const e = this.persistence.props[he];
    return m(e) && 2 === e.length && e.push(e[0]), e || [0, null, 0];
  }
  resetSessionId() {
    this._setSessionId(null, null, null);
  }
  _listenToReloadWindow() {
    null == e || e.addEventListener("beforeunload", () => {
      this._canUseSessionStorage() && nt.remove(this._primary_window_exists_storage_key);
    });
  }
  checkAndGetSessionAndWindowId() {
    let e = arguments.length > 0 && void 0 !== arguments[0] && arguments[0];
    const t = (arguments.length > 1 && void 0 !== arguments[1] ? arguments[1] : null) || new Date().getTime();
    let [i, n, s] = this._getSessionId(),
      r = this._getWindowId();
    const o = P(s) && s > 0 && Math.abs(t - s) > 864e5;
    let a = !1;
    const l = !n,
      c = !e && Math.abs(t - i) > this.sessionTimeoutMs;
    l || c || o ? (n = this._sessionIdGenerator(), r = this._windowIdGenerator(), $.info("[SessionId] new session ID generated", {
      sessionId: n,
      windowId: r,
      changeReason: {
        noSessionId: l,
        activityTimeout: c,
        sessionPastMaximumLength: o
      }
    }), s = t, a = !0) : r || (r = this._windowIdGenerator(), a = !0);
    const u = 0 === i || !e || o ? t : i,
      d = 0 === s ? new Date().getTime() : s;
    return this._setWindowId(r), this._setSessionId(n, u, d), a && this._sessionIdChangedHandlers.forEach(e => e(n, r, a ? {
      noSessionId: l,
      activityTimeout: c,
      sessionPastMaximumLength: o
    } : void 0)), {
      sessionId: n,
      windowId: r,
      sessionStartTimestamp: d,
      changeReason: a ? {
        noSessionId: l,
        activityTimeout: c,
        sessionPastMaximumLength: o
      } : void 0,
      lastActivityTimestamp: i
    };
  }
}
let as;
!function (e) {
  e.US = "us", e.EU = "eu", e.CUSTOM = "custom";
}(as || (as = {}));
const ls = "i.posthog.com";
class cs {
  _regionCache = {};
  constructor(e) {
    this.instance = e;
  }
  get apiHost() {
    const e = this.instance.config.api_host.trim().replace(/\/$/, "");
    return "https://app.posthog.com" === e ? "https://us.i.posthog.com" : e;
  }
  get uiHost() {
    var e;
    let t = null === (e = this.instance.config.ui_host) || void 0 === e ? void 0 : e.replace(/\/$/, "");
    return t || (t = this.apiHost.replace(`.${ls}`, ".posthog.com")), "https://app.posthog.com" === t ? "https://us.posthog.com" : t;
  }
  get region() {
    return this._regionCache[this.apiHost] || (/https:\/\/(app|us|us-assets)(\.i)?\.posthog\.com/i.test(this.apiHost) ? this._regionCache[this.apiHost] = as.US : /https:\/\/(eu|eu-assets)(\.i)?\.posthog\.com/i.test(this.apiHost) ? this._regionCache[this.apiHost] = as.EU : this._regionCache[this.apiHost] = as.CUSTOM), this._regionCache[this.apiHost];
  }
  endpointFor(e) {
    let t = arguments.length > 1 && void 0 !== arguments[1] ? arguments[1] : "";
    if (t && (t = "/" === t[0] ? t : `/${t}`), "ui" === e) return this.uiHost + t;
    if (this.region === as.CUSTOM) return this.apiHost + t;
    const i = ls + t;
    switch (e) {
      case "assets":
        return `https://${this.region}-assets.${i}`;
      case "api":
        return `https://${this.region}.${i}`;
    }
  }
}
const us = "posthog-js";
function ds(e) {
  let {
    organization: t,
    projectId: i,
    prefix: n,
    severityAllowList: s = ["error"]
  } = arguments.length > 1 && void 0 !== arguments[1] ? arguments[1] : {};
  return r => {
    var o, a, l, c, u;
    if (!("*" === s || s.includes(r.level)) || !e.__loaded) return r;
    r.tags || (r.tags = {});
    const d = e.requestRouter.endpointFor("ui", `/project/${e.config.token}/person/${e.get_distinct_id()}`);
    r.tags["PostHog Person URL"] = d, e.sessionRecordingStarted() && (r.tags["PostHog Recording URL"] = e.get_session_replay_url({
      withTimestamp: !0
    }));
    const h = (null === (o = r.exception) || void 0 === o ? void 0 : o.values) || [],
      _ = {
        $exception_message: (null === (a = h[0]) || void 0 === a ? void 0 : a.value) || r.message,
        $exception_type: null === (l = h[0]) || void 0 === l ? void 0 : l.type,
        $exception_personURL: d,
        $exception_level: r.level,
        $exception_list: h,
        $sentry_event_id: r.event_id,
        $sentry_exception: r.exception,
        $sentry_exception_message: (null === (c = h[0]) || void 0 === c ? void 0 : c.value) || r.message,
        $sentry_exception_type: null === (u = h[0]) || void 0 === u ? void 0 : u.type,
        $sentry_tags: r.tags,
        $level: r.level
      };
    return t && i && (_.$sentry_url = (n || "https://sentry.io/organizations/") + t + "/issues/?project=" + i + "&query=" + r.event_id), e.exceptions.sendExceptionEvent(_), r;
  };
}
class hs {
  constructor(e, t, i, n, s) {
    this.name = us, this.setupOnce = function (r) {
      r(ds(e, {
        organization: t,
        projectId: i,
        prefix: n,
        severityAllowList: s
      }));
    };
  }
}
function _s(e, t) {
  const i = e.config.segment;
  if (!i) return t();
  !function (e, t) {
    const i = e.config.segment;
    if (!i) return t();
    const n = i => {
        const n = () => i.anonymousId() || We();
        e.config.get_device_id = n, i.id() && (e.register({
          distinct_id: i.id(),
          $device_id: n()
        }), e.persistence.set_property(Ee, "identified")), t();
      },
      s = i.user();
    "then" in s && y(s.then) ? s.then(e => n(e)) : n(s);
  }(e, () => {
    i.register((e => {
      Promise && Promise.resolve || $.warn("This browser does not have Promise support, and can not use the segment integration");
      const t = (t, i) => {
        var n;
        if (!i) return t;
        t.event.userId || t.event.anonymousId === e.get_distinct_id() || ($.info("Segment integration does not have a userId set, resetting PostHog"), e.reset()), t.event.userId && t.event.userId !== e.get_distinct_id() && ($.info("Segment integration has a userId set, identifying with PostHog"), e.identify(t.event.userId));
        const s = e._calculate_event_properties(i, null !== (n = t.event.properties) && void 0 !== n ? n : {}, new Date());
        return t.event.properties = Object.assign({}, s, t.event.properties), t;
      };
      return {
        name: "PostHog JS",
        type: "enrichment",
        version: "1.0.0",
        isLoaded: () => !0,
        load: () => Promise.resolve(),
        track: e => t(e, e.event.event),
        page: e => t(e, "$pageview"),
        identify: e => t(e, "$identify"),
        screen: e => t(e, "$screen")
      };
    })(e)).then(() => {
      t();
    });
  });
}
class ps {
  constructor(e) {
    this._instance = e;
  }
  doPageView(t) {
    var i;
    const n = this._previousPageViewProperties(t);
    return this._currentPath = null !== (i = null == e ? void 0 : e.location.pathname) && void 0 !== i ? i : "", this._instance.scrollManager.resetContext(), this._prevPageviewTimestamp = t, n;
  }
  doPageLeave(e) {
    return this._previousPageViewProperties(e);
  }
  _previousPageViewProperties(e) {
    const t = this._currentPath,
      i = this._prevPageviewTimestamp,
      n = this._instance.scrollManager.getContext();
    if (!i) return {};
    let s = {};
    if (n) {
      let {
        maxScrollHeight: e,
        lastScrollY: t,
        maxScrollY: i,
        maxContentHeight: r,
        lastContentY: o,
        maxContentY: a
      } = n;
      if (!(S(e) || S(t) || S(i) || S(r) || S(o) || S(a))) {
        e = Math.ceil(e), t = Math.ceil(t), i = Math.ceil(i), r = Math.ceil(r), o = Math.ceil(o), a = Math.ceil(a);
        s = {
          $prev_pageview_last_scroll: t,
          $prev_pageview_last_scroll_percentage: e <= 1 ? 1 : On(t / e, 0, 1),
          $prev_pageview_max_scroll: i,
          $prev_pageview_max_scroll_percentage: e <= 1 ? 1 : On(i / e, 0, 1),
          $prev_pageview_last_content: o,
          $prev_pageview_last_content_percentage: r <= 1 ? 1 : On(o / r, 0, 1),
          $prev_pageview_max_content: a,
          $prev_pageview_max_content_percentage: r <= 1 ? 1 : On(a / r, 0, 1)
        };
      }
    }
    return t && (s.$prev_pageview_pathname = t), i && (s.$prev_pageview_duration = (e.getTime() - i.getTime()) / 1e3), s;
  }
}
let gs, fs, vs;
!function (e) {
  e.Popover = "popover", e.API = "api", e.Widget = "widget";
}(gs || (gs = {})), function (e) {
  e.Open = "open", e.MultipleChoice = "multiple_choice", e.SingleChoice = "single_choice", e.Rating = "rating", e.Link = "link";
}(fs || (fs = {})), function (e) {
  e.NextQuestion = "next_question", e.End = "end", e.ResponseBased = "response_based", e.SpecificQuestion = "specific_question";
}(vs || (vs = {}));
class ms {
  events = {};
  constructor() {
    this.events = {};
  }
  on(e, t) {
    return this.events[e] || (this.events[e] = []), this.events[e].push(t), () => {
      this.events[e] = this.events[e].filter(e => e !== t);
    };
  }
  emit(e, t) {
    for (const i of this.events[e] || []) i(t);
    for (const i of this.events["*"] || []) i(e, t);
  }
}
class ys {
  _debugEventEmitter = new ms();
  constructor(e) {
    this.instance = e, this.actionEvents = new Set(), this.actionRegistry = new Set();
  }
  init() {
    var e;
    if (!S(null === (e = this.instance) || void 0 === e ? void 0 : e._addCaptureHook)) {
      var t;
      const e = (e, t) => {
        this.on(e, t);
      };
      null === (t = this.instance) || void 0 === t || t._addCaptureHook(e);
    }
  }
  register(e) {
    var t, i;
    if (!S(null === (t = this.instance) || void 0 === t ? void 0 : t._addCaptureHook) && (e.forEach(e => {
      var t, i;
      null === (t = this.actionRegistry) || void 0 === t || t.add(e), null === (i = e.steps) || void 0 === i || i.forEach(e => {
        var t;
        null === (t = this.actionEvents) || void 0 === t || t.add((null == e ? void 0 : e.event) || "");
      });
    }), null !== (i = this.instance) && void 0 !== i && i.autocapture)) {
      var n;
      const t = new Set();
      e.forEach(e => {
        var i;
        null === (i = e.steps) || void 0 === i || i.forEach(e => {
          null != e && e.selector && t.add(null == e ? void 0 : e.selector);
        });
      }), null === (n = this.instance) || void 0 === n || n.autocapture.setElementSelectors(t);
    }
  }
  on(e, t) {
    var i;
    null != t && 0 != e.length && (this.actionEvents.has(e) || this.actionEvents.has(null == t ? void 0 : t.event)) && this.actionRegistry && (null === (i = this.actionRegistry) || void 0 === i ? void 0 : i.size) > 0 && this.actionRegistry.forEach(e => {
      this.checkAction(t, e) && this._debugEventEmitter.emit("actionCaptured", e.name);
    });
  }
  _addActionHook(e) {
    this.onAction("actionCaptured", t => e(t));
  }
  checkAction(e, t) {
    if (null == (null == t ? void 0 : t.steps)) return !1;
    for (const i of t.steps) if (this.checkStep(e, i)) return !0;
    return !1;
  }
  onAction(e, t) {
    return this._debugEventEmitter.on(e, t);
  }
  checkStep = (e, t) => this.checkStepEvent(e, t) && this.checkStepUrl(e, t) && this.checkStepElement(e, t);
  checkStepEvent = (e, t) => null == t || !t.event || (null == e ? void 0 : e.event) === (null == t ? void 0 : t.event);
  checkStepUrl(e, t) {
    if (null != t && t.url) {
      var i;
      const n = null == e || null === (i = e.properties) || void 0 === i ? void 0 : i.$current_url;
      if (!n || "string" != typeof n) return !1;
      if (!ys.matchString(n, null == t ? void 0 : t.url, (null == t ? void 0 : t.url_matching) || "contains")) return !1;
    }
    return !0;
  }
  static matchString(t, i, n) {
    switch (n) {
      case "regex":
        return !!e && ot(t, i);
      case "exact":
        return i === t;
      case "contains":
        const n = ys.escapeStringRegexp(i).replace(/_/g, ".").replace(/%/g, ".*");
        return ot(t, n);
      default:
        return !1;
    }
  }
  static escapeStringRegexp(e) {
    return e.replace(/[|\\{}()[\]^$+*?.]/g, "\\$&").replace(/-/g, "\\x2d");
  }
  checkStepElement(e, t) {
    if (null != t && t.href || null != t && t.tag_name || null != t && t.text) {
      if (!this.getElementsList(e).some(e => !(null != t && t.href && !ys.matchString(e.href || "", null == t ? void 0 : t.href, (null == t ? void 0 : t.href_matching) || "exact")) && (null == t || !t.tag_name || e.tag_name === (null == t ? void 0 : t.tag_name)) && !(null != t && t.text && !ys.matchString(e.text || "", null == t ? void 0 : t.text, (null == t ? void 0 : t.text_matching) || "exact") && !ys.matchString(e.$el_text || "", null == t ? void 0 : t.text, (null == t ? void 0 : t.text_matching) || "exact")))) return !1;
    }
    if (null != t && t.selector) {
      var i;
      const n = null == e || null === (i = e.properties) || void 0 === i ? void 0 : i.$element_selectors;
      if (!n) return !1;
      if (!n.includes(null == t ? void 0 : t.selector)) return !1;
    }
    return !0;
  }
  getElementsList(e) {
    return null == (null == e ? void 0 : e.properties.$elements) ? [] : null == e ? void 0 : e.properties.$elements;
  }
}
class bs {
  static SURVEY_SHOWN_EVENT_NAME = "survey shown";
  constructor(e) {
    this.instance = e, this.eventToSurveys = new Map(), this.actionToSurveys = new Map();
  }
  register(e) {
    var t;
    S(null === (t = this.instance) || void 0 === t ? void 0 : t._addCaptureHook) || (this.setupEventBasedSurveys(e), this.setupActionBasedSurveys(e));
  }
  setupActionBasedSurveys(e) {
    const t = e.filter(e => {
      var t, i, n, s;
      return (null === (t = e.conditions) || void 0 === t ? void 0 : t.actions) && (null === (i = e.conditions) || void 0 === i || null === (n = i.actions) || void 0 === n || null === (s = n.values) || void 0 === s ? void 0 : s.length) > 0;
    });
    if (0 !== t.length) {
      if (null == this.actionMatcher) {
        this.actionMatcher = new ys(this.instance), this.actionMatcher.init();
        const e = e => {
          this.onAction(e);
        };
        this.actionMatcher._addActionHook(e);
      }
      t.forEach(e => {
        var t, i, n, s, r, o, a, l, c, u;
        e.conditions && null !== (t = e.conditions) && void 0 !== t && t.actions && null !== (i = e.conditions) && void 0 !== i && null !== (n = i.actions) && void 0 !== n && n.values && (null === (s = e.conditions) || void 0 === s || null === (r = s.actions) || void 0 === r || null === (o = r.values) || void 0 === o ? void 0 : o.length) > 0 && (null === (a = this.actionMatcher) || void 0 === a || a.register(e.conditions.actions.values), null === (l = e.conditions) || void 0 === l || null === (c = l.actions) || void 0 === c || null === (u = c.values) || void 0 === u || u.forEach(t => {
          if (t && t.name) {
            const i = this.actionToSurveys.get(t.name);
            i && i.push(e.id), this.actionToSurveys.set(t.name, i || [e.id]);
          }
        }));
      });
    }
  }
  setupEventBasedSurveys(e) {
    var t;
    if (0 === e.filter(e => {
      var t, i, n, s;
      return (null === (t = e.conditions) || void 0 === t ? void 0 : t.events) && (null === (i = e.conditions) || void 0 === i || null === (n = i.events) || void 0 === n || null === (s = n.values) || void 0 === s ? void 0 : s.length) > 0;
    }).length) return;
    null === (t = this.instance) || void 0 === t || t._addCaptureHook((e, t) => {
      this.onEvent(e, t);
    }), e.forEach(e => {
      var t, i, n;
      null === (t = e.conditions) || void 0 === t || null === (i = t.events) || void 0 === i || null === (n = i.values) || void 0 === n || n.forEach(t => {
        if (t && t.name) {
          const i = this.eventToSurveys.get(t.name);
          i && i.push(e.id), this.eventToSurveys.set(t.name, i || [e.id]);
        }
      });
    });
  }
  onEvent(e, t) {
    var i, n;
    const s = (null === (i = this.instance) || void 0 === i || null === (n = i.persistence) || void 0 === n ? void 0 : n.props[we]) || [];
    if (bs.SURVEY_SHOWN_EVENT_NAME == e && t && s.length > 0) {
      var r;
      const e = null == t || null === (r = t.properties) || void 0 === r ? void 0 : r.$survey_id;
      if (e) {
        const t = s.indexOf(e);
        t >= 0 && (s.splice(t, 1), this._updateActivatedSurveys(s));
      }
    } else this.eventToSurveys.has(e) && this._updateActivatedSurveys(s.concat(this.eventToSurveys.get(e) || []));
  }
  onAction(e) {
    var t, i;
    const n = (null === (t = this.instance) || void 0 === t || null === (i = t.persistence) || void 0 === i ? void 0 : i.props[we]) || [];
    this.actionToSurveys.has(e) && this._updateActivatedSurveys(n.concat(this.actionToSurveys.get(e) || []));
  }
  _updateActivatedSurveys(e) {
    var t, i;
    null === (t = this.instance) || void 0 === t || null === (i = t.persistence) || void 0 === i || i.register({
      [we]: [...new Set(e)]
    });
  }
  getSurveys() {
    var e, t;
    const i = null === (e = this.instance) || void 0 === e || null === (t = e.persistence) || void 0 === t ? void 0 : t.props[we];
    return i || [];
  }
  getEventToSurveys() {
    return this.eventToSurveys;
  }
  _getActionMatcher() {
    return this.actionMatcher;
  }
}
var ws,
  Ss,
  Es,
  ks,
  xs,
  Is,
  Ps,
  Fs,
  Rs = {},
  Ts = [],
  $s = /acit|ex(?:s|g|n|p|$)|rph|grid|ows|mnc|ntw|ine[ch]|zoo|^ord|itera/i,
  Cs = Array.isArray;
function Ms(e, t) {
  for (var i in t) e[i] = t[i];
  return e;
}
function As(e) {
  var t = e.parentNode;
  t && t.removeChild(e);
}
function Ls(e, t, i, n, s) {
  var r = {
    type: e,
    props: t,
    key: i,
    ref: n,
    __k: null,
    __: null,
    __b: 0,
    __e: null,
    __d: void 0,
    __c: null,
    constructor: void 0,
    __v: null == s ? ++Es : s,
    __i: -1,
    __u: 0
  };
  return null == s && null != Ss.vnode && Ss.vnode(r), r;
}
function Os(e) {
  return e.children;
}
function Ds(e, t) {
  this.props = e, this.context = t;
}
function Ns(e, t) {
  if (null == t) return e.__ ? Ns(e.__, e.__i + 1) : null;
  for (var i; t < e.__k.length; t++) if (null != (i = e.__k[t]) && null != i.__e) return i.__e;
  return "function" == typeof e.type ? Ns(e) : null;
}
function qs(e) {
  var t, i;
  if (null != (e = e.__) && null != e.__c) {
    for (e.__e = e.__c.base = null, t = 0; t < e.__k.length; t++) if (null != (i = e.__k[t]) && null != i.__e) {
      e.__e = e.__c.base = i.__e;
      break;
    }
    return qs(e);
  }
}
function Bs(e) {
  (!e.__d && (e.__d = !0) && ks.push(e) && !Hs.__r++ || xs !== Ss.debounceRendering) && ((xs = Ss.debounceRendering) || Is)(Hs);
}
function Hs() {
  var e, t, i, n, s, r, o, a, l;
  for (ks.sort(Ps); e = ks.shift();) e.__d && (t = ks.length, n = void 0, r = (s = (i = e).__v).__e, a = [], l = [], (o = i.__P) && ((n = Ms({}, s)).__v = s.__v + 1, Ss.vnode && Ss.vnode(n), Ys(o, n, s, i.__n, void 0 !== o.ownerSVGElement, 32 & s.__u ? [r] : null, a, null == r ? Ns(s) : r, !!(32 & s.__u), l), n.__.__k[n.__i] = n, Xs(a, n, l), n.__e != r && qs(n)), ks.length > t && ks.sort(Ps));
  Hs.__r = 0;
}
function Us(e, t, i, n, s, r, o, a, l, c, u) {
  var d,
    h,
    _,
    p,
    g,
    f = n && n.__k || Ts,
    v = t.length;
  for (i.__d = l, Ws(i, t, f), l = i.__d, d = 0; d < v; d++) null != (_ = i.__k[d]) && "boolean" != typeof _ && "function" != typeof _ && (h = -1 === _.__i ? Rs : f[_.__i] || Rs, _.__i = d, Ys(e, _, h, s, r, o, a, l, c, u), p = _.__e, _.ref && h.ref != _.ref && (h.ref && Zs(h.ref, null, _), u.push(_.ref, _.__c || p, _)), null == g && null != p && (g = p), 65536 & _.__u || h.__k === _.__k ? l = zs(_, l, e) : "function" == typeof _.type && void 0 !== _.__d ? l = _.__d : p && (l = p.nextSibling), _.__d = void 0, _.__u &= -196609);
  i.__d = l, i.__e = g;
}
function Ws(e, t, i) {
  var n,
    s,
    r,
    o,
    a,
    l = t.length,
    c = i.length,
    u = c,
    d = 0;
  for (e.__k = [], n = 0; n < l; n++) null != (s = e.__k[n] = null == (s = t[n]) || "boolean" == typeof s || "function" == typeof s ? null : "string" == typeof s || "number" == typeof s || "bigint" == typeof s || s.constructor == String ? Ls(null, s, null, null, s) : Cs(s) ? Ls(Os, {
    children: s
  }, null, null, null) : void 0 === s.constructor && s.__b > 0 ? Ls(s.type, s.props, s.key, s.ref ? s.ref : null, s.__v) : s) ? (s.__ = e, s.__b = e.__b + 1, a = Vs(s, i, o = n + d, u), s.__i = a, r = null, -1 !== a && (u--, (r = i[a]) && (r.__u |= 131072)), null == r || null === r.__v ? (-1 == a && d--, "function" != typeof s.type && (s.__u |= 65536)) : a !== o && (a === o + 1 ? d++ : a > o ? u > l - o ? d += a - o : d-- : d = a < o && a == o - 1 ? a - o : 0, a !== n + d && (s.__u |= 65536))) : (r = i[n]) && null == r.key && r.__e && (r.__e == e.__d && (e.__d = Ns(r)), er(r, r, !1), i[n] = null, u--);
  if (u) for (n = 0; n < c; n++) null != (r = i[n]) && 0 == (131072 & r.__u) && (r.__e == e.__d && (e.__d = Ns(r)), er(r, r));
}
function zs(e, t, i) {
  var n, s;
  if ("function" == typeof e.type) {
    for (n = e.__k, s = 0; n && s < n.length; s++) n[s] && (n[s].__ = e, t = zs(n[s], t, i));
    return t;
  }
  return e.__e != t && (i.insertBefore(e.__e, t || null), t = e.__e), t && t.nextSibling;
}
function Vs(e, t, i, n) {
  var s = e.key,
    r = e.type,
    o = i - 1,
    a = i + 1,
    l = t[i];
  if (null === l || l && s == l.key && r === l.type) return i;
  if (n > (null != l && 0 == (131072 & l.__u) ? 1 : 0)) for (; o >= 0 || a < t.length;) {
    if (o >= 0) {
      if ((l = t[o]) && 0 == (131072 & l.__u) && s == l.key && r === l.type) return o;
      o--;
    }
    if (a < t.length) {
      if ((l = t[a]) && 0 == (131072 & l.__u) && s == l.key && r === l.type) return a;
      a++;
    }
  }
  return -1;
}
function Gs(e, t, i) {
  "-" === t[0] ? e.setProperty(t, null == i ? "" : i) : e[t] = null == i ? "" : "number" != typeof i || $s.test(t) ? i : i + "px";
}
function js(e, t, i, n, s) {
  var r;
  e: if ("style" === t) {
    if ("string" == typeof i) e.style.cssText = i;else {
      if ("string" == typeof n && (e.style.cssText = n = ""), n) for (t in n) i && t in i || Gs(e.style, t, "");
      if (i) for (t in i) n && i[t] === n[t] || Gs(e.style, t, i[t]);
    }
  } else if ("o" === t[0] && "n" === t[1]) r = t !== (t = t.replace(/(PointerCapture)$|Capture$/, "$1")), t = t.toLowerCase() in e ? t.toLowerCase().slice(2) : t.slice(2), e.l || (e.l = {}), e.l[t + r] = i, i ? n ? i.u = n.u : (i.u = Date.now(), e.addEventListener(t, r ? Js : Qs, r)) : e.removeEventListener(t, r ? Js : Qs, r);else {
    if (s) t = t.replace(/xlink(H|:h)/, "h").replace(/sName$/, "s");else if ("width" !== t && "height" !== t && "href" !== t && "list" !== t && "form" !== t && "tabIndex" !== t && "download" !== t && "rowSpan" !== t && "colSpan" !== t && "role" !== t && t in e) try {
      e[t] = null == i ? "" : i;
      break e;
    } catch (e) {}
    "function" == typeof i || (null == i || !1 === i && "-" !== t[4] ? e.removeAttribute(t) : e.setAttribute(t, i));
  }
}
function Qs(e) {
  var t = this.l[e.type + !1];
  if (e.t) {
    if (e.t <= t.u) return;
  } else e.t = Date.now();
  return t(Ss.event ? Ss.event(e) : e);
}
function Js(e) {
  return this.l[e.type + !0](Ss.event ? Ss.event(e) : e);
}
function Ys(e, t, i, n, s, r, o, a, l, c) {
  var u,
    d,
    h,
    _,
    p,
    g,
    f,
    v,
    m,
    y,
    b,
    w,
    S,
    E,
    k,
    x = t.type;
  if (void 0 !== t.constructor) return null;
  128 & i.__u && (l = !!(32 & i.__u), r = [a = t.__e = i.__e]), (u = Ss.__b) && u(t);
  e: if ("function" == typeof x) try {
    if (v = t.props, m = (u = x.contextType) && n[u.__c], y = u ? m ? m.props.value : u.__ : n, i.__c ? f = (d = t.__c = i.__c).__ = d.__E : ("prototype" in x && x.prototype.render ? t.__c = d = new x(v, y) : (t.__c = d = new Ds(v, y), d.constructor = x, d.render = tr), m && m.sub(d), d.props = v, d.state || (d.state = {}), d.context = y, d.__n = n, h = d.__d = !0, d.__h = [], d._sb = []), null == d.__s && (d.__s = d.state), null != x.getDerivedStateFromProps && (d.__s == d.state && (d.__s = Ms({}, d.__s)), Ms(d.__s, x.getDerivedStateFromProps(v, d.__s))), _ = d.props, p = d.state, d.__v = t, h) null == x.getDerivedStateFromProps && null != d.componentWillMount && d.componentWillMount(), null != d.componentDidMount && d.__h.push(d.componentDidMount);else {
      if (null == x.getDerivedStateFromProps && v !== _ && null != d.componentWillReceiveProps && d.componentWillReceiveProps(v, y), !d.__e && (null != d.shouldComponentUpdate && !1 === d.shouldComponentUpdate(v, d.__s, y) || t.__v === i.__v)) {
        for (t.__v !== i.__v && (d.props = v, d.state = d.__s, d.__d = !1), t.__e = i.__e, t.__k = i.__k, t.__k.forEach(function (e) {
          e && (e.__ = t);
        }), b = 0; b < d._sb.length; b++) d.__h.push(d._sb[b]);
        d._sb = [], d.__h.length && o.push(d);
        break e;
      }
      null != d.componentWillUpdate && d.componentWillUpdate(v, d.__s, y), null != d.componentDidUpdate && d.__h.push(function () {
        d.componentDidUpdate(_, p, g);
      });
    }
    if (d.context = y, d.props = v, d.__P = e, d.__e = !1, w = Ss.__r, S = 0, "prototype" in x && x.prototype.render) {
      for (d.state = d.__s, d.__d = !1, w && w(t), u = d.render(d.props, d.state, d.context), E = 0; E < d._sb.length; E++) d.__h.push(d._sb[E]);
      d._sb = [];
    } else do {
      d.__d = !1, w && w(t), u = d.render(d.props, d.state, d.context), d.state = d.__s;
    } while (d.__d && ++S < 25);
    d.state = d.__s, null != d.getChildContext && (n = Ms(Ms({}, n), d.getChildContext())), h || null == d.getSnapshotBeforeUpdate || (g = d.getSnapshotBeforeUpdate(_, p)), Us(e, Cs(k = null != u && u.type === Os && null == u.key ? u.props.children : u) ? k : [k], t, i, n, s, r, o, a, l, c), d.base = t.__e, t.__u &= -161, d.__h.length && o.push(d), f && (d.__E = d.__ = null);
  } catch (e) {
    t.__v = null, l || null != r ? (t.__e = a, t.__u |= l ? 160 : 32, r[r.indexOf(a)] = null) : (t.__e = i.__e, t.__k = i.__k), Ss.__e(e, t, i);
  } else null == r && t.__v === i.__v ? (t.__k = i.__k, t.__e = i.__e) : t.__e = Ks(i.__e, t, i, n, s, r, o, l, c);
  (u = Ss.diffed) && u(t);
}
function Xs(e, t, i) {
  t.__d = void 0;
  for (var n = 0; n < i.length; n++) Zs(i[n], i[++n], i[++n]);
  Ss.__c && Ss.__c(t, e), e.some(function (t) {
    try {
      e = t.__h, t.__h = [], e.some(function (e) {
        e.call(t);
      });
    } catch (e) {
      Ss.__e(e, t.__v);
    }
  });
}
function Ks(e, t, i, n, s, r, o, a, l) {
  var c,
    u,
    d,
    h,
    _,
    p,
    g,
    f = i.props,
    v = t.props,
    m = t.type;
  if ("svg" === m && (s = !0), null != r) for (c = 0; c < r.length; c++) if ((_ = r[c]) && "setAttribute" in _ == !!m && (m ? _.localName === m : 3 === _.nodeType)) {
    e = _, r[c] = null;
    break;
  }
  if (null == e) {
    if (null === m) return document.createTextNode(v);
    e = s ? document.createElementNS("http://www.w3.org/2000/svg", m) : document.createElement(m, v.is && v), r = null, a = !1;
  }
  if (null === m) f === v || a && e.data === v || (e.data = v);else {
    if (r = r && ws.call(e.childNodes), f = i.props || Rs, !a && null != r) for (f = {}, c = 0; c < e.attributes.length; c++) f[(_ = e.attributes[c]).name] = _.value;
    for (c in f) _ = f[c], "children" == c || ("dangerouslySetInnerHTML" == c ? d = _ : "key" === c || c in v || js(e, c, null, _, s));
    for (c in v) _ = v[c], "children" == c ? h = _ : "dangerouslySetInnerHTML" == c ? u = _ : "value" == c ? p = _ : "checked" == c ? g = _ : "key" === c || a && "function" != typeof _ || f[c] === _ || js(e, c, _, f[c], s);
    if (u) a || d && (u.__html === d.__html || u.__html === e.innerHTML) || (e.innerHTML = u.__html), t.__k = [];else if (d && (e.innerHTML = ""), Us(e, Cs(h) ? h : [h], t, i, n, s && "foreignObject" !== m, r, o, r ? r[0] : i.__k && Ns(i, 0), a, l), null != r) for (c = r.length; c--;) null != r[c] && As(r[c]);
    a || (c = "value", void 0 !== p && (p !== e[c] || "progress" === m && !p || "option" === m && p !== f[c]) && js(e, c, p, f[c], !1), c = "checked", void 0 !== g && g !== e[c] && js(e, c, g, f[c], !1));
  }
  return e;
}
function Zs(e, t, i) {
  try {
    "function" == typeof e ? e(t) : e.current = t;
  } catch (e) {
    Ss.__e(e, i);
  }
}
function er(e, t, i) {
  var n, s;
  if (Ss.unmount && Ss.unmount(e), (n = e.ref) && (n.current && n.current !== e.__e || Zs(n, null, t)), null != (n = e.__c)) {
    if (n.componentWillUnmount) try {
      n.componentWillUnmount();
    } catch (e) {
      Ss.__e(e, t);
    }
    n.base = n.__P = null, e.__c = void 0;
  }
  if (n = e.__k) for (s = 0; s < n.length; s++) n[s] && er(n[s], t, i || "function" != typeof e.type);
  i || null == e.__e || As(e.__e), e.__ = e.__e = e.__d = void 0;
}
function tr(e, t, i) {
  return this.constructor(e, i);
}
ws = Ts.slice, Ss = {
  __e: function (e, t, i, n) {
    for (var s, r, o; t = t.__;) if ((s = t.__c) && !s.__) try {
      if ((r = s.constructor) && null != r.getDerivedStateFromError && (s.setState(r.getDerivedStateFromError(e)), o = s.__d), null != s.componentDidCatch && (s.componentDidCatch(e, n || {}), o = s.__d), o) return s.__E = s;
    } catch (t) {
      e = t;
    }
    throw e;
  }
}, Es = 0, Ds.prototype.setState = function (e, t) {
  var i;
  i = null != this.__s && this.__s !== this.state ? this.__s : this.__s = Ms({}, this.state), "function" == typeof e && (e = e(Ms({}, i), this.props)), e && Ms(i, e), null != e && this.__v && (t && this._sb.push(t), Bs(this));
}, Ds.prototype.forceUpdate = function (e) {
  this.__v && (this.__e = !0, e && this.__h.push(e), Bs(this));
}, Ds.prototype.render = Os, ks = [], Is = "function" == typeof Promise ? Promise.prototype.then.bind(Promise.resolve()) : setTimeout, Ps = function (e, t) {
  return e.__v.__b - t.__v.__b;
}, Hs.__r = 0, Fs = 0;
!function (e, t) {
  var i = {
    __c: t = "__cC" + Fs++,
    __: e,
    Consumer: function (e, t) {
      return e.children(t);
    },
    Provider: function (e) {
      var i, n;
      return this.getChildContext || (i = [], (n = {})[t] = this, this.getChildContext = function () {
        return n;
      }, this.shouldComponentUpdate = function (e) {
        this.props.value !== e.value && i.some(function (e) {
          e.__e = !0, Bs(e);
        });
      }, this.sub = function (e) {
        i.push(e);
        var t = e.componentWillUnmount;
        e.componentWillUnmount = function () {
          i.splice(i.indexOf(e), 1), t && t.call(e);
        };
      }), e.children;
    }
  };
  i.Provider.__ = i.Consumer.contextType = i;
}({
  isPreviewMode: !1,
  previewPageIndex: 0,
  handleCloseSurveyPopup: () => {},
  isPopup: !0
});
const ir = "[Surveys]",
  nr = {
    icontains: t => !!e && e.location.href.toLowerCase().indexOf(t.toLowerCase()) > -1,
    not_icontains: t => !!e && -1 === e.location.href.toLowerCase().indexOf(t.toLowerCase()),
    regex: t => !!e && ot(e.location.href, t),
    not_regex: t => !!e && !ot(e.location.href, t),
    exact: t => (null == e ? void 0 : e.location.href) === t,
    is_not: t => (null == e ? void 0 : e.location.href) !== t
  };
class sr {
  constructor(e) {
    this.instance = e, this._surveyEventReceiver = null;
  }
  afterDecideResponse(e) {
    this._decideServerResponse = !!e.surveys, this.loadIfEnabled();
  }
  reset() {
    localStorage.removeItem("lastSeenSurveyDate");
    const e = (() => {
      const e = [];
      for (let t = 0; t < localStorage.length; t++) {
        const i = localStorage.key(t);
        null != i && i.startsWith("seenSurvey_") && e.push(i);
      }
      return e;
    })();
    e.forEach(e => localStorage.removeItem(e));
  }
  loadIfEnabled() {
    var e;
    const t = null == h || null === (e = h.__PosthogExtensions__) || void 0 === e ? void 0 : e.generateSurveys;
    var i, n;
    this.instance.config.disable_surveys || !this._decideServerResponse || t || (null == this._surveyEventReceiver && (this._surveyEventReceiver = new bs(this.instance)), null === (i = h.__PosthogExtensions__) || void 0 === i || null === (n = i.loadExternalDependency) || void 0 === n || n.call(i, this.instance, "surveys", e => {
      var t, i;
      if (e) return $.error(ir, "Could not load surveys script", e);
      this._surveyManager = null === (t = h.__PosthogExtensions__) || void 0 === t || null === (i = t.generateSurveys) || void 0 === i ? void 0 : i.call(t, this.instance);
    }));
  }
  getSurveys(e) {
    let t = arguments.length > 1 && void 0 !== arguments[1] && arguments[1];
    if (this.instance.config.disable_surveys) return e([]);
    null == this._surveyEventReceiver && (this._surveyEventReceiver = new bs(this.instance));
    const i = this.instance.get_property(be);
    if (i && !t) return e(i);
    this.instance._send_request({
      url: this.instance.requestRouter.endpointFor("api", `/api/surveys/?token=${this.instance.config.token}`),
      method: "GET",
      transport: "XHR",
      callback: t => {
        var i;
        if (200 !== t.statusCode || !t.json) return e([]);
        const n = t.json.surveys || [],
          s = n.filter(e => {
            var t, i, n, s, r, o, a, l, c, u, d, h;
            return (null === (t = e.conditions) || void 0 === t ? void 0 : t.events) && (null === (i = e.conditions) || void 0 === i || null === (n = i.events) || void 0 === n ? void 0 : n.values) && (null === (s = e.conditions) || void 0 === s || null === (r = s.events) || void 0 === r || null === (o = r.values) || void 0 === o ? void 0 : o.length) > 0 || (null === (a = e.conditions) || void 0 === a ? void 0 : a.actions) && (null === (l = e.conditions) || void 0 === l || null === (c = l.actions) || void 0 === c ? void 0 : c.values) && (null === (u = e.conditions) || void 0 === u || null === (d = u.actions) || void 0 === d || null === (h = d.values) || void 0 === h ? void 0 : h.length) > 0;
          });
        var r;
        s.length > 0 && (null === (r = this._surveyEventReceiver) || void 0 === r || r.register(s));
        return null === (i = this.instance.persistence) || void 0 === i || i.register({
          [be]: n
        }), e(n);
      }
    });
  }
  getActiveMatchingSurveys(e) {
    let t = arguments.length > 1 && void 0 !== arguments[1] && arguments[1];
    this.getSurveys(t => {
      var i;
      const n = t.filter(e => !(!e.start_date || e.end_date)).filter(e => {
          var t, i, n, s;
          if (!e.conditions) return !0;
          const r = null === (t = e.conditions) || void 0 === t || !t.url || nr[null !== (i = null === (n = e.conditions) || void 0 === n ? void 0 : n.urlMatchType) && void 0 !== i ? i : "icontains"](e.conditions.url),
            a = null === (s = e.conditions) || void 0 === s || !s.selector || (null == o ? void 0 : o.querySelector(e.conditions.selector));
          return r && a;
        }),
        s = null === (i = this._surveyEventReceiver) || void 0 === i ? void 0 : i.getSurveys(),
        r = n.filter(e => {
          var t, i, n, r, o, a, l, c, u, d;
          if (!e.linked_flag_key && !e.targeting_flag_key && !e.internal_targeting_flag_key) return !0;
          const h = !e.linked_flag_key || this.instance.featureFlags.isFeatureEnabled(e.linked_flag_key),
            _ = !e.targeting_flag_key || this.instance.featureFlags.isFeatureEnabled(e.targeting_flag_key),
            p = (null === (t = e.conditions) || void 0 === t ? void 0 : t.events) && (null === (i = e.conditions) || void 0 === i || null === (n = i.events) || void 0 === n ? void 0 : n.values) && (null === (r = e.conditions) || void 0 === r || null === (o = r.events) || void 0 === o ? void 0 : o.values.length) > 0,
            g = (null === (a = e.conditions) || void 0 === a ? void 0 : a.actions) && (null === (l = e.conditions) || void 0 === l || null === (c = l.actions) || void 0 === c ? void 0 : c.values) && (null === (u = e.conditions) || void 0 === u || null === (d = u.actions) || void 0 === d ? void 0 : d.values.length) > 0,
            f = !p && !g || (null == s ? void 0 : s.includes(e.id)),
            v = this._canActivateRepeatedly(e),
            m = !(e.internal_targeting_flag_key && !v) || this.instance.featureFlags.isFeatureEnabled(e.internal_targeting_flag_key);
          return h && _ && m && f;
        });
      return e(r);
    }, t);
  }
  getNextSurveyStep(e, t, i) {
    var n;
    const s = e.questions[t],
      r = t + 1;
    if (null === (n = s.branching) || void 0 === n || !n.type) return t === e.questions.length - 1 ? vs.End : r;
    if (s.branching.type === vs.End) return vs.End;
    if (s.branching.type === vs.SpecificQuestion) {
      if (Number.isInteger(s.branching.index)) return s.branching.index;
    } else if (s.branching.type === vs.ResponseBased) {
      if (s.type === fs.SingleChoice) {
        var o, a;
        const e = s.choices.indexOf(`${i}`);
        if (null !== (o = s.branching) && void 0 !== o && null !== (a = o.responseValues) && void 0 !== a && a.hasOwnProperty(e)) {
          const t = s.branching.responseValues[e];
          return Number.isInteger(t) ? t : t === vs.End ? vs.End : r;
        }
      } else if (s.type === fs.Rating) {
        var l, c;
        if ("number" != typeof i || !Number.isInteger(i)) throw new Error("The response type must be an integer");
        const e = function (e, t) {
          if (3 === t) {
            if (e < 1 || e > 3) throw new Error("The response must be in range 1-3");
            return 1 === e ? "negative" : 2 === e ? "neutral" : "positive";
          }
          if (5 === t) {
            if (e < 1 || e > 5) throw new Error("The response must be in range 1-5");
            return e <= 2 ? "negative" : 3 === e ? "neutral" : "positive";
          }
          if (7 === t) {
            if (e < 1 || e > 7) throw new Error("The response must be in range 1-7");
            return e <= 3 ? "negative" : 4 === e ? "neutral" : "positive";
          }
          if (10 === t) {
            if (e < 0 || e > 10) throw new Error("The response must be in range 0-10");
            return e <= 6 ? "detractors" : e <= 8 ? "passives" : "promoters";
          }
          throw new Error("The scale must be one of: 3, 5, 7, 10");
        }(i, s.scale);
        if (null !== (l = s.branching) && void 0 !== l && null !== (c = l.responseValues) && void 0 !== c && c.hasOwnProperty(e)) {
          const t = s.branching.responseValues[e];
          return Number.isInteger(t) ? t : t === vs.End ? vs.End : r;
        }
      }
      return r;
    }
    return $.warn(ir, "Falling back to next question index due to unexpected branching type"), r;
  }
  _canActivateRepeatedly(e) {
    var t;
    return I(null === (t = h.__PosthogExtensions__) || void 0 === t ? void 0 : t.canActivateRepeatedly) ? ($.warn(ir, "canActivateRepeatedly is not defined, must init before calling"), !1) : h.__PosthogExtensions__.canActivateRepeatedly(e);
  }
  canRenderSurvey(e) {
    I(this._surveyManager) ? $.warn(ir, "canActivateRepeatedly is not defined, must init before calling") : this.getSurveys(t => {
      const i = t.filter(t => t.id === e)[0];
      this._surveyManager.canRenderSurvey(i);
    });
  }
  renderSurvey(e, t) {
    I(this._surveyManager) ? $.warn(ir, "canActivateRepeatedly is not defined, must init before calling") : this.getSurveys(i => {
      const n = i.filter(t => t.id === e)[0];
      this._surveyManager.renderSurvey(n, null == o ? void 0 : o.querySelector(t));
    });
  }
}
class rr {
  serverLimits = {};
  lastEventRateLimited = !1;
  constructor(e) {
    var t, i;
    this.instance = e, this.captureEventsPerSecond = (null === (t = e.config.rate_limiting) || void 0 === t ? void 0 : t.events_per_second) || 10, this.captureEventsBurstLimit = Math.max((null === (i = e.config.rate_limiting) || void 0 === i ? void 0 : i.events_burst_limit) || 10 * this.captureEventsPerSecond, this.captureEventsPerSecond), this.lastEventRateLimited = this.clientRateLimitContext(!0).isRateLimited;
  }
  clientRateLimitContext() {
    var e, t, i;
    let n = arguments.length > 0 && void 0 !== arguments[0] && arguments[0];
    const s = new Date().getTime(),
      r = null !== (e = null === (t = this.instance.persistence) || void 0 === t ? void 0 : t.get_property(xe)) && void 0 !== e ? e : {
        tokens: this.captureEventsBurstLimit,
        last: s
      };
    r.tokens += (s - r.last) / 1e3 * this.captureEventsPerSecond, r.last = s, r.tokens > this.captureEventsBurstLimit && (r.tokens = this.captureEventsBurstLimit);
    const o = r.tokens < 1;
    return o || n || (r.tokens = Math.max(0, r.tokens - 1)), !o || this.lastEventRateLimited || n || this.instance.capture("$$client_ingestion_warning", {
      $$client_ingestion_warning_message: `posthog-js client rate limited. Config is set to ${this.captureEventsPerSecond} events per second and ${this.captureEventsBurstLimit} events burst limit.`
    }, {
      skip_client_rate_limiting: !0
    }), this.lastEventRateLimited = o, null === (i = this.instance.persistence) || void 0 === i || i.set_property(xe, r), {
      isRateLimited: o,
      remainingTokens: r.tokens
    };
  }
  isServerRateLimited(e) {
    const t = this.serverLimits[e || "events"] || !1;
    return !1 !== t && new Date().getTime() < t;
  }
  checkForLimiting = e => {
    const t = e.text;
    if (t && t.length) try {
      const e = JSON.parse(t);
      (e.quota_limited || []).forEach(e => {
        $.info(`[RateLimiter] ${e || "events"} is quota limited.`), this.serverLimits[e] = new Date().getTime() + 6e4;
      });
    } catch (e) {
      return void $.warn(`[RateLimiter] could not rate limit - continuing. Error: "${null == e ? void 0 : e.message}"`, {
        text: t
      });
    }
  };
}
const or = () => ({
  initialPathName: (null == a ? void 0 : a.pathname) || "",
  referringDomain: ai.referringDomain(),
  ...ai.campaignParams()
});
class ar {
  constructor(e, t, i) {
    this._sessionIdManager = e, this._persistence = t, this._sessionSourceParamGenerator = i || or, this._sessionIdManager.onSessionId(this._onSessionIdCallback);
  }
  _getStoredProps() {
    return this._persistence.props[ke];
  }
  _onSessionIdCallback = e => {
    const t = this._getStoredProps();
    if (t && t.sessionId === e) return;
    const i = {
      sessionId: e,
      props: this._sessionSourceParamGenerator()
    };
    this._persistence.register({
      [ke]: i
    });
  };
  getSessionProps() {
    var e;
    const t = null === (e = this._getStoredProps()) || void 0 === e ? void 0 : e.props;
    return t ? {
      $client_session_initial_referring_host: t.referringDomain,
      $client_session_initial_pathname: t.initialPathName,
      $client_session_initial_utm_source: t.utm_source,
      $client_session_initial_utm_campaign: t.utm_campaign,
      $client_session_initial_utm_medium: t.utm_medium,
      $client_session_initial_utm_content: t.utm_content,
      $client_session_initial_utm_term: t.utm_term
    } : {};
  }
}
const lr = ["ahrefsbot", "ahrefssiteaudit", "applebot", "baiduspider", "bingbot", "bingpreview", "bot.htm", "bot.php", "crawler", "deepscan", "duckduckbot", "facebookexternal", "facebookcatalog", "gptbot", "http://yandex.com/bots", "hubspot", "ia_archiver", "linkedinbot", "mj12bot", "msnbot", "nessus", "petalbot", "pinterest", "prerender", "rogerbot", "screaming frog", "semrushbot", "sitebulb", "slurp", "turnitin", "twitterbot", "vercelbot", "yahoo! slurp", "yandexbot", "headlesschrome", "cypress", "Google-HotelAdsVerifier", "adsbot-google", "apis-google", "duplexweb-google", "feedfetcher-google", "google favicon", "google web preview", "google-read-aloud", "googlebot", "googleweblight", "mediapartners-google", "storebot-google", "Bytespider;"],
  cr = function (e, t) {
    if (!e) return !1;
    const i = e.toLowerCase();
    return lr.concat(t || []).some(e => {
      const t = e.toLowerCase();
      return -1 !== i.indexOf(t);
    });
  },
  ur = function (e, t) {
    if (!e) return !1;
    const i = e.userAgent;
    if (i && cr(i, t)) return !0;
    try {
      const i = null == e ? void 0 : e.userAgentData;
      if (null != i && i.brands && i.brands.some(e => cr(null == e ? void 0 : e.brand, t))) return !0;
    } catch {}
    return !!e.webdriver;
  };
class dr {
  constructor() {
    this.clicks = [];
  }
  isRageClick(e, t, i) {
    const n = this.clicks[this.clicks.length - 1];
    if (n && Math.abs(e - n.x) + Math.abs(t - n.y) < 30 && i - n.timestamp < 1e3) {
      if (this.clicks.push({
        x: e,
        y: t,
        timestamp: i
      }), 3 === this.clicks.length) return !0;
    } else this.clicks = [{
      x: e,
      y: t,
      timestamp: i
    }];
    return !1;
  }
}
function hr(e) {
  var t;
  return e.id === Te || !(null === (t = e.closest) || void 0 === t || !t.call(e, "#" + Te));
}
class _r {
  rageclicks = new dr();
  _enabledServerSide = !1;
  _initialized = !1;
  _flushInterval = null;
  constructor(t) {
    var i;
    this.instance = t, this._enabledServerSide = !(null === (i = this.instance.persistence) || void 0 === i || !i.props[te]), null == e || e.addEventListener("beforeunload", () => {
      this.flush();
    });
  }
  get flushIntervalMilliseconds() {
    let e = 5e3;
    return b(this.instance.config.capture_heatmaps) && this.instance.config.capture_heatmaps.flush_interval_milliseconds && (e = this.instance.config.capture_heatmaps.flush_interval_milliseconds), e;
  }
  get isEnabled() {
    return S(this.instance.config.capture_heatmaps) ? S(this.instance.config.enable_heatmaps) ? this._enabledServerSide : this.instance.config.enable_heatmaps : !1 !== this.instance.config.capture_heatmaps;
  }
  startIfEnabled() {
    if (this.isEnabled) {
      if (this._initialized) return;
      $.info("[heatmaps] starting..."), this._setupListeners(), this._flushInterval = setInterval(this.flush.bind(this), this.flushIntervalMilliseconds);
    } else {
      var e;
      clearInterval(null !== (e = this._flushInterval) && void 0 !== e ? e : void 0), this.getAndClearBuffer();
    }
  }
  afterDecideResponse(e) {
    const t = !!e.heatmaps;
    this.instance.persistence && this.instance.persistence.register({
      [te]: t
    }), this._enabledServerSide = t, this.startIfEnabled();
  }
  getAndClearBuffer() {
    const e = this.buffer;
    return this.buffer = void 0, e;
  }
  _setupListeners() {
    e && o && (G(o, "click", t => this._onClick(t || (null == e ? void 0 : e.event)), !1, !0), G(o, "mousemove", t => this._onMouseMove(t || (null == e ? void 0 : e.event)), !1, !0), this._initialized = !0);
  }
  _getProperties(t, i) {
    const n = this.instance.scrollManager.scrollY(),
      s = this.instance.scrollManager.scrollX(),
      r = this.instance.scrollManager.scrollElement(),
      o = function (t, i, n) {
        let s = t;
        for (; s && bi(s) && !wi(s, "body");) {
          if (s === n) return !1;
          if (N(i, null == e ? void 0 : e.getComputedStyle(s).position)) return !0;
          s = xi(s);
        }
        return !1;
      }(yi(t), ["fixed", "sticky"], r);
    return {
      x: t.clientX + (o ? 0 : s),
      y: t.clientY + (o ? 0 : n),
      target_fixed: o,
      type: i
    };
  }
  _onClick(e) {
    var t;
    if (hr(e.target)) return;
    const i = this._getProperties(e, "click");
    null !== (t = this.rageclicks) && void 0 !== t && t.isRageClick(e.clientX, e.clientY, new Date().getTime()) && this._capture({
      ...i,
      type: "rageclick"
    }), this._capture(i);
  }
  _onMouseMove(e) {
    hr(e.target) || (clearTimeout(this._mouseMoveTimeout), this._mouseMoveTimeout = setTimeout(() => {
      this._capture(this._getProperties(e, "mousemove"));
    }, 500));
  }
  _capture(t) {
    if (!e) return;
    const i = e.location.href;
    this.buffer = this.buffer || {}, this.buffer[i] || (this.buffer[i] = []), this.buffer[i].push(t);
  }
  flush() {
    this.buffer && !w(this.buffer) && this.instance.capture("$$heatmap", {
      $heatmap_data: this.getAndClearBuffer()
    });
  }
}
class pr {
  constructor(e) {
    this.instance = e;
  }
  getContext() {
    return this.context;
  }
  resetContext() {
    const e = this.context;
    return setTimeout(this._updateScrollData, 0), e;
  }
  _updateScrollData = () => {
    var e, t, i, n;
    this.context || (this.context = {});
    const s = this.scrollElement(),
      r = this.scrollY(),
      o = s ? Math.max(0, s.scrollHeight - s.clientHeight) : 0,
      a = r + ((null == s ? void 0 : s.clientHeight) || 0),
      l = (null == s ? void 0 : s.scrollHeight) || 0;
    this.context.lastScrollY = Math.ceil(r), this.context.maxScrollY = Math.max(r, null !== (e = this.context.maxScrollY) && void 0 !== e ? e : 0), this.context.maxScrollHeight = Math.max(o, null !== (t = this.context.maxScrollHeight) && void 0 !== t ? t : 0), this.context.lastContentY = a, this.context.maxContentY = Math.max(a, null !== (i = this.context.maxContentY) && void 0 !== i ? i : 0), this.context.maxContentHeight = Math.max(l, null !== (n = this.context.maxContentHeight) && void 0 !== n ? n : 0);
  };
  startMeasuringScrollPosition() {
    null == e || e.addEventListener("scroll", this._updateScrollData, !0), null == e || e.addEventListener("scrollend", this._updateScrollData, !0), null == e || e.addEventListener("resize", this._updateScrollData);
  }
  scrollElement() {
    if (!this.instance.config.scroll_root_selector) return null == e ? void 0 : e.document.documentElement;
    {
      const t = m(this.instance.config.scroll_root_selector) ? this.instance.config.scroll_root_selector : [this.instance.config.scroll_root_selector];
      for (const i of t) {
        const t = null == e ? void 0 : e.document.querySelector(i);
        if (t) return t;
      }
    }
  }
  scrollY() {
    if (this.instance.config.scroll_root_selector) {
      const e = this.scrollElement();
      return e && e.scrollTop || 0;
    }
    return e && (e.scrollY || e.pageYOffset || e.document.documentElement.scrollTop) || 0;
  }
  scrollX() {
    if (this.instance.config.scroll_root_selector) {
      const e = this.scrollElement();
      return e && e.scrollLeft || 0;
    }
    return e && (e.scrollX || e.pageXOffset || e.document.documentElement.scrollLeft) || 0;
  }
}
const gr = "$copy_autocapture";
function fr(e, t) {
  return t.length > e ? t.slice(0, e) + "..." : t;
}
class vr {
  _initialized = !1;
  _isDisabledServerSide = null;
  rageclicks = new dr();
  _elementsChainAsString = !1;
  constructor(e) {
    this.instance = e, this._elementSelectors = null;
  }
  get config() {
    var e, t;
    const i = b(this.instance.config.autocapture) ? this.instance.config.autocapture : {};
    return i.url_allowlist = null === (e = i.url_allowlist) || void 0 === e ? void 0 : e.map(e => new RegExp(e)), i.url_ignorelist = null === (t = i.url_ignorelist) || void 0 === t ? void 0 : t.map(e => new RegExp(e)), i;
  }
  _addDomEventHandlers() {
    if (!this.isBrowserSupported()) return void $.info("Disabling Automatic Event Collection because this browser is not supported");
    if (!e || !o) return;
    const t = t => {
        t = t || (null == e ? void 0 : e.event);
        try {
          this._captureEvent(t);
        } catch (e) {
          $.error("Failed to capture event", e);
        }
      },
      i = t => {
        t = t || (null == e ? void 0 : e.event), this._captureEvent(t, gr);
      };
    G(o, "submit", t, !1, !0), G(o, "change", t, !1, !0), G(o, "click", t, !1, !0), this.config.capture_copied_text && (G(o, "copy", i, !1, !0), G(o, "cut", i, !1, !0));
  }
  startIfEnabled() {
    this.isEnabled && !this._initialized && (this._addDomEventHandlers(), this._initialized = !0);
  }
  afterDecideResponse(e) {
    e.elementsChainAsString && (this._elementsChainAsString = e.elementsChainAsString), this.instance.persistence && this.instance.persistence.register({
      [ee]: !!e.autocapture_opt_out
    }), this._isDisabledServerSide = !!e.autocapture_opt_out, this.startIfEnabled();
  }
  setElementSelectors(e) {
    this._elementSelectors = e;
  }
  getElementSelectors(e) {
    var t;
    const i = [];
    return null === (t = this._elementSelectors) || void 0 === t || t.forEach(t => {
      const n = null == o ? void 0 : o.querySelectorAll(t);
      null == n || n.forEach(n => {
        e === n && i.push(t);
      });
    }), i;
  }
  get isEnabled() {
    var e, t;
    const i = null === (e = this.instance.persistence) || void 0 === e ? void 0 : e.props[ee],
      n = this._isDisabledServerSide;
    if (x(n) && !F(i) && !this.instance.config.advanced_disable_decide) return !1;
    const s = null !== (t = this._isDisabledServerSide) && void 0 !== t ? t : !!i;
    return !!this.instance.config.autocapture && !s;
  }
  _previousElementSibling(e) {
    if (e.previousElementSibling) return e.previousElementSibling;
    let t = e;
    do {
      t = t.previousSibling;
    } while (t && !bi(t));
    return t;
  }
  _getAugmentPropertiesFromElement(e) {
    if (!Pi(e)) return {};
    const t = {};
    return O(e.attributes, function (e) {
      if (e.name && 0 === e.name.indexOf("data-ph-capture-attribute")) {
        const i = e.name.replace("data-ph-capture-attribute-", ""),
          n = e.value;
        i && n && Li(n) && (t[i] = n);
      }
    }), t;
  }
  _getPropertiesFromElement(e, t, i) {
    var n;
    const s = e.tagName.toLowerCase(),
      r = {
        tag_name: s
      };
    ki.indexOf(s) > -1 && !i && ("a" === s.toLowerCase() || "button" === s.toLowerCase() ? r.$el_text = fr(1024, Oi(e)) : r.$el_text = fr(1024, mi(e)));
    const o = fi(e);
    o.length > 0 && (r.classes = o.filter(function (e) {
      return "" !== e;
    }));
    const a = null === (n = this.config) || void 0 === n ? void 0 : n.element_attribute_ignorelist;
    O(e.attributes, function (i) {
      var n;
      if ((!Fi(e) || -1 !== ["name", "id", "class", "aria-label"].indexOf(i.name)) && (null == a || !a.includes(i.name)) && !t && Li(i.value) && (n = i.name, !E(n) || "_ngcontent" !== n.substring(0, 10) && "_nghost" !== n.substring(0, 7))) {
        let e = i.value;
        "class" === i.name && (e = pi(e).join(" ")), r["attr__" + i.name] = fr(1024, e);
      }
    });
    let l = 1,
      c = 1,
      u = e;
    for (; u = this._previousElementSibling(u);) l++, u.tagName === e.tagName && c++;
    return r.nth_child = l, r.nth_of_type = c, r;
  }
  _getDefaultProperties(e) {
    return {
      $event_type: e,
      $ce_version: 1
    };
  }
  _captureEvent(t) {
    let i = arguments.length > 1 && void 0 !== arguments[1] ? arguments[1] : "$autocapture";
    if (!this.isEnabled) return;
    let n = yi(t);
    var s;
    (Si(n) && (n = n.parentNode || null), "$autocapture" === i && "click" === t.type && t instanceof MouseEvent) && this.instance.config.rageclick && null !== (s = this.rageclicks) && void 0 !== s && s.isRageClick(t.clientX, t.clientY, new Date().getTime()) && this._captureEvent(t, "$rageclick");
    const r = i === gr;
    if (n && Ii(n, t, this.config, r, r ? ["copy", "cut"] : void 0)) {
      var o, a;
      const s = [n];
      let r = n;
      for (; r.parentNode && !wi(r, "body");) Ei(r.parentNode) ? (s.push(r.parentNode.host), r = r.parentNode.host) : (s.push(r.parentNode), r = r.parentNode);
      const d = [],
        h = {};
      let _,
        p,
        g = !1;
      if (O(s, e => {
        const t = Pi(e);
        "a" === e.tagName.toLowerCase() && (_ = e.getAttribute("href"), _ = t && Li(_) && _);
        N(fi(e), "ph-no-capture") && (g = !0), d.push(this._getPropertiesFromElement(e, this.instance.config.mask_all_element_attributes, this.instance.config.mask_all_text));
        const i = this._getAugmentPropertiesFromElement(e);
        D(h, i);
      }), this.instance.config.mask_all_text || ("a" === n.tagName.toLowerCase() || "button" === n.tagName.toLowerCase() ? d[0].$el_text = Oi(n) : d[0].$el_text = mi(n)), _) {
        var l, c;
        d[0].attr__href = _;
        const t = null === (l = rt(_)) || void 0 === l ? void 0 : l.host,
          i = null == e || null === (c = e.location) || void 0 === c ? void 0 : c.host;
        t && i && t !== i && (p = _);
      }
      if (g) return !1;
      const f = D(this._getDefaultProperties(t.type), this._elementsChainAsString ? {
          $elements_chain: Ni(d)
        } : {
          $elements: d
        }, null !== (o = d[0]) && void 0 !== o && o.$el_text ? {
          $el_text: null === (a = d[0]) || void 0 === a ? void 0 : a.$el_text
        } : {}, p && "click" === t.type ? {
          $external_click_url: p
        } : {}, h),
        v = this.getElementSelectors(n);
      if (v && v.length > 0 && (f.$element_selectors = v), i === gr) {
        var u;
        const i = vi(null == e || null === (u = e.getSelection()) || void 0 === u ? void 0 : u.toString()),
          n = t.type || "clipboard";
        if (!i) return !1;
        f.$selected_content = i, f.$copy_type = n;
      }
      return this.instance.capture(i, f), !0;
    }
  }
  isBrowserSupported() {
    return y(null == o ? void 0 : o.querySelectorAll);
  }
}
class mr {
  _restoreXHRPatch = void 0;
  _restoreFetchPatch = void 0;
  constructor(e) {
    this.instance = e;
  }
  _loadScript(e) {
    var t, i, n;
    null !== (t = h.__PosthogExtensions__) && void 0 !== t && t.tracingHeadersPatchFns && e(), null === (i = h.__PosthogExtensions__) || void 0 === i || null === (n = i.loadExternalDependency) || void 0 === n || n.call(i, this.instance, "tracing-headers", t => {
      if (t) return $.error("[TRACING-HEADERS] failed to load script", t);
      e();
    });
  }
  startIfEnabledOrStop() {
    var e, t;
    this.instance.config.__add_tracing_headers ? this._loadScript(this._startCapturing) : (null === (e = this._restoreXHRPatch) || void 0 === e || e.call(this), null === (t = this._restoreFetchPatch) || void 0 === t || t.call(this), this._restoreXHRPatch = void 0, this._restoreFetchPatch = void 0);
  }
  _startCapturing = () => {
    var e, t, i, n;
    S(this._restoreXHRPatch) && (null === (e = h.__PosthogExtensions__) || void 0 === e || null === (t = e.tracingHeadersPatchFns) || void 0 === t || t._patchXHR(this.instance.sessionManager));
    S(this._restoreFetchPatch) && (null === (i = h.__PosthogExtensions__) || void 0 === i || null === (n = i.tracingHeadersPatchFns) || void 0 === n || n._patchFetch(this.instance.sessionManager));
  };
}
let yr;
!function (e) {
  e[e.PENDING = -1] = "PENDING", e[e.DENIED = 0] = "DENIED", e[e.GRANTED = 1] = "GRANTED";
}(yr || (yr = {}));
class br {
  constructor(e) {
    this.instance = e;
  }
  get config() {
    return this.instance.config;
  }
  get consent() {
    return this.getDnt() ? yr.DENIED : this.storedConsent;
  }
  isOptedOut() {
    return this.consent === yr.DENIED || this.consent === yr.PENDING && this.config.opt_out_capturing_by_default;
  }
  isOptedIn() {
    return !this.isOptedOut();
  }
  optInOut(e) {
    this.storage.set(this.storageKey, e ? 1 : 0, this.config.cookie_expiration, this.config.cross_subdomain_cookie, this.config.secure_cookie);
  }
  reset() {
    this.storage.remove(this.storageKey, this.config.cross_subdomain_cookie);
  }
  get storageKey() {
    const {
      token: e,
      opt_out_capturing_cookie_prefix: t
    } = this.instance.config;
    return (t || "__ph_opt_in_out_") + e;
  }
  get storedConsent() {
    const e = this.storage.get(this.storageKey);
    return "1" === e ? yr.GRANTED : "0" === e ? yr.DENIED : yr.PENDING;
  }
  get storage() {
    if (!this._storage) {
      const e = this.config.opt_out_capturing_persistence_type;
      this._storage = "localStorage" === e ? Xe : Je;
      const t = "localStorage" === e ? Je : Xe;
      t.get(this.storageKey) && (this._storage.get(this.storageKey) || this.optInOut("1" === t.get(this.storageKey)), t.remove(this.storageKey, this.config.cross_subdomain_cookie));
    }
    return this._storage;
  }
  getDnt() {
    return !!this.config.respect_dnt && !!Q([null == r ? void 0 : r.doNotTrack, null == r ? void 0 : r.msDoNotTrack, h.doNotTrack], e => N([!0, 1, "1", "yes"], e));
  }
}
const wr = "[Exception Autocapture]";
class Sr {
  originalOnUnhandledRejectionHandler = void 0;
  constructor(e) {
    var t;
    this.instance = e, this.remoteEnabled = !(null === (t = this.instance.persistence) || void 0 === t || !t.props[ie]), this.startIfEnabled();
  }
  get isEnabled() {
    var e;
    return null !== (e = this.remoteEnabled) && void 0 !== e && e;
  }
  get isCapturing() {
    var t;
    return !(null == e || null === (t = e.onerror) || void 0 === t || !t.__POSTHOG_INSTRUMENTED__);
  }
  get hasHandlers() {
    return this.originalOnUnhandledRejectionHandler || this.unwrapOnError;
  }
  startIfEnabled() {
    this.isEnabled && !this.isCapturing && ($.info(wr + " enabled, starting..."), this.loadScript(this.startCapturing));
  }
  loadScript(e) {
    var t, i;
    this.hasHandlers && e(), null === (t = h.__PosthogExtensions__) || void 0 === t || null === (i = t.loadExternalDependency) || void 0 === i || i.call(t, this.instance, "exception-autocapture", t => {
      if (t) return $.error(wr + " failed to load script", t);
      e();
    });
  }
  startCapturing = () => {
    var t, i, n, s;
    if (!e || !this.isEnabled || this.hasHandlers || this.isCapturing) return;
    const r = null === (t = h.__PosthogExtensions__) || void 0 === t || null === (i = t.errorWrappingFunctions) || void 0 === i ? void 0 : i.wrapOnError,
      o = null === (n = h.__PosthogExtensions__) || void 0 === n || null === (s = n.errorWrappingFunctions) || void 0 === s ? void 0 : s.wrapUnhandledRejection;
    if (r && o) try {
      this.unwrapOnError = r(this.captureException.bind(this)), this.unwrapUnhandledRejection = o(this.captureException.bind(this));
    } catch (e) {
      $.error(wr + " failed to start", e), this.stopCapturing();
    } else $.error(wr + " failed to load error wrapping functions - cannot start");
  };
  stopCapturing() {
    var e, t;
    null === (e = this.unwrapOnError) || void 0 === e || e.call(this), null === (t = this.unwrapUnhandledRejection) || void 0 === t || t.call(this);
  }
  afterDecideResponse(e) {
    const t = e.autocaptureExceptions;
    this.remoteEnabled = !!t || !1, this.instance.persistence && this.instance.persistence.register({
      [ie]: this.remoteEnabled
    }), this.startIfEnabled();
  }
  captureException(e) {
    const t = this.instance.requestRouter.endpointFor("ui");
    e.$exception_personURL = `${t}/project/${this.instance.config.token}/person/${this.instance.get_distinct_id()}`, this.instance.exceptions.sendExceptionEvent(e);
  }
}
const Er = 9e5,
  kr = "[Web Vitals]";
class xr {
  _enabledServerSide = !1;
  _initialized = !1;
  buffer = {
    url: void 0,
    metrics: [],
    firstMetricTimestamp: void 0
  };
  constructor(e) {
    var t;
    this.instance = e, this._enabledServerSide = !(null === (t = this.instance.persistence) || void 0 === t || !t.props[se]), this.startIfEnabled();
  }
  get allowedMetrics() {
    var e, t;
    const i = b(this.instance.config.capture_performance) ? null === (e = this.instance.config.capture_performance) || void 0 === e ? void 0 : e.web_vitals_allowed_metrics : void 0;
    return S(i) ? (null === (t = this.instance.persistence) || void 0 === t ? void 0 : t.props[re]) || ["CLS", "FCP", "INP", "LCP"] : i;
  }
  get flushToCaptureTimeoutMs() {
    return (b(this.instance.config.capture_performance) ? this.instance.config.capture_performance.web_vitals_delayed_flush_ms : void 0) || 5e3;
  }
  get _maxAllowedValue() {
    const e = b(this.instance.config.capture_performance) && P(this.instance.config.capture_performance.__web_vitals_max_value) ? this.instance.config.capture_performance.__web_vitals_max_value : Er;
    return 0 < e && e <= 6e4 ? Er : e;
  }
  get isEnabled() {
    const e = b(this.instance.config.capture_performance) ? this.instance.config.capture_performance.web_vitals : void 0;
    return F(e) ? e : this._enabledServerSide;
  }
  startIfEnabled() {
    this.isEnabled && !this._initialized && ($.info(kr + " enabled, starting..."), this.loadScript(this._startCapturing));
  }
  afterDecideResponse(e) {
    const t = b(e.capturePerformance) && !!e.capturePerformance.web_vitals,
      i = b(e.capturePerformance) ? e.capturePerformance.web_vitals_allowed_metrics : void 0;
    this.instance.persistence && (this.instance.persistence.register({
      [se]: t
    }), this.instance.persistence.register({
      [re]: i
    })), this._enabledServerSide = t, this.startIfEnabled();
  }
  loadScript(e) {
    var t, i, n;
    null !== (t = h.__PosthogExtensions__) && void 0 !== t && t.postHogWebVitalsCallbacks && e(), null === (i = h.__PosthogExtensions__) || void 0 === i || null === (n = i.loadExternalDependency) || void 0 === n || n.call(i, this.instance, "web-vitals", t => {
      t ? $.error(kr + " failed to load script", t) : e();
    });
  }
  _currentURL() {
    const t = e ? e.location.href : void 0;
    return t || $.error(kr + "Could not determine current URL"), t;
  }
  _flushToCapture = () => {
    clearTimeout(this._delayedFlushTimer), 0 !== this.buffer.metrics.length && (this.instance.capture("$web_vitals", this.buffer.metrics.reduce((e, t) => ({
      ...e,
      [`$web_vitals_${t.name}_event`]: {
        ...t
      },
      [`$web_vitals_${t.name}_value`]: t.value
    }), {})), this.buffer = {
      url: void 0,
      metrics: [],
      firstMetricTimestamp: void 0
    });
  };
  _addToBuffer = e => {
    var t;
    const i = null === (t = this.instance.sessionManager) || void 0 === t ? void 0 : t.checkAndGetSessionAndWindowId(!0);
    if (S(i)) return void $.error(kr + "Could not read session ID. Dropping metrics!");
    this.buffer = this.buffer || {
      url: void 0,
      metrics: [],
      firstMetricTimestamp: void 0
    };
    const n = this._currentURL();
    if (S(n)) return;
    if (I(null == e ? void 0 : e.name) || I(null == e ? void 0 : e.value)) return void $.error(kr + "Invalid metric received", e);
    if (this._maxAllowedValue && e.value >= this._maxAllowedValue) return void $.error(kr + "Ignoring metric with value >= " + this._maxAllowedValue, e);
    this.buffer.url !== n && (this._flushToCapture(), this._delayedFlushTimer = setTimeout(this._flushToCapture, this.flushToCaptureTimeoutMs)), S(this.buffer.url) && (this.buffer.url = n), this.buffer.firstMetricTimestamp = S(this.buffer.firstMetricTimestamp) ? Date.now() : this.buffer.firstMetricTimestamp, e.attribution && e.attribution.interactionTargetElement && (e.attribution.interactionTargetElement = void 0), this.buffer.metrics.push({
      ...e,
      $current_url: n,
      $session_id: i.sessionId,
      $window_id: i.windowId,
      timestamp: Date.now()
    }), this.buffer.metrics.length === this.allowedMetrics.length && this._flushToCapture();
  };
  _startCapturing = () => {
    let e, t, i, n;
    const s = h.__PosthogExtensions__;
    S(s) || S(s.postHogWebVitalsCallbacks) || ({
      onLCP: e,
      onCLS: t,
      onFCP: i,
      onINP: n
    } = s.postHogWebVitalsCallbacks), e && t && i && n ? (this.allowedMetrics.indexOf("LCP") > -1 && e(this._addToBuffer.bind(this)), this.allowedMetrics.indexOf("CLS") > -1 && t(this._addToBuffer.bind(this)), this.allowedMetrics.indexOf("FCP") > -1 && i(this._addToBuffer.bind(this)), this.allowedMetrics.indexOf("INP") > -1 && n(this._addToBuffer.bind(this)), this._initialized = !0) : $.error(kr + "web vitals callbacks not loaded - not starting");
  };
}
const Ir = {
  icontains: (t, i) => !!e && i.href.toLowerCase().indexOf(t.toLowerCase()) > -1,
  not_icontains: (t, i) => !!e && -1 === i.href.toLowerCase().indexOf(t.toLowerCase()),
  regex: (t, i) => !!e && ot(i.href, t),
  not_regex: (t, i) => !!e && !ot(i.href, t),
  exact: (e, t) => t.href === e,
  is_not: (e, t) => t.href !== e
};
class Pr {
  constructor(e) {
    this.instance = e;
    const t = e => {
      this.applyFeatureFlagChanges(e);
    };
    this.instance.onFeatureFlags && this.instance.onFeatureFlags(t), this._flagToExperiments = new Map();
  }
  applyFeatureFlagChanges(e) {
    I(this._flagToExperiments) || this.instance.config.disable_web_experiments || (Pr.logInfo("applying feature flags", e), e.forEach(e => {
      var t;
      if (this._flagToExperiments && null !== (t = this._flagToExperiments) && void 0 !== t && t.has(e)) {
        var i;
        const t = this.instance.getFeatureFlag(e),
          n = null === (i = this._flagToExperiments) || void 0 === i ? void 0 : i.get(e);
        t && null != n && n.variants[t] && this.applyTransforms(n.name, t, n.variants[t].transforms);
      }
    }));
  }
  afterDecideResponse(e) {
    this._is_bot() ? Pr.logInfo("Refusing to render web experiment since the viewer is a likely bot") : (this._featureFlags = e.featureFlags, this.loadIfEnabled(), this.previewWebExperiment());
  }
  previewWebExperiment() {
    const e = Pr.getWindowLocation();
    if (null != e && e.search) {
      const t = lt(null == e ? void 0 : e.search, "__experiment_id"),
        i = lt(null == e ? void 0 : e.search, "__experiment_variant");
      t && i && (Pr.logInfo(`previewing web experiments ${t} && ${i}`), this.getWebExperiments(e => {
        this.showPreviewWebExperiment(parseInt(t), i, e);
      }, !1, !0));
    }
  }
  loadIfEnabled() {
    this.instance.config.disable_web_experiments || this.getWebExperimentsAndEvaluateDisplayLogic();
  }
  getWebExperimentsAndEvaluateDisplayLogic = (() => {
    var e = this;
    return function () {
      let t = arguments.length > 0 && void 0 !== arguments[0] && arguments[0];
      e.getWebExperiments(t => {
        Pr.logInfo("retrieved web experiments from the server"), e._flagToExperiments = new Map(), t.forEach(t => {
          if (t.feature_flag_key && e._featureFlags && e._featureFlags[t.feature_flag_key]) {
            var i;
            if (e._flagToExperiments) Pr.logInfo("setting flag key ", t.feature_flag_key, " to web experiment ", t), null === (i = e._flagToExperiments) || void 0 === i || i.set(t.feature_flag_key, t);
            const n = e._featureFlags[t.feature_flag_key];
            n && t.variants[n] && e.applyTransforms(t.name, n, t.variants[n].transforms);
          } else if (t.variants) for (const i in t.variants) {
            const n = t.variants[i];
            Pr.matchesTestVariant(n) && e.applyTransforms(t.name, i, n.transforms);
          }
        });
      }, t);
    };
  })();
  getWebExperiments(e, t, i) {
    if (this.instance.config.disable_web_experiments && !i) return e([]);
    const n = this.instance.get_property("$web_experiments");
    if (n && !t) return e(n);
    this.instance._send_request({
      url: this.instance.requestRouter.endpointFor("api", `/api/web_experiments/?token=${this.instance.config.token}`),
      method: "GET",
      transport: "XHR",
      callback: t => {
        if (200 !== t.statusCode || !t.json) return e([]);
        const i = t.json.experiments || [];
        return e(i);
      }
    });
  }
  showPreviewWebExperiment(e, t, i) {
    const n = i.filter(t => t.id === e);
    n && n.length > 0 && (Pr.logInfo(`Previewing web experiment [${n[0].name}] with variant [${t}]`), this.applyTransforms(n[0].name, t, n[0].variants[t].transforms, !0));
  }
  static matchesTestVariant(e) {
    return !I(e.conditions) && Pr.matchUrlConditions(e) && Pr.matchUTMConditions(e);
  }
  static matchUrlConditions(e) {
    var t;
    if (I(e.conditions) || I(null === (t = e.conditions) || void 0 === t ? void 0 : t.url)) return !0;
    const i = Pr.getWindowLocation();
    if (i) {
      var n, s, r;
      return null === (n = e.conditions) || void 0 === n || !n.url || Ir[null !== (s = null === (r = e.conditions) || void 0 === r ? void 0 : r.urlMatchType) && void 0 !== s ? s : "icontains"](e.conditions.url, i);
    }
    return !1;
  }
  static getWindowLocation() {
    return null == e ? void 0 : e.location;
  }
  static matchUTMConditions(e) {
    var t;
    if (I(e.conditions) || I(null === (t = e.conditions) || void 0 === t ? void 0 : t.utm)) return !0;
    const i = ai.campaignParams();
    if (i.utm_source) {
      var n, s, r, o, a, l, c, u, d, h, _, p, g, f, v, m;
      const t = null === (n = e.conditions) || void 0 === n || null === (s = n.utm) || void 0 === s || !s.utm_campaign || (null === (r = e.conditions) || void 0 === r || null === (o = r.utm) || void 0 === o ? void 0 : o.utm_campaign) == i.utm_campaign,
        y = null === (a = e.conditions) || void 0 === a || null === (l = a.utm) || void 0 === l || !l.utm_source || (null === (c = e.conditions) || void 0 === c || null === (u = c.utm) || void 0 === u ? void 0 : u.utm_source) == i.utm_source,
        b = null === (d = e.conditions) || void 0 === d || null === (h = d.utm) || void 0 === h || !h.utm_medium || (null === (_ = e.conditions) || void 0 === _ || null === (p = _.utm) || void 0 === p ? void 0 : p.utm_medium) == i.utm_medium,
        w = null === (g = e.conditions) || void 0 === g || null === (f = g.utm) || void 0 === f || !f.utm_term || (null === (v = e.conditions) || void 0 === v || null === (m = v.utm) || void 0 === m ? void 0 : m.utm_term) == i.utm_term;
      return t && b && w && y;
    }
    return !1;
  }
  static logInfo(e) {
    for (var t = arguments.length, i = new Array(t > 1 ? t - 1 : 0), n = 1; n < t; n++) i[n - 1] = arguments[n];
    $.info(`[WebExperiments] ${e}`, i);
  }
  applyTransforms(e, t, i, n) {
    var s;
    this._is_bot() ? Pr.logInfo("Refusing to render web experiment since the viewer is a likely bot") : "control" !== t ? i.forEach(i => {
      if (i.selector) {
        var s;
        Pr.logInfo(`applying transform of variant ${t} for experiment ${e} `, i);
        let o = 0;
        const a = null === (s = document) || void 0 === s ? void 0 : s.querySelectorAll(i.selector);
        var r;
        if (null == a || a.forEach(e => {
          const t = e;
          o += 1, i.attributes && i.attributes.forEach(e => {
            switch (e.name) {
              case "text":
                t.innerText = e.value;
                break;
              case "html":
                t.innerHTML = e.value;
                break;
              case "cssClass":
                t.className = e.value;
                break;
              default:
                t.setAttribute(e.name, e.value);
            }
          }), i.text && (t.innerText = i.text), i.html && (t.parentElement ? t.parentElement.innerHTML = i.html : t.innerHTML = i.html), i.css && t.setAttribute("style", i.css);
        }), this.instance && this.instance.capture) this.instance.capture("$web_experiment_applied", {
          $web_experiment_name: e,
          $web_experiment_variant: t,
          $web_experiment_preview: n,
          $web_experiment_document_url: null === (r = Pr.getWindowLocation()) || void 0 === r ? void 0 : r.href,
          $web_experiment_elements_modified: o
        });
      }
    }) : (Pr.logInfo("Control variants leave the page unmodified."), this.instance && this.instance.capture && this.instance.capture("$web_experiment_applied", {
      $web_experiment_name: e,
      $web_experiment_preview: n,
      $web_experiment_variant: t,
      $web_experiment_document_url: null === (s = Pr.getWindowLocation()) || void 0 === s ? void 0 : s.href,
      $web_experiment_elements_modified: 0
    }));
  }
  _is_bot() {
    return r && this.instance ? ur(r, this.instance.config.custom_blocked_useragents) : void 0;
  }
}
const Fr = "/e/";
class Rr {
  constructor(e) {
    var t;
    this.instance = e, this._endpointSuffix = (null === (t = this.instance.persistence) || void 0 === t ? void 0 : t.props[ne]) || Fr;
  }
  get endpoint() {
    return this.instance.requestRouter.endpointFor("api", this._endpointSuffix);
  }
  afterDecideResponse(e) {
    const t = e.autocaptureExceptions;
    this._endpointSuffix = b(t) && t.endpoint || Fr, this.instance.persistence && this.instance.persistence.register({
      [ne]: this._endpointSuffix
    });
  }
  sendExceptionEvent(e) {
    this.instance.capture("$exception", e, {
      _noTruncate: !0,
      _batchKey: "exceptionEvent",
      _url: this.endpoint
    });
  }
}
const Tr = {},
  $r = () => {},
  Cr = "posthog";
let Mr = !Xn && -1 === (null == d ? void 0 : d.indexOf("MSIE")) && -1 === (null == d ? void 0 : d.indexOf("Mozilla"));
const Ar = () => {
    var t;
    return {
      api_host: "https://us.i.posthog.com",
      ui_host: null,
      token: "",
      autocapture: !0,
      rageclick: !0,
      cross_subdomain_cookie: j(null == o ? void 0 : o.location),
      persistence: "localStorage+cookie",
      persistence_name: "",
      loaded: $r,
      store_google: !0,
      custom_campaign_params: [],
      custom_blocked_useragents: [],
      save_referrer: !0,
      capture_pageview: !0,
      capture_pageleave: "if_capture_pageview",
      debug: a && E(null == a ? void 0 : a.search) && -1 !== a.search.indexOf("__posthog_debug=true") || !1,
      verbose: !1,
      cookie_expiration: 365,
      upgrade: !1,
      disable_session_recording: !1,
      disable_persistence: !1,
      disable_web_experiments: !0,
      disable_surveys: !1,
      enable_recording_console_log: void 0,
      secure_cookie: "https:" === (null == e || null === (t = e.location) || void 0 === t ? void 0 : t.protocol),
      ip: !0,
      opt_out_capturing_by_default: !1,
      opt_out_persistence_by_default: !1,
      opt_out_useragent_filter: !1,
      opt_out_capturing_persistence_type: "localStorage",
      opt_out_capturing_cookie_prefix: null,
      opt_in_site_apps: !1,
      property_denylist: [],
      respect_dnt: !1,
      sanitize_properties: null,
      request_headers: {},
      inapp_protocol: "//",
      inapp_link_new_window: !1,
      request_batching: !0,
      properties_string_max_length: 65535,
      session_recording: {},
      mask_all_element_attributes: !1,
      mask_all_text: !1,
      advanced_disable_decide: !1,
      advanced_disable_feature_flags: !1,
      advanced_disable_feature_flags_on_first_load: !1,
      advanced_disable_toolbar_metrics: !1,
      feature_flag_request_timeout_ms: 3e3,
      on_request_error: e => {
        const t = "Bad HTTP status: " + e.statusCode + " " + e.text;
        $.error(t);
      },
      get_device_id: e => e,
      _onCapture: $r,
      capture_performance: void 0,
      name: "posthog",
      bootstrap: {},
      disable_compression: !1,
      session_idle_timeout_seconds: 1800,
      person_profiles: "identified_only",
      __add_tracing_headers: !1
    };
  },
  Lr = e => {
    const t = {};
    S(e.process_person) || (t.person_profiles = e.process_person), S(e.xhr_headers) || (t.request_headers = e.xhr_headers), S(e.cookie_name) || (t.persistence_name = e.cookie_name), S(e.disable_cookie) || (t.disable_persistence = e.disable_cookie);
    const i = D({}, t, e);
    return m(e.property_blacklist) && (S(e.property_denylist) ? i.property_denylist = e.property_blacklist : m(e.property_denylist) ? i.property_denylist = [...e.property_blacklist, ...e.property_denylist] : $.error("Invalid value for property_denylist config: " + e.property_denylist)), i;
  };
class Or {
  get _forceAllowLocalhost() {
    return this.__forceAllowLocalhost;
  }
  set _forceAllowLocalhost(e) {
    $.error("WebPerformanceObserver is deprecated and has no impact on network capture. Use `_forceAllowLocalhostNetworkCapture` on `posthog.sessionRecording`"), this.__forceAllowLocalhost = e;
  }
  __forceAllowLocalhost = !1;
}
class Dr {
  webPerformance = new Or();
  version = _.LIB_VERSION;
  _internalEventEmitter = new ms();
  constructor() {
    this.config = Ar(), this.decideEndpointWasHit = !1, this.SentryIntegration = hs, this.sentryIntegration = e => function (e, t) {
      const i = ds(e, t);
      return {
        name: us,
        processEvent: e => i(e)
      };
    }(this, e), this.__request_queue = [], this.__loaded = !1, this.analyticsDefaultEndpoint = "/e/", this._initialPageviewCaptured = !1, this._initialPersonProfilesConfig = null, this.featureFlags = new Oe(this), this.toolbar = new Jn(this), this.scrollManager = new pr(this), this.pageViewManager = new ps(this), this.surveys = new sr(this), this.experiments = new Pr(this), this.exceptions = new Rr(this), this.rateLimiter = new rr(this), this.requestRouter = new cs(this), this.consent = new br(this), this.people = {
      set: (e, t, i) => {
        const n = E(e) ? {
          [e]: t
        } : e;
        this.setPersonProperties(n), null == i || i({});
      },
      set_once: (e, t, i) => {
        const n = E(e) ? {
          [e]: t
        } : e;
        this.setPersonProperties(void 0, n), null == i || i({});
      }
    }, this.on("eventCaptured", e => $.info(`send "${null == e ? void 0 : e.event}"`, e));
  }
  init(e, t, i) {
    if (i && i !== Cr) {
      var n;
      const s = null !== (n = Tr[i]) && void 0 !== n ? n : new Dr();
      return s._init(e, t, i), Tr[i] = s, Tr[Cr][i] = s, s;
    }
    return this._init(e, t, i);
  }
  _init(t) {
    var i, n;
    let s = arguments.length > 1 && void 0 !== arguments[1] ? arguments[1] : {},
      r = arguments.length > 2 ? arguments[2] : void 0;
    if (S(t) || k(t)) return $.critical("PostHog was initialized without a token. This likely indicates a misconfiguration. Please check the first argument passed to posthog.init()"), this;
    if (this.__loaded) return $.warn("You have already initialized PostHog! Re-initializing is a no-op"), this;
    this.__loaded = !0, this.config = {}, this._triggered_notifs = [], s.person_profiles && (this._initialPersonProfilesConfig = s.person_profiles), this.set_config(D({}, Ar(), Lr(s), {
      name: r,
      token: t
    })), this.config.on_xhr_error && $.error("[posthog] on_xhr_error is deprecated. Use on_request_error instead"), this.compression = s.disable_compression ? void 0 : J.GZipJS, this.persistence = new ci(this.config), this.sessionPersistence = "sessionStorage" === this.config.persistence ? this.persistence : new ci({
      ...this.config,
      persistence: "sessionStorage"
    });
    const o = {
        ...this.persistence.props
      },
      a = {
        ...this.sessionPersistence.props
      };
    if (this._requestQueue = new Yn(e => this._send_retriable_request(e)), this._retryQueue = new rs(this), this.__request_queue = [], this.sessionManager = new os(this.config, this.persistence), this.sessionPropsManager = new ar(this.sessionManager, this.persistence), new mr(this).startIfEnabledOrStop(), this.sessionRecording = new zn(this), this.sessionRecording.startIfEnabledOrStop(), this.config.disable_scroll_properties || this.scrollManager.startMeasuringScrollPosition(), this.autocapture = new vr(this), this.autocapture.startIfEnabled(), this.surveys.loadIfEnabled(), this.heatmaps = new _r(this), this.heatmaps.startIfEnabled(), this.webVitalsAutocapture = new xr(this), this.exceptionObserver = new Sr(this), this.exceptionObserver.startIfEnabled(), _.DEBUG = _.DEBUG || this.config.debug, _.DEBUG && $.info("Starting in debug mode", {
      this: this,
      config: s,
      thisC: {
        ...this.config
      },
      p: o,
      s: a
    }), this._sync_opt_out_with_persistence(), void 0 !== (null === (i = s.bootstrap) || void 0 === i ? void 0 : i.distinctID)) {
      var l, c;
      const e = this.config.get_device_id(We()),
        t = null !== (l = s.bootstrap) && void 0 !== l && l.isIdentifiedID ? e : s.bootstrap.distinctID;
      this.persistence.set_property(Ee, null !== (c = s.bootstrap) && void 0 !== c && c.isIdentifiedID ? "identified" : "anonymous"), this.register({
        distinct_id: s.bootstrap.distinctID,
        $device_id: t
      });
    }
    if (this._hasBootstrappedFeatureFlags()) {
      var u, d;
      const e = Object.keys((null === (u = s.bootstrap) || void 0 === u ? void 0 : u.featureFlags) || {}).filter(e => {
          var t, i;
          return !(null === (t = s.bootstrap) || void 0 === t || null === (i = t.featureFlags) || void 0 === i || !i[e]);
        }).reduce((e, t) => {
          var i, n;
          return e[t] = (null === (i = s.bootstrap) || void 0 === i || null === (n = i.featureFlags) || void 0 === n ? void 0 : n[t]) || !1, e;
        }, {}),
        t = Object.keys((null === (d = s.bootstrap) || void 0 === d ? void 0 : d.featureFlagPayloads) || {}).filter(t => e[t]).reduce((e, t) => {
          var i, n, r, o;
          null !== (i = s.bootstrap) && void 0 !== i && null !== (n = i.featureFlagPayloads) && void 0 !== n && n[t] && (e[t] = null === (r = s.bootstrap) || void 0 === r || null === (o = r.featureFlagPayloads) || void 0 === o ? void 0 : o[t]);
          return e;
        }, {});
      this.featureFlags.receivedFeatureFlags({
        featureFlags: e,
        featureFlagPayloads: t
      });
    }
    if (!this.get_distinct_id()) {
      const e = this.config.get_device_id(We());
      this.register_once({
        distinct_id: e,
        $device_id: e
      }, ""), this.persistence.set_property(Ee, "anonymous");
    }
    return null == e || null === (n = e.addEventListener) || void 0 === n || n.call(e, "onpagehide" in self ? "pagehide" : "unload", this._handle_unload.bind(this)), this.toolbar.maybeLoadToolbar(), s.segment ? _s(this, () => this._loaded()) : this._loaded(), y(this.config._onCapture) && this.on("eventCaptured", e => this.config._onCapture(e.event, e)), this;
  }
  _afterDecideResponse(e) {
    var t, i, n, s, r, o, a, l, c;
    this.compression = void 0, e.supportedCompression && !this.config.disable_compression && (this.compression = N(e.supportedCompression, J.GZipJS) ? J.GZipJS : N(e.supportedCompression, J.Base64) ? J.Base64 : void 0), null !== (t = e.analytics) && void 0 !== t && t.endpoint && (this.analyticsDefaultEndpoint = e.analytics.endpoint), this.set_config({
      person_profiles: this._initialPersonProfilesConfig ? this._initialPersonProfilesConfig : e.defaultIdentifiedOnly ? "identified_only" : "always"
    }), null === (i = this.sessionRecording) || void 0 === i || i.afterDecideResponse(e), null === (n = this.autocapture) || void 0 === n || n.afterDecideResponse(e), null === (s = this.heatmaps) || void 0 === s || s.afterDecideResponse(e), null === (r = this.experiments) || void 0 === r || r.afterDecideResponse(e), null === (o = this.surveys) || void 0 === o || o.afterDecideResponse(e), null === (a = this.webVitalsAutocapture) || void 0 === a || a.afterDecideResponse(e), null === (l = this.exceptions) || void 0 === l || l.afterDecideResponse(e), null === (c = this.exceptionObserver) || void 0 === c || c.afterDecideResponse(e);
  }
  _loaded() {
    const e = this.config.advanced_disable_decide;
    e || this.featureFlags.setReloadingPaused(!0);
    try {
      this.config.loaded(this);
    } catch (e) {
      $.critical("`loaded` function failed", e);
    }
    this._start_queue_if_opted_in(), this.config.capture_pageview && setTimeout(() => {
      this.consent.isOptedIn() && this._captureInitialPageview();
    }, 1), e || (new Vn(this).call(), this.featureFlags.resetRequestQueue());
  }
  _start_queue_if_opted_in() {
    var e;
    this.has_opted_out_capturing() || this.config.request_batching && (null === (e = this._requestQueue) || void 0 === e || e.enable());
  }
  _dom_loaded() {
    this.has_opted_out_capturing() || L(this.__request_queue, e => this._send_retriable_request(e)), this.__request_queue = [], this._start_queue_if_opted_in();
  }
  _handle_unload() {
    var e, t;
    this.config.request_batching ? (this._shouldCapturePageleave() && this.capture("$pageleave"), null === (e = this._requestQueue) || void 0 === e || e.unload(), null === (t = this._retryQueue) || void 0 === t || t.unload()) : this._shouldCapturePageleave() && this.capture("$pageleave", null, {
      transport: "sendBeacon"
    });
  }
  _send_request(e) {
    this.__loaded && (Mr ? this.__request_queue.push(e) : this.rateLimiter.isServerRateLimited(e.batchKey) || (e.transport = e.transport || this.config.api_transport, e.url = Zn(e.url, {
      ip: this.config.ip ? 1 : 0
    }), e.headers = {
      ...this.config.request_headers
    }, e.compression = "best-available" === e.compression ? this.compression : e.compression, (e => {
      var t, i, n;
      const s = {
        ...e
      };
      s.timeout = s.timeout || 6e4, s.url = Zn(s.url, {
        _: new Date().getTime().toString(),
        ver: _.LIB_VERSION,
        compression: s.compression
      });
      const r = null !== (t = s.transport) && void 0 !== t ? t : "XHR",
        o = null !== (i = null === (n = Q(ss, e => e.transport === r)) || void 0 === n ? void 0 : n.method) && void 0 !== i ? i : ss[0].method;
      if (!o) throw new Error("No available transport method");
      o(s);
    })({
      ...e,
      callback: t => {
        var i, n, s;
        (this.rateLimiter.checkForLimiting(t), t.statusCode >= 400) && (null === (n = (s = this.config).on_request_error) || void 0 === n || n.call(s, t));
        null === (i = e.callback) || void 0 === i || i.call(e, t);
      }
    })));
  }
  _send_retriable_request(e) {
    this._retryQueue ? this._retryQueue.retriableRequest(e) : this._send_request(e);
  }
  _execute_array(e) {
    let t;
    const i = [],
      n = [],
      s = [];
    L(e, e => {
      e && (t = e[0], m(t) ? s.push(e) : y(e) ? e.call(this) : m(e) && "alias" === t ? i.push(e) : m(e) && -1 !== t.indexOf("capture") && y(this[t]) ? s.push(e) : n.push(e));
    });
    const r = function (e, t) {
      L(e, function (e) {
        if (m(e[0])) {
          let i = t;
          O(e, function (e) {
            i = i[e[0]].apply(i, e.slice(1));
          });
        } else this[e[0]].apply(this, e.slice(1));
      }, t);
    };
    r(i, this), r(n, this), r(s, this);
  }
  _hasBootstrappedFeatureFlags() {
    var e, t;
    return (null === (e = this.config.bootstrap) || void 0 === e ? void 0 : e.featureFlags) && Object.keys(null === (t = this.config.bootstrap) || void 0 === t ? void 0 : t.featureFlags).length > 0 || !1;
  }
  push(e) {
    this._execute_array([e]);
  }
  capture(e, t, i) {
    var n;
    if (!(this.__loaded && this.persistence && this.sessionPersistence && this._requestQueue)) return void $.uninitializedWarning("posthog.capture");
    if (this.consent.isOptedOut()) return;
    if (S(e) || !E(e)) return void $.error("No event name provided to posthog.capture");
    if (!this.config.opt_out_useragent_filter && this._is_bot()) return;
    const s = null != i && i.skip_client_rate_limiting ? void 0 : this.rateLimiter.clientRateLimitContext();
    if (null != s && s.isRateLimited) return void $.critical("This capture call is ignored due to client rate limiting.");
    this.sessionPersistence.update_search_keyword(), this.config.store_google && this.sessionPersistence.update_campaign_params(), this.config.save_referrer && this.sessionPersistence.update_referrer_info(), (this.config.store_google || this.config.save_referrer) && this.persistence.set_initial_person_info();
    const r = new Date(),
      o = (null == i ? void 0 : i.timestamp) || r;
    let a = {
      uuid: We(),
      event: e,
      properties: this._calculate_event_properties(e, t || {}, o)
    };
    s && (a.properties.$lib_rate_limit_remaining_tokens = s.remainingTokens);
    (null == i ? void 0 : i.$set) && (a.$set = null == i ? void 0 : i.$set);
    const l = this._calculate_set_once_properties(null == i ? void 0 : i.$set_once);
    l && (a.$set_once = l), a = z(a, null != i && i._noTruncate ? null : this.config.properties_string_max_length), a.timestamp = o, S(null == i ? void 0 : i.timestamp) || (a.properties.$event_time_override_provided = !0, a.properties.$event_time_override_system_time = r);
    const c = {
      ...a.properties.$set,
      ...a.$set
    };
    w(c) || this.setPersonPropertiesForFlags(c), this._internalEventEmitter.emit("eventCaptured", a);
    const u = {
      method: "POST",
      url: null !== (n = null == i ? void 0 : i._url) && void 0 !== n ? n : this.requestRouter.endpointFor("api", this.analyticsDefaultEndpoint),
      data: a,
      compression: "best-available",
      batchKey: null == i ? void 0 : i._batchKey
    };
    return !this.config.request_batching || i && (null == i || !i._batchKey) || null != i && i.send_instantly ? this._send_retriable_request(u) : this._requestQueue.enqueue(u), a;
  }
  _addCaptureHook(e) {
    return this.on("eventCaptured", t => e(t.event, t));
  }
  _calculate_event_properties(e, t, i) {
    if (i = i || new Date(), !this.persistence || !this.sessionPersistence) return t;
    const n = this.persistence.remove_event_timer(e);
    let s = {
      ...t
    };
    if (s.token = this.config.token, "$snapshot" === e) {
      const e = {
        ...this.persistence.properties(),
        ...this.sessionPersistence.properties()
      };
      return s.distinct_id = e.distinct_id, (!E(s.distinct_id) && !P(s.distinct_id) || k(s.distinct_id)) && $.error("Invalid distinct_id for replay event. This indicates a bug in your implementation"), s;
    }
    const r = ai.properties();
    if (this.sessionManager) {
      const {
        sessionId: e,
        windowId: t
      } = this.sessionManager.checkAndGetSessionAndWindowId();
      s.$session_id = e, s.$window_id = t;
    }
    if (this.requestRouter.region === as.CUSTOM && (s.$lib_custom_api_host = this.config.api_host), this.sessionPropsManager && this.config.__preview_send_client_session_params && ("$pageview" === e || "$pageleave" === e || "$autocapture" === e)) {
      const e = this.sessionPropsManager.getSessionProps();
      s = D(s, e);
    }
    if (!this.config.disable_scroll_properties) {
      let t = {};
      "$pageview" === e ? t = this.pageViewManager.doPageView(i) : "$pageleave" === e && (t = this.pageViewManager.doPageLeave(i)), s = D(s, t);
    }
    if ("$pageview" === e && o && (s.title = o.title), !S(n)) {
      const e = i.getTime() - n;
      s.$duration = parseFloat((e / 1e3).toFixed(3));
    }
    d && this.config.opt_out_useragent_filter && (s.$browser_type = this._is_bot() ? "bot" : "browser"), s = D({}, r, this.persistence.properties(), this.sessionPersistence.properties(), s), s.$is_identified = this._isIdentified(), m(this.config.property_denylist) ? O(this.config.property_denylist, function (e) {
      delete s[e];
    }) : $.error("Invalid value for property_denylist config: " + this.config.property_denylist + " or property_blacklist config: " + this.config.property_blacklist);
    const a = this.config.sanitize_properties;
    a && (s = a(s, e));
    const l = this._hasPersonProcessing();
    return s.$process_person_profile = l, l && this._requirePersonProcessing("_calculate_event_properties"), s;
  }
  _calculate_set_once_properties(e) {
    if (!this.persistence || !this._hasPersonProcessing()) return e;
    let t = D({}, this.persistence.get_initial_props(), e || {});
    const i = this.config.sanitize_properties;
    return i && (t = i(t, "$set_once")), w(t) ? void 0 : t;
  }
  register(e, t) {
    var i;
    null === (i = this.persistence) || void 0 === i || i.register(e, t);
  }
  register_once(e, t, i) {
    var n;
    null === (n = this.persistence) || void 0 === n || n.register_once(e, t, i);
  }
  register_for_session(e) {
    var t;
    null === (t = this.sessionPersistence) || void 0 === t || t.register(e);
  }
  unregister(e) {
    var t;
    null === (t = this.persistence) || void 0 === t || t.unregister(e);
  }
  unregister_for_session(e) {
    var t;
    null === (t = this.sessionPersistence) || void 0 === t || t.unregister(e);
  }
  _register_single(e, t) {
    this.register({
      [e]: t
    });
  }
  getFeatureFlag(e, t) {
    return this.featureFlags.getFeatureFlag(e, t);
  }
  getFeatureFlagPayload(e) {
    const t = this.featureFlags.getFeatureFlagPayload(e);
    try {
      return JSON.parse(t);
    } catch {
      return t;
    }
  }
  isFeatureEnabled(e, t) {
    return this.featureFlags.isFeatureEnabled(e, t);
  }
  reloadFeatureFlags() {
    this.featureFlags.reloadFeatureFlags();
  }
  updateEarlyAccessFeatureEnrollment(e, t) {
    this.featureFlags.updateEarlyAccessFeatureEnrollment(e, t);
  }
  getEarlyAccessFeatures(e) {
    let t = arguments.length > 1 && void 0 !== arguments[1] && arguments[1];
    return this.featureFlags.getEarlyAccessFeatures(e, t);
  }
  on(e, t) {
    return this._internalEventEmitter.on(e, t);
  }
  onFeatureFlags(e) {
    return this.featureFlags.onFeatureFlags(e);
  }
  onSessionId(e) {
    var t, i;
    return null !== (t = null === (i = this.sessionManager) || void 0 === i ? void 0 : i.onSessionId(e)) && void 0 !== t ? t : () => {};
  }
  getSurveys(e) {
    let t = arguments.length > 1 && void 0 !== arguments[1] && arguments[1];
    this.surveys.getSurveys(e, t);
  }
  getActiveMatchingSurveys(e) {
    let t = arguments.length > 1 && void 0 !== arguments[1] && arguments[1];
    this.surveys.getActiveMatchingSurveys(e, t);
  }
  renderSurvey(e, t) {
    this.surveys.renderSurvey(e, t);
  }
  canRenderSurvey(e) {
    this.surveys.canRenderSurvey(e);
  }
  getNextSurveyStep(e, t, i) {
    return this.surveys.getNextSurveyStep(e, t, i);
  }
  identify(e, t, i) {
    if (!this.__loaded || !this.persistence) return $.uninitializedWarning("posthog.identify");
    if (P(e) && (e = e.toString(), $.warn("The first argument to posthog.identify was a number, but it should be a string. It has been converted to a string.")), !e) return void $.error("Unique user id has not been set in posthog.identify");
    if (["distinct_id", "distinctid"].includes(e.toLowerCase())) return void $.critical(`The string "${e}" was set in posthog.identify which indicates an error. This ID should be unique to the user and not a hardcoded string.`);
    if (!this._requirePersonProcessing("posthog.identify")) return;
    const n = this.get_distinct_id();
    if (this.register({
      $user_id: e
    }), !this.get_property("$device_id")) {
      const e = n;
      this.register_once({
        $had_persisted_distinct_id: !0,
        $device_id: e
      }, "");
    }
    e !== n && e !== this.get_property(K) && (this.unregister(K), this.register({
      distinct_id: e
    }));
    const s = "anonymous" === (this.persistence.get_property(Ee) || "anonymous");
    e !== n && s ? (this.persistence.set_property(Ee, "identified"), this.setPersonPropertiesForFlags(t || {}, !1), this.capture("$identify", {
      distinct_id: e,
      $anon_distinct_id: n
    }, {
      $set: t || {},
      $set_once: i || {}
    }), this.featureFlags.setAnonymousDistinctId(n)) : (t || i) && this.setPersonProperties(t, i), e !== n && (this.reloadFeatureFlags(), this.unregister(Se));
  }
  setPersonProperties(e, t) {
    (e || t) && this._requirePersonProcessing("posthog.setPersonProperties") && (this.setPersonPropertiesForFlags(e || {}), this.capture("$set", {
      $set: e || {},
      $set_once: t || {}
    }));
  }
  group(e, t, i) {
    if (!e || !t) return void $.error("posthog.group requires a group type and group key");
    if (!this._requirePersonProcessing("posthog.group")) return;
    const n = this.getGroups();
    n[e] !== t && this.resetGroupPropertiesForFlags(e), this.register({
      $groups: {
        ...n,
        [e]: t
      }
    }), i && (this.capture("$groupidentify", {
      $group_type: e,
      $group_key: t,
      $group_set: i
    }), this.setGroupPropertiesForFlags({
      [e]: i
    })), n[e] === t || i || this.reloadFeatureFlags();
  }
  resetGroups() {
    this.register({
      $groups: {}
    }), this.resetGroupPropertiesForFlags(), this.reloadFeatureFlags();
  }
  setPersonPropertiesForFlags(e) {
    let t = !(arguments.length > 1 && void 0 !== arguments[1]) || arguments[1];
    this._requirePersonProcessing("posthog.setPersonPropertiesForFlags") && this.featureFlags.setPersonPropertiesForFlags(e, t);
  }
  resetPersonPropertiesForFlags() {
    this.featureFlags.resetPersonPropertiesForFlags();
  }
  setGroupPropertiesForFlags(e) {
    let t = !(arguments.length > 1 && void 0 !== arguments[1]) || arguments[1];
    this._requirePersonProcessing("posthog.setGroupPropertiesForFlags") && this.featureFlags.setGroupPropertiesForFlags(e, t);
  }
  resetGroupPropertiesForFlags(e) {
    this.featureFlags.resetGroupPropertiesForFlags(e);
  }
  reset(e) {
    var t, i, n, s, r;
    if ($.info("reset"), !this.__loaded) return $.uninitializedWarning("posthog.reset");
    const o = this.get_property("$device_id");
    this.consent.reset(), null === (t = this.persistence) || void 0 === t || t.clear(), null === (i = this.sessionPersistence) || void 0 === i || i.clear(), null === (n = this.surveys) || void 0 === n || n.reset(), null === (s = this.persistence) || void 0 === s || s.set_property(Ee, "anonymous"), null === (r = this.sessionManager) || void 0 === r || r.resetSessionId();
    const a = this.config.get_device_id(We());
    this.register_once({
      distinct_id: a,
      $device_id: e ? a : o
    }, "");
  }
  get_distinct_id() {
    return this.get_property("distinct_id");
  }
  getGroups() {
    return this.get_property("$groups") || {};
  }
  get_session_id() {
    var e, t;
    return null !== (e = null === (t = this.sessionManager) || void 0 === t ? void 0 : t.checkAndGetSessionAndWindowId(!0).sessionId) && void 0 !== e ? e : "";
  }
  get_session_replay_url(e) {
    if (!this.sessionManager) return "";
    const {
      sessionId: t,
      sessionStartTimestamp: i
    } = this.sessionManager.checkAndGetSessionAndWindowId(!0);
    let n = this.requestRouter.endpointFor("ui", `/project/${this.config.token}/replay/${t}`);
    if (null != e && e.withTimestamp && i) {
      var s;
      const t = null !== (s = e.timestampLookBack) && void 0 !== s ? s : 10;
      if (!i) return n;
      n += `?t=${Math.max(Math.floor((new Date().getTime() - i) / 1e3) - t, 0)}`;
    }
    return n;
  }
  alias(e, t) {
    return e === this.get_property(X) ? ($.critical("Attempting to create alias for existing People user - aborting."), -2) : this._requirePersonProcessing("posthog.alias") ? (S(t) && (t = this.get_distinct_id()), e !== t ? (this._register_single(K, e), this.capture("$create_alias", {
      alias: e,
      distinct_id: t
    })) : ($.warn("alias matches current distinct_id - skipping api call."), this.identify(e), -1)) : void 0;
  }
  set_config(e) {
    const t = {
      ...this.config
    };
    var i, n, s, r;
    b(e) && (D(this.config, Lr(e)), null === (i = this.persistence) || void 0 === i || i.update_config(this.config, t), this.sessionPersistence = "sessionStorage" === this.config.persistence ? this.persistence : new ci({
      ...this.config,
      persistence: "sessionStorage"
    }), Xe.is_supported() && "true" === Xe.get("ph_debug") && (this.config.debug = !0), this.config.debug && (_.DEBUG = !0, $.info("set_config", {
      config: e,
      oldConfig: t,
      newConfig: {
        ...this.config
      }
    })), null === (n = this.sessionRecording) || void 0 === n || n.startIfEnabledOrStop(), null === (s = this.autocapture) || void 0 === s || s.startIfEnabled(), null === (r = this.heatmaps) || void 0 === r || r.startIfEnabled(), this.surveys.loadIfEnabled(), this._sync_opt_out_with_persistence());
  }
  startSessionRecording(e) {
    const t = F(e) && e;
    if (t || null != e && e.sampling || null != e && e.linked_flag) {
      var i;
      const r = null === (i = this.sessionManager) || void 0 === i ? void 0 : i.checkAndGetSessionAndWindowId();
      var n, s;
      if (t || null != e && e.sampling) null === (n = this.sessionRecording) || void 0 === n || n.overrideSampling(), $.info("Session recording started with sampling override for session: ", null == r ? void 0 : r.sessionId);
      if (t || null != e && e.linked_flag) null === (s = this.sessionRecording) || void 0 === s || s.overrideLinkedFlag(), $.info("Session recording started with linked_flags override");
    }
    this.set_config({
      disable_session_recording: !1
    });
  }
  stopSessionRecording() {
    this.set_config({
      disable_session_recording: !0
    });
  }
  sessionRecordingStarted() {
    var e;
    return !(null === (e = this.sessionRecording) || void 0 === e || !e.started);
  }
  captureException(e, t) {
    var i;
    const n = new Error("PostHog syntheticException"),
      s = y(null === (i = h.__PosthogExtensions__) || void 0 === i ? void 0 : i.parseErrorAsProperties) ? h.__PosthogExtensions__.parseErrorAsProperties([e.message, void 0, void 0, void 0, e], {
        syntheticException: n
      }) : {
        $exception_level: "error",
        $exception_list: [{
          type: e.name,
          value: e.message,
          mechanism: {
            handled: !0,
            synthetic: !1
          }
        }],
        ...t
      };
    this.exceptions.sendExceptionEvent(s);
  }
  loadToolbar(e) {
    return this.toolbar.loadToolbar(e);
  }
  get_property(e) {
    var t;
    return null === (t = this.persistence) || void 0 === t ? void 0 : t.props[e];
  }
  getSessionProperty(e) {
    var t;
    return null === (t = this.sessionPersistence) || void 0 === t ? void 0 : t.props[e];
  }
  toString() {
    var e;
    let t = null !== (e = this.config.name) && void 0 !== e ? e : Cr;
    return t !== Cr && (t = Cr + "." + t), t;
  }
  _isIdentified() {
    var e, t;
    return "identified" === (null === (e = this.persistence) || void 0 === e ? void 0 : e.get_property(Ee)) || "identified" === (null === (t = this.sessionPersistence) || void 0 === t ? void 0 : t.get_property(Ee));
  }
  _hasPersonProcessing() {
    var e, t, i, n;
    return !("never" === this.config.person_profiles || "identified_only" === this.config.person_profiles && !this._isIdentified() && w(this.getGroups()) && (null === (e = this.persistence) || void 0 === e || null === (t = e.props) || void 0 === t || !t[K]) && (null === (i = this.persistence) || void 0 === i || null === (n = i.props) || void 0 === n || !n[Re]));
  }
  _shouldCapturePageleave() {
    return !0 === this.config.capture_pageleave || "if_capture_pageview" === this.config.capture_pageleave && this.config.capture_pageview;
  }
  createPersonProfile() {
    this._hasPersonProcessing() || this._requirePersonProcessing("posthog.createPersonProfile") && this.setPersonProperties({}, {});
  }
  _requirePersonProcessing(e) {
    return "never" === this.config.person_profiles ? ($.error(e + ' was called, but process_person is set to "never". This call will be ignored.'), !1) : (this._register_single(Re, !0), !0);
  }
  _sync_opt_out_with_persistence() {
    var e, t;
    const i = this.consent.isOptedOut(),
      n = this.config.opt_out_persistence_by_default,
      s = this.config.disable_persistence || i && !!n;
    var r, o;
    (null === (e = this.persistence) || void 0 === e ? void 0 : e.disabled) !== s && (null === (r = this.persistence) || void 0 === r || r.set_disabled(s));
    (null === (t = this.sessionPersistence) || void 0 === t ? void 0 : t.disabled) !== s && (null === (o = this.sessionPersistence) || void 0 === o || o.set_disabled(s));
  }
  opt_in_capturing(e) {
    var t;
    (this.consent.optInOut(!0), this._sync_opt_out_with_persistence(), S(null == e ? void 0 : e.captureEventName) || null != e && e.captureEventName) && this.capture(null !== (t = null == e ? void 0 : e.captureEventName) && void 0 !== t ? t : "$opt_in", null == e ? void 0 : e.captureProperties, {
      send_instantly: !0
    });
    this.config.capture_pageview && this._captureInitialPageview();
  }
  opt_out_capturing() {
    this.consent.optInOut(!1), this._sync_opt_out_with_persistence();
  }
  has_opted_in_capturing() {
    return this.consent.isOptedIn();
  }
  has_opted_out_capturing() {
    return this.consent.isOptedOut();
  }
  clear_opt_in_out_capturing() {
    this.consent.reset(), this._sync_opt_out_with_persistence();
  }
  _is_bot() {
    return r ? ur(r, this.config.custom_blocked_useragents) : void 0;
  }
  _captureInitialPageview() {
    o && !this._initialPageviewCaptured && (this._initialPageviewCaptured = !0, this.capture("$pageview", {
      title: o.title
    }, {
      send_instantly: !0
    }));
  }
  debug(t) {
    !1 === t ? (null == e || e.console.log("You've disabled debug mode."), localStorage && localStorage.removeItem("ph_debug"), this.set_config({
      debug: !1
    })) : (null == e || e.console.log("You're now in debug mode. All calls to PostHog will be logged in your console.\nYou can disable this with `posthog.debug(false)`."), localStorage && localStorage.setItem("ph_debug", "true"), this.set_config({
      debug: !0
    }));
  }
}
!function (e, t) {
  for (let i = 0; i < t.length; i++) e.prototype[t[i]] = H(e.prototype[t[i]]);
}(Dr, ["identify"]);
const Nr = function () {
  const t = Tr[Cr] = new Dr();
  return function () {
    function t() {
      t.done || (t.done = !0, Mr = !1, O(Tr, function (e) {
        e._dom_loaded();
      }));
    }
    null != o && o.addEventListener && ("complete" === o.readyState ? t() : o.addEventListener("DOMContentLoaded", t, !1)), e && G(e, "load", t, !0);
  }(), t;
}();
export { J as Compression, Dr as PostHog, vs as SurveyQuestionBranchingType, fs as SurveyQuestionType, gs as SurveyType, Nr as default, Nr as posthog, Y as severityLevels };
